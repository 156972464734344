@charset "UTF-8";

/*!
 * Tabler (v1.0.0-alpha.5)
 * Copyright 2018-2020 The Tabler Authors
 * Copyright 2018-2020 codecalm
 * Licensed under MIT (https://github.com/tabler/tabler/blob/master/LICENSE)
 */


/**
Dark mode
 */

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;600;700&display=swap");
:root {
    --blue: #206bc4;
    --azure: #45aaf2;
    --indigo: #6574cd;
    --purple: #a55eea;
    --pink: #f66d9b;
    --red: #cd201f;
    --orange: #ff922b;
    --yellow: #fab005;
    --lime: #94d82d;
    --green: #5dba00;
    --teal: #2bcbba;
    --cyan: #17a2b8;
    --white: #ffffff;
    --gray: #a8aeb7;
    --gray-dark: #545d6d;
    --dark: #354052;
    --primary: #206bc4;
    --control: #e8bc6b;
    --secondary: #6e7582;
    --success: #26BF7F;
    --info: #45aaf2;
    --warning: #fab005;
    --danger: #cd201f;
    --light: #f5f7fb;
    --dark: #354052;
    --font-family-sans-serif: "Ubuntu", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    --font-family-monospace: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
  scrollbar-width: thin;
}

body {

	/* @media (orientation:portrait) and (max-width: 800px)
	{
		display: none !important;
	} */
    margin: 0;
    font-family: "Ubuntu", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.7142857;
    color: #354052;
    background-color: #f5f7fb;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr,
.hr {
    margin: 2rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.1;
}

hr:not([size]),
.hr:not([size]) {
    height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1,
.h1 {
    font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
    h1,
    .h1 {
        font-size: 1.75rem;
    }
}

h2,
.h2 {
    font-size: calc(1.2625rem + 0.15vw);
}

@media (min-width: 1200px) {
    h2,
    .h2 {
        font-size: 1.375rem;
    }
}

h3,
.h3 {
    font-size: 1.125rem;
}

h4,
.h4 {
    font-size: 0.875rem;
}

h5,
.h5 {
    font-size: 0.75rem;
}

h6,
.h6 {
    font-size: 0.625rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul {
    padding-left: 2rem;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 500;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small,
.small {
    font-size: 87.5%;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

sub,
sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: #206bc4;
    text-decoration: none;
}

a:hover {
    color: #154782;
}

a:not([href]),
a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

pre,
code,
kbd,
samp {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em;
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.625rem;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

code {
    font-size: 0.625rem;
    color: #206bc4;
    word-wrap: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 0.625rem;
    color: #ffffff;
    background-color: #354052;
    border-radius: 0.2rem;
}

kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 500;
}

figure {
    margin: 0 0 1rem;
}

img,
svg {
    vertical-align: middle;
}

table {
    caption-side: bottom;
    border-collapse: collapse;
}

caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6e7582;
    text-align: left;
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
    display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: textfield;
}

textarea {
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
    white-space: normal;
}

@media (min-width: 1200px) {
    legend {
        font-size: 1.5rem;
    }
}

legend+* {
    clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
    padding: 0;
}

::-webkit-inner-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
}

::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
    padding: 0;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

progress {
    vertical-align: baseline;
}

[hidden] {
    display: none !important;
}

.lead {
    font-size: 0.875rem;
    font-weight: 400;
}

.display-1 {
    font-size: calc(1.725rem + 5.7vw);
    font-weight: 300;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-1 {
        font-size: 6rem;
    }
}

.display-2 {
    font-size: calc(1.675rem + 5.1vw);
    font-weight: 300;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-2 {
        font-size: 5.5rem;
    }
}

.display-3 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-3 {
        font-size: 4.5rem;
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-4 {
        font-size: 3.5rem;
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 87.5%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 0.875rem;
}

.blockquote-footer {
    display: block;
    font-size: 87.5%;
    color: #a8aeb7;
}

.blockquote-footer::before {
    content: "\2014\00A0";
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #f5f7fb;
    border: 1px solid #e9ecf1;
    border-radius: 3px;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 87.5%;
    color: #a8aeb7;
}

.container {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 33rem) {
    .container {
        max-width: 32.5rem;
    }
}

@media (min-width: 48rem) {
    .container {
        max-width: 47.5rem;
    }
}

@media (min-width: 64rem) {
    .container {
        max-width: 64rem;
    }
}

@media (min-width: 75.25rem) {
    .container {
        max-width: 75.25rem;
    }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 33rem) {
    .container,
    .container-sm {
        max-width: 32.5rem;
    }
}

@media (min-width: 48rem) {
    .container,
    .container-sm,
    .container-md {
        max-width: 47.5rem;
    }
}

@media (min-width: 64rem) {
    .container,
    .container-sm,
    .container-md,
    .container-lg {
        max-width: 64rem;
    }
}

@media (min-width: 75.25rem) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 75.25rem;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
}

.row>* {
    flex-shrink: 0;
    max-width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

@media (max-width: 767px) {
    .row>* {
        width: 100%;
    }
}

.g-0,
.gx-0 {
    margin-right: 0;
    margin-left: 0;
}

.g-0>*,
.gx-0>* {
    padding-right: 0;
    padding-left: 0;
}

.g-0,
.gy-0 {
    margin-top: 0;
}

.g-0>*,
.gy-0>* {
    margin-top: 0;
}

.g-1,
.gx-1 {
    margin-right: -0.125rem;
    margin-left: -0.125rem;
}

.g-1>*,
.gx-1>* {
    padding-right: 0.125rem;
    padding-left: 0.125rem;
}

.g-1,
.gy-1 {
    margin-top: -0.25rem;
}

.g-1>*,
.gy-1>* {
    margin-top: 0.25rem;
}

.g-2,
.gx-2 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
}

.g-2>*,
.gx-2>* {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
}

.g-2,
.gy-2 {
    margin-top: -0.5rem;
}

.g-2>*,
.gy-2>* {
    margin-top: 0.5rem;
}

.g-3,
.gx-3 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.g-3>*,
.gx-3>* {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.g-3,
.gy-3 {
    margin-top: -1rem;
}

.g-3>*,
.gy-3>* {
    margin-top: 1rem;
}

.g-4,
.gx-4 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
}

.g-4>*,
.gx-4>* {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.g-4,
.gy-4 {
    margin-top: -1.5rem;
}

.g-4>*,
.gy-4>* {
    margin-top: 1.5rem;
}

.g-5,
.gx-5 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
}

.g-5>*,
.gx-5>* {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.g-5,
.gy-5 {
    margin-top: -3rem;
}

.g-5>*,
.gy-5>* {
    margin-top: 3rem;
}

.g-6,
.gx-6 {
    margin-right: -2.25rem;
    margin-left: -2.25rem;
}

.g-6>*,
.gx-6>* {
    padding-right: 2.25rem;
    padding-left: 2.25rem;
}

.g-6,
.gy-6 {
    margin-top: -4.5rem;
}

.g-6>*,
.gy-6>* {
    margin-top: 4.5rem;
}

@media (min-width: 33rem) {
    .g-sm-0,
    .gx-sm-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .g-sm-0>*,
    .gx-sm-0>* {
        padding-right: 0;
        padding-left: 0;
    }
    .g-sm-0,
    .gy-sm-0 {
        margin-top: 0;
    }
    .g-sm-0>*,
    .gy-sm-0>* {
        margin-top: 0;
    }
    .g-sm-1,
    .gx-sm-1 {
        margin-right: -0.125rem;
        margin-left: -0.125rem;
    }
    .g-sm-1>*,
    .gx-sm-1>* {
        padding-right: 0.125rem;
        padding-left: 0.125rem;
    }
    .g-sm-1,
    .gy-sm-1 {
        margin-top: -0.25rem;
    }
    .g-sm-1>*,
    .gy-sm-1>* {
        margin-top: 0.25rem;
    }
    .g-sm-2,
    .gx-sm-2 {
        margin-right: -0.25rem;
        margin-left: -0.25rem;
    }
    .g-sm-2>*,
    .gx-sm-2>* {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }
    .g-sm-2,
    .gy-sm-2 {
        margin-top: -0.5rem;
    }
    .g-sm-2>*,
    .gy-sm-2>* {
        margin-top: 0.5rem;
    }
    .g-sm-3,
    .gx-sm-3 {
        margin-right: -0.5rem;
        margin-left: -0.5rem;
    }
    .g-sm-3>*,
    .gx-sm-3>* {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .g-sm-3,
    .gy-sm-3 {
        margin-top: -1rem;
    }
    .g-sm-3>*,
    .gy-sm-3>* {
        margin-top: 1rem;
    }
    .g-sm-4,
    .gx-sm-4 {
        margin-right: -0.75rem;
        margin-left: -0.75rem;
    }
    .g-sm-4>*,
    .gx-sm-4>* {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
    .g-sm-4,
    .gy-sm-4 {
        margin-top: -1.5rem;
    }
    .g-sm-4>*,
    .gy-sm-4>* {
        margin-top: 1.5rem;
    }
    .g-sm-5,
    .gx-sm-5 {
        margin-right: -1.5rem;
        margin-left: -1.5rem;
    }
    .g-sm-5>*,
    .gx-sm-5>* {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    .g-sm-5,
    .gy-sm-5 {
        margin-top: -3rem;
    }
    .g-sm-5>*,
    .gy-sm-5>* {
        margin-top: 3rem;
    }
    .g-sm-6,
    .gx-sm-6 {
        margin-right: -2.25rem;
        margin-left: -2.25rem;
    }
    .g-sm-6>*,
    .gx-sm-6>* {
        padding-right: 2.25rem;
        padding-left: 2.25rem;
    }
    .g-sm-6,
    .gy-sm-6 {
        margin-top: -4.5rem;
    }
    .g-sm-6>*,
    .gy-sm-6>* {
        margin-top: 4.5rem;
    }
}

@media (min-width: 48rem) {
    .g-md-0,
    .gx-md-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .g-md-0>*,
    .gx-md-0>* {
        padding-right: 0;
        padding-left: 0;
    }
    .g-md-0,
    .gy-md-0 {
        margin-top: 0;
    }
    .g-md-0>*,
    .gy-md-0>* {
        margin-top: 0;
    }
    .g-md-1,
    .gx-md-1 {
        margin-right: -0.125rem;
        margin-left: -0.125rem;
    }
    .g-md-1>*,
    .gx-md-1>* {
        padding-right: 0.125rem;
        padding-left: 0.125rem;
    }
    .g-md-1,
    .gy-md-1 {
        margin-top: -0.25rem;
    }
    .g-md-1>*,
    .gy-md-1>* {
        margin-top: 0.25rem;
    }
    .g-md-2,
    .gx-md-2 {
        margin-right: -0.25rem;
        margin-left: -0.25rem;
    }
    .g-md-2>*,
    .gx-md-2>* {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }
    .g-md-2,
    .gy-md-2 {
        margin-top: -0.5rem;
    }
    .g-md-2>*,
    .gy-md-2>* {
        margin-top: 0.5rem;
    }
    .g-md-3,
    .gx-md-3 {
        margin-right: -0.5rem;
        margin-left: -0.5rem;
    }
    .g-md-3>*,
    .gx-md-3>* {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .g-md-3,
    .gy-md-3 {
        margin-top: -1rem;
    }
    .g-md-3>*,
    .gy-md-3>* {
        margin-top: 1rem;
    }
    .g-md-4,
    .gx-md-4 {
        margin-right: -0.75rem;
        margin-left: -0.75rem;
    }
    .g-md-4>*,
    .gx-md-4>* {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
    .g-md-4,
    .gy-md-4 {
        margin-top: -1.5rem;
    }
    .g-md-4>*,
    .gy-md-4>* {
        margin-top: 1.5rem;
    }
    .g-md-5,
    .gx-md-5 {
        margin-right: -1.5rem;
        margin-left: -1.5rem;
    }
    .g-md-5>*,
    .gx-md-5>* {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    .g-md-5,
    .gy-md-5 {
        margin-top: -3rem;
    }
    .g-md-5>*,
    .gy-md-5>* {
        margin-top: 3rem;
    }
    .g-md-6,
    .gx-md-6 {
        margin-right: -2.25rem;
        margin-left: -2.25rem;
    }
    .g-md-6>*,
    .gx-md-6>* {
        padding-right: 2.25rem;
        padding-left: 2.25rem;
    }
    .g-md-6,
    .gy-md-6 {
        margin-top: -4.5rem;
    }
    .g-md-6>*,
    .gy-md-6>* {
        margin-top: 4.5rem;
    }
}

@media (min-width: 64rem) {
    .g-lg-0,
    .gx-lg-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .g-lg-0>*,
    .gx-lg-0>* {
        padding-right: 0;
        padding-left: 0;
    }
    .g-lg-0,
    .gy-lg-0 {
        margin-top: 0;
    }
    .g-lg-0>*,
    .gy-lg-0>* {
        margin-top: 0;
    }
    .g-lg-1,
    .gx-lg-1 {
        margin-right: -0.125rem;
        margin-left: -0.125rem;
    }
    .g-lg-1>*,
    .gx-lg-1>* {
        padding-right: 0.125rem;
        padding-left: 0.125rem;
    }
    .g-lg-1,
    .gy-lg-1 {
        margin-top: -0.25rem;
    }
    .g-lg-1>*,
    .gy-lg-1>* {
        margin-top: 0.25rem;
    }
    .g-lg-2,
    .gx-lg-2 {
        margin-right: -0.25rem;
        margin-left: -0.25rem;
    }
    .g-lg-2>*,
    .gx-lg-2>* {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }
    .g-lg-2,
    .gy-lg-2 {
        margin-top: -0.5rem;
    }
    .g-lg-2>*,
    .gy-lg-2>* {
        margin-top: 0.5rem;
    }
    .g-lg-3,
    .gx-lg-3 {
        margin-right: -0.5rem;
        margin-left: -0.5rem;
    }
    .g-lg-3>*,
    .gx-lg-3>* {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .g-lg-3,
    .gy-lg-3 {
        margin-top: -1rem;
    }
    .g-lg-3>*,
    .gy-lg-3>* {
        margin-top: 1rem;
    }
    .g-lg-4,
    .gx-lg-4 {
        margin-right: -0.75rem;
        margin-left: -0.75rem;
    }
    .g-lg-4>*,
    .gx-lg-4>* {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
    .g-lg-4,
    .gy-lg-4 {
        margin-top: -1.5rem;
    }
    .g-lg-4>*,
    .gy-lg-4>* {
        margin-top: 1.5rem;
    }
    .g-lg-5,
    .gx-lg-5 {
        margin-right: -1.5rem;
        margin-left: -1.5rem;
    }
    .g-lg-5>*,
    .gx-lg-5>* {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    .g-lg-5,
    .gy-lg-5 {
        margin-top: -3rem;
    }
    .g-lg-5>*,
    .gy-lg-5>* {
        margin-top: 3rem;
    }
    .g-lg-6,
    .gx-lg-6 {
        margin-right: -2.25rem;
        margin-left: -2.25rem;
    }
    .g-lg-6>*,
    .gx-lg-6>* {
        padding-right: 2.25rem;
        padding-left: 2.25rem;
    }
    .g-lg-6,
    .gy-lg-6 {
        margin-top: -4.5rem;
    }
    .g-lg-6>*,
    .gy-lg-6>* {
        margin-top: 4.5rem;
    }
}

@media (min-width: 75.25rem) {
    .g-xl-0,
    .gx-xl-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .g-xl-0>*,
    .gx-xl-0>* {
        padding-right: 0;
        padding-left: 0;
    }
    .g-xl-0,
    .gy-xl-0 {
        margin-top: 0;
    }
    .g-xl-0>*,
    .gy-xl-0>* {
        margin-top: 0;
    }
    .g-xl-1,
    .gx-xl-1 {
        margin-right: -0.125rem;
        margin-left: -0.125rem;
    }
    .g-xl-1>*,
    .gx-xl-1>* {
        padding-right: 0.125rem;
        padding-left: 0.125rem;
    }
    .g-xl-1,
    .gy-xl-1 {
        margin-top: -0.25rem;
    }
    .g-xl-1>*,
    .gy-xl-1>* {
        margin-top: 0.25rem;
    }
    .g-xl-2,
    .gx-xl-2 {
        margin-right: -0.25rem;
        margin-left: -0.25rem;
    }
    .g-xl-2>*,
    .gx-xl-2>* {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }
    .g-xl-2,
    .gy-xl-2 {
        margin-top: -0.5rem;
    }
    .g-xl-2>*,
    .gy-xl-2>* {
        margin-top: 0.5rem;
    }
    .g-xl-3,
    .gx-xl-3 {
        margin-right: -0.5rem;
        margin-left: -0.5rem;
    }
    .g-xl-3>*,
    .gx-xl-3>* {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .g-xl-3,
    .gy-xl-3 {
        margin-top: -1rem;
    }
    .g-xl-3>*,
    .gy-xl-3>* {
        margin-top: 1rem;
    }
    .g-xl-4,
    .gx-xl-4 {
        margin-right: -0.75rem;
        margin-left: -0.75rem;
    }
    .g-xl-4>*,
    .gx-xl-4>* {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
    .g-xl-4,
    .gy-xl-4 {
        margin-top: -1.5rem;
    }
    .g-xl-4>*,
    .gy-xl-4>* {
        margin-top: 1.5rem;
    }
    .g-xl-5,
    .gx-xl-5 {
        margin-right: -1.5rem;
        margin-left: -1.5rem;
    }
    .g-xl-5>*,
    .gx-xl-5>* {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    .g-xl-5,
    .gy-xl-5 {
        margin-top: -3rem;
    }
    .g-xl-5>*,
    .gy-xl-5>* {
        margin-top: 3rem;
    }
    .g-xl-6,
    .gx-xl-6 {
        margin-right: -2.25rem;
        margin-left: -2.25rem;
    }
    .g-xl-6>*,
    .gx-xl-6>* {
        padding-right: 2.25rem;
        padding-left: 2.25rem;
    }
    .g-xl-6,
    .gy-xl-6 {
        margin-top: -4.5rem;
    }
    .g-xl-6>*,
    .gy-xl-6>* {
        margin-top: 4.5rem;
    }
}

.col {
    flex: 1 0 0%;
    min-width: 0;
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto;
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%;
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%;
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.3333333%;
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%;
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%;
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.6666667%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
}

.col-1 {
    flex: 0 0 auto;
    width: 8.3333333%;
}

.col-2 {
    flex: 0 0 auto;
    width: 16.6666667%;
}

.col-3 {
    flex: 0 0 auto;
    width: 25%;
}

.col-4 {
    flex: 0 0 auto;
    width: 33.3333333%;
}

.col-5 {
    flex: 0 0 auto;
    width: 41.6666667%;
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-7 {
    flex: 0 0 auto;
    width: 58.3333333%;
}

.col-8 {
    flex: 0 0 auto;
    width: 66.6666667%;
}

.col-9 {
    flex: 0 0 auto;
    width: 75%;
}

.col-10 {
    flex: 0 0 auto;
    width: 83.3333333%;
}

.col-11 {
    flex: 0 0 auto;
    width: 91.6666667%;
}

.col-12 {
    flex: 0 0 auto;
    width: 100%;
}

.offset-1 {
    margin-left: 8.3333333%;
}

.offset-2 {
    margin-left: 16.6666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.3333333%;
}

.offset-5 {
    margin-left: 41.6666667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.3333333%;
}

.offset-8 {
    margin-left: 66.6666667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.3333333%;
}

.offset-11 {
    margin-left: 91.6666667%;
}

@media (min-width: 33rem) {
    .col-sm {
        flex: 1 0 0%;
        min-width: 0;
    }
    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto;
    }
    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%;
    }
    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%;
    }
    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.3333333%;
    }
    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%;
    }
    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%;
    }
    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.6666667%;
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.3333333%;
    }
    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.6666667%;
    }
    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.3333333%;
    }
    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.6666667%;
    }
    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.3333333%;
    }
    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.6666667%;
    }
    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.3333333%;
    }
    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.6666667%;
    }
    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset-sm-0 {
        margin-left: 0;
    }
    .offset-sm-1 {
        margin-left: 8.3333333%;
    }
    .offset-sm-2 {
        margin-left: 16.6666667%;
    }
    .offset-sm-3 {
        margin-left: 25%;
    }
    .offset-sm-4 {
        margin-left: 33.3333333%;
    }
    .offset-sm-5 {
        margin-left: 41.6666667%;
    }
    .offset-sm-6 {
        margin-left: 50%;
    }
    .offset-sm-7 {
        margin-left: 58.3333333%;
    }
    .offset-sm-8 {
        margin-left: 66.6666667%;
    }
    .offset-sm-9 {
        margin-left: 75%;
    }
    .offset-sm-10 {
        margin-left: 83.3333333%;
    }
    .offset-sm-11 {
        margin-left: 91.6666667%;
    }
}

@media (min-width: 48rem) {
    .col-md {
        flex: 1 0 0%;
        min-width: 0;
    }
    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto;
    }
    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%;
    }
    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%;
    }
    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.3333333%;
    }
    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%;
    }
    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%;
    }
    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.6666667%;
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-md-1 {
        flex: 0 0 auto;
        width: 8.3333333%;
    }
    .col-md-2 {
        flex: 0 0 auto;
        width: 16.6666667%;
    }
    .col-md-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-md-4 {
        flex: 0 0 auto;
        width: 33.3333333%;
    }
    .col-md-5 {
        flex: 0 0 auto;
        width: 41.6666667%;
    }
    .col-md-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-md-7 {
        flex: 0 0 auto;
        width: 58.3333333%;
    }
    .col-md-8 {
        flex: 0 0 auto;
        width: 66.6666667%;
    }
    .col-md-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-md-10 {
        flex: 0 0 auto;
        width: 83.3333333%;
    }
    .col-md-11 {
        flex: 0 0 auto;
        width: 91.6666667%;
    }
    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset-md-0 {
        margin-left: 0;
    }
    .offset-md-1 {
        margin-left: 8.3333333%;
    }
    .offset-md-2 {
        margin-left: 16.6666667%;
    }
    .offset-md-3 {
        margin-left: 25%;
    }
    .offset-md-4 {
        margin-left: 33.3333333%;
    }
    .offset-md-5 {
        margin-left: 41.6666667%;
    }
    .offset-md-6 {
        margin-left: 50%;
    }
    .offset-md-7 {
        margin-left: 58.3333333%;
    }
    .offset-md-8 {
        margin-left: 66.6666667%;
    }
    .offset-md-9 {
        margin-left: 75%;
    }
    .offset-md-10 {
        margin-left: 83.3333333%;
    }
    .offset-md-11 {
        margin-left: 91.6666667%;
    }
}

@media (min-width: 64rem) {
    .col-lg {
        flex: 1 0 0%;
        min-width: 0;
    }
    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto;
    }
    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%;
    }
    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%;
    }
    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.3333333%;
    }
    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%;
    }
    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%;
    }
    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.6666667%;
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.3333333%;
    }
    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.6666667%;
    }
    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.3333333%;
    }
    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.6666667%;
    }
    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.3333333%;
    }
    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.6666667%;
    }
    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.3333333%;
    }
    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.6666667%;
    }
    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset-lg-0 {
        margin-left: 0;
    }
    .offset-lg-1 {
        margin-left: 8.3333333%;
    }
    .offset-lg-2 {
        margin-left: 16.6666667%;
    }
    .offset-lg-3 {
        margin-left: 25%;
    }
    .offset-lg-4 {
        margin-left: 33.3333333%;
    }
    .offset-lg-5 {
        margin-left: 41.6666667%;
    }
    .offset-lg-6 {
        margin-left: 50%;
    }
    .offset-lg-7 {
        margin-left: 58.3333333%;
    }
    .offset-lg-8 {
        margin-left: 66.6666667%;
    }
    .offset-lg-9 {
        margin-left: 75%;
    }
    .offset-lg-10 {
        margin-left: 83.3333333%;
    }
    .offset-lg-11 {
        margin-left: 91.6666667%;
    }
}

@media (min-width: 75.25rem) {
    .col-xl {
        flex: 1 0 0%;
        min-width: 0;
    }
    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto;
    }
    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%;
    }
    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%;
    }
    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.3333333%;
    }
    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%;
    }
    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%;
    }
    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.6666667%;
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.3333333%;
    }
    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.6666667%;
    }
    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.3333333%;
    }
    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.6666667%;
    }
    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.3333333%;
    }
    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.6666667%;
    }
    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.3333333%;
    }
    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.6666667%;
    }
    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset-xl-0 {
        margin-left: 0;
    }
    .offset-xl-1 {
        margin-left: 8.3333333%;
    }
    .offset-xl-2 {
        margin-left: 16.6666667%;
    }
    .offset-xl-3 {
        margin-left: 25%;
    }
    .offset-xl-4 {
        margin-left: 33.3333333%;
    }
    .offset-xl-5 {
        margin-left: 41.6666667%;
    }
    .offset-xl-6 {
        margin-left: 50%;
    }
    .offset-xl-7 {
        margin-left: 58.3333333%;
    }
    .offset-xl-8 {
        margin-left: 66.6666667%;
    }
    .offset-xl-9 {
        margin-left: 75%;
    }
    .offset-xl-10 {
        margin-left: 83.3333333%;
    }
    .offset-xl-11 {
        margin-left: 91.6666667%;
    }
}

.table,
.markdown>table {
    width: 100%;
    margin-bottom: 1rem;
    color: #354052;
    vertical-align: top;
}

.table th,
.markdown>table th,
.table td,
.markdown>table td {
    padding: 0.5rem;
    border-bottom: 1px solid rgba(110, 117, 130, 0.2);
}

.table tbody,
.markdown>table tbody {
    vertical-align: inherit;
}

.table thead th,
.markdown>table thead th {
    vertical-align: bottom;
    border-bottom-color: rgba(110, 117, 130, 0.2);
}

.table tbody+tbody,
.markdown>table tbody+tbody {
    border-top: 2px solid rgba(110, 117, 130, 0.2);
}

.caption-top {
    caption-side: top;
}

.table-sm th,
.table-sm td {
    padding: 0.25rem;
}

.table-bordered,
.markdown>table {
    border: 1px solid rgba(110, 117, 130, 0.2);
}

.table-bordered th,
.markdown>table th,
.table-bordered td,
.markdown>table td {
    border: 1px solid rgba(110, 117, 130, 0.2);
}

.table-bordered thead th,
.markdown>table thead th,
.table-bordered thead td,
.markdown>table thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(53, 64, 82, 0.024);
}

.table-hover tbody tr:hover {
    color: #354052;
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #e9f0f9;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #a6c4e7;
}

.table-hover .table-primary:hover {
    background-color: #d5e2f4;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #d5e2f4;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #f1f1f3;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #c5c8cd;
}

.table-hover .table-secondary:hover {
    background-color: #e3e3e7;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #e3e3e7;
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #eff8e6;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #bfe399;
}

.table-hover .table-success:hover {
    background-color: #e2f2d2;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #e2f2d2;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #ecf7fe;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #b5ddfa;
}

.table-hover .table-info:hover {
    background-color: #d4edfd;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #d4edfd;
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #fff7e6;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #fddf9b;
}

.table-hover .table-warning:hover {
    background-color: #ffefcd;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffefcd;
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #fae9e9;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #eba6a5;
}

.table-hover .table-danger:hover {
    background-color: #f5d4d4;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f5d4d4;
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fefeff;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #fbfcfd;
}

.table-hover .table-light:hover {
    background-color: #e5e5ff;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #e5e5ff;
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #ebecee;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #aeb3ba;
}

.table-hover .table-dark:hover {
    background-color: #dddfe2;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #dddfe2;
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th,
.markdown>table .thead-dark th {
    color: #ffffff;
    background-color: #545d6d;
    border-color: #656f83;
}

.table .thead-light th,
.markdown>table .thead-light th {
    color: #7a828f;
    background-color: #f1f3f8;
    border-color: rgba(110, 117, 130, 0.2);
}

.table-dark {
    color: #ffffff;
    background-color: #545d6d;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #656f83;
}

.table-dark.table-bordered,
.markdown>table.table-dark {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 32.98rem) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm>.table-bordered,
    .markdown.table-responsive-sm>table {
        border: 0;
    }
}

@media (max-width: 47.98rem) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md>.table-bordered,
    .markdown.table-responsive-md>table {
        border: 0;
    }
}

@media (max-width: 63.98rem) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg>.table-bordered,
    .markdown.table-responsive-lg>table {
        border: 0;
    }
}

@media (max-width: 75.23rem) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl>.table-bordered,
    .markdown.table-responsive-xl>table {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered,
.markdown.table-responsive>table {
    border: 0;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: calc(1.2625rem + 0.15vw);
}

@media (min-width: 1200px) {
    .col-form-label-lg {
        font-size: 1.375rem;
    }
}

.col-form-label-sm {
    padding-top: calc(0.125rem + 1px);
    padding-bottom: calc(0.125rem + 1px);
    font-size: 0.75rem;
}

.form-control {
    display: block;
    width: 100%;
    min-height: calc(1.4666667em + 0.75rem + 2px);
    height: 2.295rem;
    padding: 0.375rem 0.75rem;
    /* font-size: 0.875rem; */
    font-weight: 400;
    line-height: 1.5;
    color: #354052;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #CCCCCC;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-control:focus {
    color: inherit;
    background-color: #ffffff;
    border-color: #7aade9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.form-control::-webkit-input-placeholder {
    color: #6e7582;
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: #6e7582;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #6e7582;
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: #6e7582;
    opacity: 1;
}

.form-control::placeholder {
    color: #6e7582;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f5f7fb;
    opacity: 1;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #354052;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    min-height: calc(1.4666667em + 0.25rem + 2px);
    padding: 0.125rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 0.2rem;
}

.form-control-lg {
    min-height: calc(1.4666667em + 1rem + 2px);
    padding: 0.5rem 1.5rem;
    font-size: calc(1.2625rem + 0.15vw);
    border-radius: 0.3rem;
}

@media (min-width: 1200px) {
    .form-control-lg {
        font-size: 1.375rem;
    }
}

.form-control-color {
    max-width: 3rem;
    padding: 0.375rem;
}

.form-control-color::-moz-color-swatch {
    border-radius: 3px;
}

.form-control-color::-webkit-color-swatch {
    border-radius: 3px;
}

.form-select {
    display: block;
    width: 100%;
    height: calc(1.4666667em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    /* font-size: 0.875rem; */
    font-weight: 400;
    line-height: 1.5;
    color: #354052;
    vertical-align: middle;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28110, 117, 130, 0.44%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-select:focus {
    border-color: #7aade9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.form-select:focus::-ms-value {
    color: #354052;
    background-color: #ffffff;
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.form-select:disabled {
    color: #a8aeb7;
    background-color: #f1f3f8;
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #354052;
}

.form-select-sm {
    height: calc(1.4666667em + 0.25rem + 2px);
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    padding-left: 0.5rem;
    font-size: 0.75rem;
}

.form-select-lg {
    height: calc(1.4666667em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    font-size: calc(1.2625rem + 0.15vw);
}

@media (min-width: 1200px) {
    .form-select-lg {
        font-size: 1.375rem;
    }
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 0.125rem;
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5rem;
}

.form-check-input {
    width: 1rem;
    height: 1rem;
    margin-top: 0.3571429rem;
    vertical-align: top;
    background-color: #ffffff;
    border: 1px solid rgba(110, 117, 130, 0.2);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.form-check-input[type="checkbox"] {
    border-radius: 3px;
}

.form-check-input[type="radio"] {
    border-radius: 50%;
}

.form-check-input:active {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}

.form-check-input:focus {
    border-color: #7aade9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.form-check-input:checked {
    background-color: #206bc4;
    background-repeat: repeat;
    background-position: center center;
    background-size: 1rem;
    border-color: rgba(110, 117, 130, 0.2);
}

.form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6' transform='scale%28.8%29' transform-origin='50% 50%'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3ccircle r='3' fill='%23ffffff' cx='8' cy='8' /%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
    background-color: #206bc4;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 8h6'/%3e%3c/svg%3e");
    background-size: 1em;
    border-color: #206bc4;
}

.form-check-input:disabled {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: .5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
    opacity: .5;
}

.form-check-label {
    margin-bottom: 0;
}

.form-switch {
    padding-left: 2.25rem;
}

.form-switch .form-check-input {
    width: 1.75rem;
    margin-left: -2.25rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28110, 117, 130, 0.2%29'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto;
    border-radius: 1.75rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380bdff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem;
}

.form-file {
    position: relative;
    height: calc(1.4666667em + 0.75rem + 2px);
}

.form-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
}

.form-file-input:focus-within~.form-file-label {
    border-color: #7aade9;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.form-file-input[disabled]~.form-file-label .form-file-text,
.form-file-input:disabled~.form-file-label .form-file-text {
    background-color: #f5f7fb;
}

.form-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    display: flex;
    height: 100%;
    border-color: rgba(110, 117, 130, 0.2);
    border-radius: 3px;
}

.form-file-text {
    display: block;
    flex-grow: 1;
    padding: 0.375rem 0.75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #354052;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #ffffff;
    border-color: inherit;
    border-style: solid;
    border-width: 1px;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}

.form-file-button {
    display: block;
    flex-shrink: 0;
    padding: 0.375rem 0.75rem;
    margin-left: -1px;
    line-height: 1.5;
    color: #354052;
    background-color: rgba(53, 64, 82, 0.024);
    border-color: inherit;
    border-style: solid;
    border-width: 1px;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
}

.form-file-sm {
    height: calc(1.4666667em + 0.25rem + 2px);
    font-size: 0.75rem;
}

.form-file-sm .form-file-text,
.form-file-sm .form-file-button {
    padding: 0.125rem 0.5rem;
}

.form-file-lg {
    height: calc(1.4666667em + 1rem + 2px);
    font-size: calc(1.2625rem + 0.15vw);
}

@media (min-width: 1200px) {
    .form-file-lg {
        font-size: 1.375rem;
    }
}

.form-file-lg .form-file-text,
.form-file-lg .form-file-button {
    padding: 0.5rem 1.5rem;
}

.form-range {
    width: 100%;
    height: 1.25rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-range:focus {
    outline: none;
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #f5f7fb, 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #f5f7fb, 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.form-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #f5f7fb, 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.form-range::-moz-focus-outer {
    border: 0;
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.375rem;
    background-color: #206bc4;
    border: 2px solid #ffffff;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #a6c8f0;
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.25rem;
    color: transparent;
    cursor: pointer;
    background-color: rgba(176, 181, 190, 0.24);
    border-color: transparent;
    border-radius: 1rem;
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #206bc4;
    border: 2px solid #ffffff;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none;
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #a6c8f0;
}

.form-range::-moz-range-track {
    width: 100%;
    height: 0.25rem;
    color: transparent;
    cursor: pointer;
    background-color: rgba(176, 181, 190, 0.24);
    border-color: transparent;
    border-radius: 1rem;
}

.form-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.125rem;
    margin-left: 0.125rem;
    background-color: #206bc4;
    border: 2px solid #ffffff;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .form-range::-ms-thumb {
        -ms-transition: none;
        transition: none;
    }
}

.form-range::-ms-thumb:active {
    background-color: #a6c8f0;
}

.form-range::-ms-track {
    width: 100%;
    height: 0.25rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.form-range::-ms-fill-lower {
    background-color: rgba(176, 181, 190, 0.24);
    border-radius: 1rem;
}

.form-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: rgba(176, 181, 190, 0.24);
    border-radius: 1rem;
}

.form-range:disabled {
    pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #cbcfd6;
}

.form-range:disabled::-moz-range-thumb {
    background-color: #cbcfd6;
}

.form-range:disabled::-ms-thumb {
    background-color: #cbcfd6;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group>.form-control,
.input-group>.form-select,
.input-group>.form-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus,
.input-group>.form-file .form-file-input:focus~.form-file-label {
    z-index: 3;
}

.input-group>.form-file>.form-file-input:focus {
    z-index: 4;
}

.input-group>.form-file:not(:last-child)>.form-file-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.form-file:not(:first-child)>.form-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group .btn {
    position: relative;
    z-index: 2;
}

.input-group .btn:focus {
    z-index: 3;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    /* font-size: 0.875rem; */
    font-weight: 400;
    line-height: 1.5;
    color: #6e7582;
    text-align: center;
    white-space: nowrap;
    background-color: rgba(53, 64, 82, 0.024);
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px;
}

.input-group-lg>.form-control {
    min-height: calc(1.4666667em + 1rem + 2px);
}

.input-group-lg>.form-select {
    height: calc(1.4666667em + 1rem + 2px);
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
    padding: 0.5rem 1.5rem;
    font-size: calc(1.2625rem + 0.15vw);
    border-radius: 0.3rem;
}

@media (min-width: 1200px) {
    .input-group-lg>.form-control,
    .input-group-lg>.form-select,
    .input-group-lg>.input-group-text,
    .input-group-lg>.btn {
        font-size: 1.375rem;
    }
}

.input-group-sm>.form-control {
    min-height: calc(1.4666667em + 0.25rem + 2px);
}

.input-group-sm>.form-select {
    height: calc(1.4666667em + 0.25rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
    padding: 0.125rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 0.2rem;
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
    padding-right: 1.75rem;
}

.input-group> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group>.dropdown-toggle:nth-last-child(n+3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 87.5%;
    color: #26BF7F;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.765625rem;
    color: #ffffff;
    background-color: rgba(94, 186, 0, 0.9);
    border-radius: 3px;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #26BF7F;
    padding-right: calc(1.4666667em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%235eba00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.3666667em + 0.1875rem) center;
    background-size: calc(0.7333333em + 0.375rem) calc(0.7333333em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: #26BF7F;
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.4666667em + 0.75rem);
    background-position: top calc(0.3666667em + 0.1875rem) right calc(0.3666667em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
    border-color: #26BF7F;
    padding-right: calc(0.75em + 2.3125rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28110, 117, 130, 0.44%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%235eba00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 1.75rem;
    background-size: 16px 12px, calc(0.7333333em + 0.375rem) calc(0.7333333em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
    border-color: #26BF7F;
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
    border-color: #26BF7F;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
    background-color: #78ed00;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #26BF7F;
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em;
}

.was-validated .form-file-input:valid~.form-file-label,
.form-file-input.is-valid~.form-file-label {
    border-color: #26BF7F;
}

.was-validated .form-file-input:valid:focus~.form-file-label,
.form-file-input.is-valid:focus~.form-file-label {
    border-color: #26BF7F;
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 87.5%;
    color: #cd201f;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.765625rem;
    color: #ffffff;
    background-color: rgba(205, 32, 31, 0.9);
    border-radius: 3px;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #cd201f;
    padding-right: calc(1.4666667em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23cd201f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.3666667em + 0.1875rem) center;
    background-size: calc(0.7333333em + 0.375rem) calc(0.7333333em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: #cd201f;
    box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.4666667em + 0.75rem);
    background-position: top calc(0.3666667em + 0.1875rem) right calc(0.3666667em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
    border-color: #cd201f;
    padding-right: calc(0.75em + 2.3125rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28110, 117, 130, 0.44%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23cd201f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 1.75rem;
    background-size: 16px 12px, calc(0.7333333em + 0.375rem) calc(0.7333333em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
    border-color: #cd201f;
    box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
    border-color: #cd201f;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
    background-color: #e23e3d;
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: #cd201f;
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em;
}

.was-validated .form-file-input:invalid~.form-file-label,
.form-file-input.is-invalid~.form-file-label {
    border-color: #cd201f;
}

.was-validated .form-file-input:invalid:focus~.form-file-label,
.form-file-input.is-invalid:focus~.form-file-label {
    border-color: #cd201f;
    box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.25);
}

.btn {
    display: inline-block;
    font-weight: 500;
    line-height: 1.5;
    color: #354052;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 1rem;
    /* font-size: 0.875rem; */
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    color: #354052;
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65;
}

.btn-primary {
    color: #ffffff;
    background-color: #0062AF;
    border-color: #0062AF;
}

.btn-primary:hover {
    color: #ffffff;
    background-color: #1b59a3;
    border-color: #195398;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #ffffff;
    background-color: #1b59a3;
    border-color: #195398;
    box-shadow: 0 0 0 0.2rem rgba(65, 129, 205, 0.5);
}

.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #195398;
    border-color: #174d8d;
}

.btn-primary:active:focus,
.btn-primary.active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 129, 205, 0.5);
}

.btn-primary:disabled,
.btn-primary.disabled {
    color: #ffffff;
    background-color: #206bc4;
    border-color: #206bc4;
}

.btn-secondary {
    color: #ffffff;
    background-color: #6e7582;
    border-color: #6e7582;
}

.btn-secondary:hover {
    color: #ffffff;
    background-color: #FFFFFF;
    border-color: #575c66;
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: #ffffff;
    background-color: #5c626d;
    border-color: #575c66;
    box-shadow: 0 0 0 0.2rem rgba(132, 138, 149, 0.5);
}

.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #575c66;
    border-color: #51565f;
}

.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(132, 138, 149, 0.5);
}

.btn-secondary:disabled,
.btn-secondary.disabled {
    color: #ffffff;
    background-color: #6e7582;
    border-color: #6e7582;
}

.btn-success {
    color: #ffffff;
    background-color: #199026;
    border-color: #199026;
}

.btn-success:hover {
    color: #ffffff;
    background-color: #4b9400;
    border-color: #448700;
}

.btn-success:focus,
.btn-success.focus {
    color: #ffffff;
    background-color: #4b9400;
    border-color: #448700;
    box-shadow: 0 0 0 0.2rem rgba(118, 196, 38, 0.5);
}

.btn-success:active,
.btn-success.active,
.show>.btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #448700;
    border-color: #3e7a00;
}

.btn-success:active:focus,
.btn-success.active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 196, 38, 0.5);
}

.btn-success:disabled,
.btn-success.disabled {
    color: #ffffff;
    background-color: #26BF7F;
    border-color: #26BF7F;
}

.btn-info {
    color: #ffffff;
    background-color: #45aaf2;
    border-color: #45aaf2;
}

.btn-info:hover {
    color: #ffffff;
    background-color: #219af0;
    border-color: #1594ef;
}

.btn-info:focus,
.btn-info.focus {
    color: #ffffff;
    background-color: #219af0;
    border-color: #1594ef;
    box-shadow: 0 0 0 0.2rem rgba(97, 183, 244, 0.5);
}

.btn-info:active,
.btn-info.active,
.show>.btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #1594ef;
    border-color: #108ee7;
}

.btn-info:active:focus,
.btn-info.active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(97, 183, 244, 0.5);
}

.btn-info:disabled,
.btn-info.disabled {
    color: #ffffff;
    background-color: #45aaf2;
    border-color: #45aaf2;
}

.btn-warning {
    color: #ffffff;
    background-color: #fab005;
    border-color: #fab005;
}

.btn-warning:hover {
    color: #ffffff;
    background-color: #d59604;
    border-color: #c88d04;
}

.btn-warning:focus,
.btn-warning.focus {
    color: #ffffff;
    background-color: #d59604;
    border-color: #c88d04;
    box-shadow: 0 0 0 0.2rem rgba(251, 188, 43, 0.5);
}

.btn-warning:active,
.btn-warning.active,
.show>.btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #c88d04;
    border-color: #bc8404;
}

.btn-warning:active:focus,
.btn-warning.active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(251, 188, 43, 0.5);
}

.btn-warning:disabled,
.btn-warning.disabled {
    color: #ffffff;
    background-color: #fab005;
    border-color: #fab005;
}

.btn-danger {
    color: #ffffff;
    background-color: #cd201f;
    border-color: #cd201f;
}

.btn-danger:hover {
    color: #ffffff;
    background-color: #ac1b1a;
    border-color: #a11918;
}

.btn-danger:focus,
.btn-danger.focus {
    color: #ffffff;
    background-color: #ac1b1a;
    border-color: #a11918;
    box-shadow: 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
}

.btn-danger:active,
.btn-danger.active,
.show>.btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #a11918;
    border-color: #961717;
}

.btn-danger:active:focus,
.btn-danger.active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
}

.btn-danger:disabled,
.btn-danger.disabled {
    color: #ffffff;
    background-color: #cd201f;
    border-color: #cd201f;
}

.btn-light {
    color: #333333;
    background-color: #dddddd;
    border-color: #dddddd;
}

.btn-light:hover {
    color: #354052;
    background-color: #e3e3e3;
    border-color: #d1daec;
}

.btn-light:focus,
.btn-light.focus {
    color: #354052;
    background-color: #e3e3e3;
    border-color: #d1daec;
    box-shadow: 0 0 0 0.2rem rgba(216, 220, 226, 0.5);
}

.btn-light:active,
.btn-light.active,
.show>.btn-light.dropdown-toggle {
    color: #354052;
    background-color: #d1daec;
    border-color: #c7d3e9;
}

.btn-light:active:focus,
.btn-light.active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 220, 226, 0.5);
}

.btn-light:disabled,
.btn-light.disabled {
    color: #354052;
    background-color: #f5f7fb;
    border-color: #f5f7fb;
}

.btn-dark {
    color: #ffffff;
    background-color: #354052;
    border-color: #354052;
}

.btn-dark:hover {
    color: #ffffff;
    background-color: #262e3b;
    border-color: #212833;
}

.btn-dark:focus,
.btn-dark.focus {
    color: #ffffff;
    background-color: #262e3b;
    border-color: #212833;
    box-shadow: 0 0 0 0.2rem rgba(83, 93, 108, 0.5);
}

.btn-dark:active,
.btn-dark.active,
.show>.btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #212833;
    border-color: #1c222b;
}

.btn-dark:active:focus,
.btn-dark.active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(83, 93, 108, 0.5);
}

.btn-dark:disabled,
.btn-dark.disabled {
    color: #ffffff;
    background-color: #354052;
    border-color: #354052;
}

.btn-outline-primary {
    color: #206bc4;
    border-color: #206bc4;
}

.btn-outline-primary:hover {
    color: #ffffff;
    background-color: #206bc4;
    border-color: #206bc4;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.5);
}

.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
    color: #ffffff;
    background-color: #206bc4;
    border-color: #206bc4;
}

.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.5);
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
    color: #206bc4;
    background-color: transparent;
}

.btn-outline-secondary {
    color: #6e7582;
    border-color: #6e7582;
}

.btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #6e7582;
    border-color: #6e7582;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(110, 117, 130, 0.5);
}

.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
    color: #ffffff;
    background-color: #6e7582;
    border-color: #6e7582;
}

.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.2rem rgba(110, 117, 130, 0.5);
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
    color: #6e7582;
    background-color: transparent;
}

.btn-outline-success {
    color: #26BF7F;
    border-color: #26BF7F;
}

.btn-outline-success:hover {
    color: #ffffff;
    background-color: #26BF7F;
    border-color: #26BF7F;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.5);
}

.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
    color: #ffffff;
    background-color: #26BF7F;
    border-color: #26BF7F;
}

.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.5);
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
    color: #26BF7F;
    background-color: transparent;
}

.btn-outline-info {
    color: #45aaf2;
    border-color: #45aaf2;
}

.btn-outline-info:hover {
    color: #ffffff;
    background-color: #45aaf2;
    border-color: #45aaf2;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 170, 242, 0.5);
}

.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
    color: #ffffff;
    background-color: #45aaf2;
    border-color: #45aaf2;
}

.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 170, 242, 0.5);
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
    color: #45aaf2;
    background-color: transparent;
}

.btn-outline-warning {
    color: #fab005;
    border-color: #fab005;
}

.btn-outline-warning:hover {
    color: #ffffff;
    background-color: #fab005;
    border-color: #fab005;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 176, 5, 0.5);
}

.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
    color: #ffffff;
    background-color: #fab005;
    border-color: #fab005;
}

.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 176, 5, 0.5);
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
    color: #fab005;
    background-color: transparent;
}

.btn-outline-danger {
    color: #cd201f;
    border-color: #cd201f;
}

.btn-outline-danger:hover {
    color: #ffffff;
    background-color: #cd201f;
    border-color: #cd201f;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
}

.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
    color: #ffffff;
    background-color: #cd201f;
    border-color: #cd201f;
}

.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
    color: #cd201f;
    background-color: transparent;
}

.btn-outline-light {
    color: #f5f7fb;
    border-color: #f5f7fb;
}

.btn-outline-light:hover {
    color: #354052;
    background-color: #f5f7fb;
    border-color: #f5f7fb;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 247, 251, 0.5);
}

.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
    color: #354052;
    background-color: #f5f7fb;
    border-color: #f5f7fb;
}

.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 247, 251, 0.5);
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
    color: #f5f7fb;
    background-color: transparent;
}

.btn-outline-dark {
    color: #354052;
    border-color: #354052;
}

.btn-outline-dark:hover {
    color: #ffffff;
    background-color: #354052;
    border-color: #354052;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(53, 64, 82, 0.5);
}

.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
    color: #ffffff;
    background-color: #354052;
    border-color: #354052;
}

.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.2rem rgba(53, 64, 82, 0.5);
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
    color: #354052;
    background-color: transparent;
}

.btn-link {
    font-weight: 400;
    color: #206bc4;
    text-decoration: none;
}

.btn-link:hover {
    color: #154782;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #a8aeb7;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 0.5rem 1.5rem;
    font-size: calc(1.2625rem + 0.15vw);
    border-radius: 0.3rem;
}

@media (min-width: 1200px) {
    .btn-lg,
    .btn-group-lg>.btn {
        font-size: 1.375rem;
    }
}

.btn-sm,
.btn-group-sm>.btn {
    padding: 0.125rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 0.2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

.fade {
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle:after {
    content: "";
    display: inline-block;
    vertical-align: 0.306em;
    width: 0.36em;
    height: 0.36em;
    border-bottom: 1px solid;
    border-left: 1px solid;
    margin-right: .1em;
    margin-left: 0.4em;
    transform: rotate(-45deg);
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    min-width: 12rem !important;
    padding: 0.5rem 0;
    margin: 1px 0 0;
    /* font-size: 0.875rem; */
    color: #354052;
    text-align: left;
    list-style: none;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

@media (min-width: 33rem) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 48rem) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 64rem) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 75.25rem) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 10px;
}

.dropup .dropdown-toggle:after {
    content: "";
    display: inline-block;
    vertical-align: 0.306em;
    width: 0.36em;
    height: 0.36em;
    border-bottom: 1px solid;
    border-left: 1px solid;
    margin-right: .1em;
    margin-left: 0.4em;
    transform: rotate(135deg);
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 1px;
}

.dropright .dropdown-toggle:after {
    content: "";
    display: inline-block;
    vertical-align: 0.306em;
    width: 0.36em;
    height: 0.36em;
    border-bottom: 1px solid;
    border-left: 1px solid;
    margin-right: .1em;
    margin-left: 0.4em;
    transform: rotate(-135deg);
}

.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 1px;
}

.dropleft .dropdown-toggle:before {
    content: "";
    display: inline-block;
    vertical-align: 0.306em;
    width: 0.36em;
    height: 0.36em;
    border-bottom: 1px solid;
    border-left: 1px solid;
    margin-right: .1em;
    margin-right: 0.4em;
    transform: rotate(45deg);
}

.dropleft .dropdown-toggle:after {
    content: none;
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(110, 117, 130, 0.2);
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #354052;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: inherit;
    background-color: rgba(110, 117, 130, 0.06);
}

.dropdown-item.active,
.dropdown-item:active {
    color: #206bc4;
    text-decoration: none;
    background-color: rgba(32, 107, 196, 0.06);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #a8aeb7;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 0.765625rem;
    color: #a8aeb7;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1rem;
    color: #354052;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
    position: relative;
    flex: 1 1 auto;
}

.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
    z-index: 1;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem;
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.25rem 0.75rem;
}

.nav-link.disabled {
    color: #a8aeb7;
    pointer-events: none;
    cursor: default;
}

.nav-tabs {
    border-bottom: 1px solid #e9ecf1;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #f1f3f8 #f1f3f8 #e9ecf1;
}

.nav-tabs .nav-link.disabled {
    color: #a8aeb7;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #7a828f;
    background-color: #f5f7fb;
    border-color: #e9ecf1 #e9ecf1 #f5f7fb;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 3px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #ffffff;
    background-color: #206bc4;
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-right: 0;
    font-size: 1.125rem;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
}

.navbar-text {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.navbar-collapse {
    align-items: center;
    width: 100%;
}

.navbar-toggler {
    padding: 0 0;
    font-size: 1rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
        transition: none;
    }
}

.navbar-toggler:hover {
    text-decoration: none;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

@media (min-width: 33rem) {
    .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
    }
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (min-width: 48rem) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
    }
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (min-width: 64rem) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (min-width: 75.25rem) {
    .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
    }
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.navbar-expand .navbar-nav {
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.navbar-expand .navbar-collapse {
    display: flex !important;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand {
    color: #354052;
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: #354052;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(53, 64, 82, 0.72);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(53, 64, 82, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: #354052;
}

.navbar-light .navbar-toggler {
    color: rgba(53, 64, 82, 0.72);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2853, 64, 82, 0.72%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
    color: rgba(53, 64, 82, 0.72);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: #354052;
}

.navbar-dark .navbar-brand {
    color: #ffffff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.72);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.3);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #ffffff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.72);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.72%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.72);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #ffffff;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px;
}

.card>hr,
.card>.hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.card-body {
    flex: 1 1 auto;
    padding: 0.5rem 0.75rem;
		@media (min-width: 1200px) {
			padding: 1.25rem 2rem;
    }
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(53, 64, 82, 0.024);
    border-bottom: 1px solid rgba(110, 117, 130, 0.2);
}

.card-header:first-child {
    border-radius: 2px 2px 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(53, 64, 82, 0.024);
    border-top: 1px solid rgba(110, 117, 130, 0.2);
}

.card-footer:last-child {
    border-radius: 0 0 2px 2px;
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-tabs .nav-link.active {
    background-color: #ffffff;
    border-bottom-color: #ffffff;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
    width: 100%;
}

.card-img,
.card-img-top {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.card-group>.card {
    margin-bottom: 1.5rem;
}

@media (min-width: 33rem) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }
    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }
    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }
    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }
    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.accordion>.card {
    overflow: hidden;
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: -1px;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #f1f3f8;
    border-radius: 3px;
}

.breadcrumb-item {
    display: flex;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #a8aeb7;
    content: "/";
}

.breadcrumb-item.active {
    color: #a8aeb7;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.page-link {
    position: relative;
    display: block;
    color: #6e7582;
    background-color: transparent;
    border: 0 solid #e9ecf1;
}

.page-link:hover {
    z-index: 2;
    color: #154782;
    background-color: #f1f3f8;
    border-color: #e9ecf1;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.page-item:not(:first-child) .page-link {
    margin-left: 0;
}

.page-item.active .page-link {
    z-index: 3;
    color: #ffffff;
    background-color: #206bc4;
    border-color: #206bc4;
}

.page-item.disabled .page-link {
    color: rgba(110, 117, 130, 0.5);
    pointer-events: none;
    background-color: transparent;
    border-color: #e9ecf1;
}

.page-link {
    padding: 0.25rem 0.25rem;
}

.page-item:first-child .page-link {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.09375rem;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.765625rem;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.badge {
    display: inline-block;
    padding: 0.125rem 0.25rem;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3px;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 3px;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 500;
}

.alert-dismissible {
    padding-right: 4rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #061527;
    background-color: #d2e1f3;
    border-color: #a6c4e7;
}

.alert-primary .alert-link {
    color: black;
}

.alert-secondary {
    color: #16171a;
    background-color: #e2e3e6;
    border-color: #c5c8cd;
}

.alert-secondary .alert-link {
    color: black;
}

.alert-success {
    color: #132500;
    background-color: #dff1cc;
    border-color: #bfe399;
}

.alert-success .alert-link {
    color: black;
}

.alert-info {
    color: #0e2230;
    background-color: #daeefc;
    border-color: #b5ddfa;
}

.alert-info .alert-link {
    color: #020609;
}

.alert-warning {
    color: #322301;
    background-color: #feefcd;
    border-color: #fddf9b;
}

.alert-warning .alert-link {
    color: black;
}

.alert-danger {
    color: #290606;
    background-color: #f5d2d2;
    border-color: #eba6a5;
}

.alert-danger .alert-link {
    color: black;
}

.alert-light {
    color: #313132;
    background-color: #fdfdfe;
    border-color: #fbfcfd;
}

.alert-light .alert-link {
    color: #181818;
}

.alert-dark {
    color: #0b0d10;
    background-color: #d7d9dc;
    border-color: #aeb3ba;
}

.alert-dark .alert-link {
    color: black;
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: 0.5rem;
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 0.5rem;
    }
}

.progress {
    display: flex;
    height: 0.5rem;
    overflow: hidden;
    font-size: 0.65625rem;
    background-color: rgba(176, 181, 190, 0.24);
    border-radius: 3px;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    background-color: #206bc4;
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 0.5rem 0.5rem;
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none;
    }
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: inherit;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: inherit;
    text-decoration: none;
    background-color: #f5f7fb;
}

.list-group-item-action:active {
    color: #354052;
    background-color: #f1f3f8;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    background-color: inherit;
    border: 1px solid rgba(110, 117, 130, 0.2);
}

.list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.list-group-item:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #a8aeb7;
    pointer-events: none;
    background-color: inherit;
}

.list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #206bc4;
    border-color: #206bc4;
}

.list-group-item+.list-group-item {
    border-top-width: 0;
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal .list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}

.list-group-horizontal .list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 33rem) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }
    .list-group-horizontal-sm .list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-sm .list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-sm .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-sm .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 48rem) {
    .list-group-horizontal-md {
        flex-direction: row;
    }
    .list-group-horizontal-md .list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-md .list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-md .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-md .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 64rem) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }
    .list-group-horizontal-lg .list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-lg .list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-lg .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-lg .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 75.25rem) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }
    .list-group-horizontal-xl .list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-xl .list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-xl .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-xl .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
    border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: #0d2b4e;
    background-color: #e9f0f9;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #0d2b4e;
    background-color: #d5e2f4;
}

.list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #0d2b4e;
    border-color: #0d2b4e;
}

.list-group-item-secondary {
    color: #2c2f34;
    background-color: #f1f1f3;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #2c2f34;
    background-color: #e3e3e7;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #2c2f34;
    border-color: #2c2f34;
}

.list-group-item-success {
    color: #264a00;
    background-color: #eff8e6;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #264a00;
    background-color: #e2f2d2;
}

.list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #264a00;
    border-color: #264a00;
}

.list-group-item-info {
    color: #1c4461;
    background-color: #ecf7fe;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #1c4461;
    background-color: #d4edfd;
}

.list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #1c4461;
    border-color: #1c4461;
}

.list-group-item-warning {
    color: #644602;
    background-color: #fff7e6;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #644602;
    background-color: #ffefcd;
}

.list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #644602;
    border-color: #644602;
}

.list-group-item-danger {
    color: #520d0c;
    background-color: #fae9e9;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #520d0c;
    background-color: #f5d4d4;
}

.list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #520d0c;
    border-color: #520d0c;
}

.list-group-item-light {
    color: #626364;
    background-color: #fefeff;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #626364;
    background-color: #e5e5ff;
}

.list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #626364;
    border-color: #626364;
}

.list-group-item-dark {
    color: #151a21;
    background-color: #ebecee;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #151a21;
    background-color: #dddfe2;
}

.list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #151a21;
    border-color: #151a21;
}

.close {
    font-size: calc(1.275rem + 0.3vw);
    font-weight: 400;
    line-height: 1;
    color: #000000;
    text-shadow: 0 1px 0 #ffffff;
    opacity: .5;
}

@media (min-width: 1200px) {
    .close {
        font-size: 1.5rem;
    }
}

.close:hover {
    color: #000000;
    text-decoration: none;
}

.close:hover,
.close:focus {
    opacity: .75;
}

.close:disabled,
.close.disabled {
    pointer-events: none;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(110, 117, 130, 0.2);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 3px;
}

.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #6e7582;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
    padding: 0.75rem;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -1rem);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fbfbfb;
    background-clip: padding-box;
    border: 1px solid rgba(110, 117, 130, 0.1);
    border-radius: 3px;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #354052;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.24;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(110, 117, 130, 0.1);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.modal-header .close {
    padding: 1.5rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.7142857;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1.5rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 1.125rem;
    border-top: 0 solid rgba(110, 117, 130, 0.1);
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.modal-footer>* {
    margin: 0.375rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 33rem) {
    .modal-dialog {
        max-width: 540px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .modal-sm {
        max-width: 380px;
    }
}

@media (min-width: 64rem) {
    .modal-lg,
    .modal-xl {
        max-width: 720px;
    }
}

@media (min-width: 75.25rem) {
    .modal-xl {
        max-width: 1140px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7142857;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.85rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[x-placement^="top"] .tooltip-arrow {
    bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .tooltip-arrow,
.bs-tooltip-auto[x-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
    top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .tooltip-arrow,
.bs-tooltip-auto[x-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000000;
}

.tooltip-inner {
    max-width: 300px;
    padding: 0.5rem 0.75rem;
    color: #ffffff;
    /* text-align: center; */
    background-color: #000000;
    border-radius: 3px;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Ubuntu", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7142857;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.765625rem;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 0.3rem;
}

.popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[x-placement^="top"]>.popover-arrow {
    bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[x-placement^="top"]>.popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(110, 117, 130, 0.25);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[x-placement^="top"]>.popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #ffffff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
}

.bs-popover-right>.popover-arrow,
.bs-popover-auto[x-placement^="right"]>.popover-arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right>.popover-arrow::before,
.bs-popover-auto[x-placement^="right"]>.popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(110, 117, 130, 0.25);
}

.bs-popover-right>.popover-arrow::after,
.bs-popover-auto[x-placement^="right"]>.popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #ffffff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
}

.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[x-placement^="bottom"]>.popover-arrow {
    top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[x-placement^="bottom"]>.popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(110, 117, 130, 0.25);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #ffffff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
}

.bs-popover-left>.popover-arrow,
.bs-popover-auto[x-placement^="left"]>.popover-arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-left>.popover-arrow::before,
.bs-popover-auto[x-placement^="left"]>.popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(110, 117, 130, 0.25);
}

.bs-popover-left>.popover-arrow::after,
.bs-popover-auto[x-placement^="left"]>.popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #ffffff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #354052;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
    transform: translateX(-100%);
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #ffffff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
        transition: none;
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='15 18 9 12 15 6'%3e%3c/polyline%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='9 18 15 12 9 6'%3e%3c/polyline%3e%3c/svg%3e");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #ffffff;
    text-align: center;
}

@-webkit-keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: text-bottom;
    border: 2px solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 1px;
}

@-webkit-keyframes spinner-grow {
    0% {
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow 0.75s linear infinite;
    animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.link-primary {
    color: #206bc4;
}

.link-control {
    color: #e8bc6b;
}

.link-primary:hover,
.link-primary:focus {
    color: #154782;
}

.link-secondary {
    color: #6e7582;
}

.link-secondary:hover,
.link-secondary:focus {
    color: #4b5059;
}

.link-success {
    color: #26BF7F;
}

.link-success:hover,
.link-success:focus {
    color: #376e00;
}

.link-info {
    color: #45aaf2;
}

.link-info:hover,
.link-info:focus {
    color: #0f86db;
}

.link-warning {
    color: #fab005;
}

.link-warning:hover,
.link-warning:focus {
    color: #af7b04;
}

.link-danger {
    color: #cd201f;
}

.link-danger:hover,
.link-danger:focus {
    color: #8b1615;
}

.link-light {
    color: #f5f7fb;
}

.link-light:hover,
.link-light:focus {
    color: #becbe5;
}

.link-dark {
    color: #354052;
}

.link-dark:hover,
.link-dark:focus {
    color: #171c24;
}

.embed-responsive {
    position: relative;
    width: 100%;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.8571429%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.embed-responsive-2by1::before {
    padding-top: 50%;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
    @media (min-width: 33rem) {
        .sticky-sm-top {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 1020;
        }
    }
    @media (min-width: 48rem) {
        .sticky-md-top {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 1020;
        }
    }
    @media (min-width: 64rem) {
        .sticky-lg-top {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 1020;
        }
    }
    @media (min-width: 75.25rem) {
        .sticky-xl-top {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 1020;
        }
    }
}

.sr-only,
.sr-only-focusable:not(:focus) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.border {
    border: 1px solid rgba(110, 117, 130, 0.2) !important;
}

.border-wide {
    border: 2px solid rgba(110, 117, 130, 0.2) !important;
}

.border-0 {
    border: 0 !important;
}

.border-top {
    border-top: 1px solid rgba(110, 117, 130, 0.2) !important;
}

.border-top-wide {
    border-top: 2px solid rgba(110, 117, 130, 0.2) !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right {
    border-right: 1px solid rgba(110, 117, 130, 0.2) !important;
}

.border-right-wide {
    border-right: 2px solid rgba(110, 117, 130, 0.2) !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom {
    border-bottom: 1px solid rgba(110, 117, 130, 0.2) !important;
}

.border-bottom-wide {
    border-bottom: 2px solid rgba(110, 117, 130, 0.2) !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left {
    border-left: 1px solid rgba(110, 117, 130, 0.2) !important;
}

.border-left-wide {
    border-left: 2px solid rgba(110, 117, 130, 0.2) !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #206bc4 !important;
}

.border-control {
    border-color: #e8bc6b !important;
}

.border-secondary {
    border-color: #6e7582 !important;
}

.border-success {
    border-color: #26BF7F !important;
}

.border-info {
    border-color: #45aaf2 !important;
}

.border-warning {
    border-color: #fab005 !important;
}

.border-danger {
    border-color: #cd201f !important;
}

.border-light {
    border-color: #f5f7fb !important;
}

.border-dark {
    border-color: #354052 !important;
}

.border-white {
    border-color: #ffffff !important;
}

.w-1 {
    width: 1% !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.vw-100 {
    width: 100vw !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.h-1 {
    height: 1% !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mh-100 {
    max-height: 100% !important;
}

.vh-100 {
    height: 100vh !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    -ms-grid-row-align: center !important;
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
}

.order-first {
    order: -1 !important;
}

.order-0 {
    order: 0 !important;
}

.order-1 {
    order: 1 !important;
}

.order-2 {
    order: 2 !important;
}

.order-3 {
    order: 3 !important;
}

.order-4 {
    order: 4 !important;
}

.order-5 {
    order: 5 !important;
}

.order-last {
    order: 6 !important;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.m-6 {
    margin: 4.5rem !important;
}

.m-auto {
    margin: auto !important;
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.mx-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.my-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.mt-6 {
    margin-top: 4.5rem !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mr-5 {
    margin-right: 3rem !important;
}

.mr-6 {
    margin-right: 4.5rem !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.mb-6 {
    margin-bottom: 4.5rem !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.ml-5 {
    margin-left: 3rem !important;
}

.ml-6 {
    margin-left: 4.5rem !important;
}

.ml-auto {
    margin-left: auto !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.m-n6 {
    margin: -4.5rem !important;
}

.mx-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
}

.mx-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
}

.mx-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
}

.mx-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
}

.mx-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
}

.mx-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
}

.my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
}

.my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
}

.my-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
}

.my-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
}

.my-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
}

.my-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
}

.mt-n1 {
    margin-top: -0.25rem !important;
}

.mt-n2 {
    margin-top: -0.5rem !important;
}

.mt-n3 {
    margin-top: -1rem !important;
}

.mt-n4 {
    margin-top: -1.5rem !important;
}

.mt-n5 {
    margin-top: -3rem !important;
}

.mt-n6 {
    margin-top: -4.5rem !important;
}

.mr-n1 {
    margin-right: -0.25rem !important;
}

.mr-n2 {
    margin-right: -0.5rem !important;
}

.mr-n3 {
    margin-right: -1rem !important;
}

.mr-n4 {
    margin-right: -1.5rem !important;
}

.mr-n5 {
    margin-right: -3rem !important;
}

.mr-n6 {
    margin-right: -4.5rem !important;
}

.mb-n1 {
    margin-bottom: -0.25rem !important;
}

.mb-n2 {
    margin-bottom: -0.5rem !important;
}

.mb-n3 {
    margin-bottom: -1rem !important;
}

.mb-n4 {
    margin-bottom: -1.5rem !important;
}

.mb-n5 {
    margin-bottom: -3rem !important;
}

.mb-n6 {
    margin-bottom: -4.5rem !important;
}

.ml-n1 {
    margin-left: -0.25rem !important;
}

.ml-n2 {
    margin-left: -0.5rem !important;
}

.ml-n3 {
    margin-left: -1rem !important;
}

.ml-n4 {
    margin-left: -1.5rem !important;
}

.ml-n5 {
    margin-left: -3rem !important;
}

.ml-n6 {
    margin-left: -4.5rem !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.p-6 {
    padding: 4.5rem !important;
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.px-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.py-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pt-6 {
    padding-top: 4.5rem !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: 0.25rem !important;
}

.pr-2 {
    padding-right: 0.5rem !important;
}

.pr-3 {
    padding-right: 1rem !important;
}

.pr-4 {
    padding-right: 1.5rem !important;
}

.pr-5 {
    padding-right: 3rem !important;
}

.pr-6 {
    padding-right: 4.5rem !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.pb-6 {
    padding-bottom: 4.5rem !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 0.25rem !important;
}

.pl-2 {
    padding-left: 0.5rem !important;
}

.pl-3 {
    padding-left: 1rem !important;
}

.pl-4 {
    padding-left: 1.5rem !important;
}

.pl-5 {
    padding-left: 3rem !important;
}

.pl-6 {
    padding-left: 4.5rem !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-medium {
    font-weight: 500 !important;
}

.font-weight-semibold {
    font-weight: 600 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-extrabold {
    font-weight: 800 !important;
}

.font-weight-black {
    font-weight: 900 !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-primary {
    color: #0062AF !important;
}

.text-control {
    color: #e8bc6b !important;
}

.text-secondary {
    color: #6e7582 !important;
}

.text-success {
    color: #26BF7F !important;
}

.text-info {
    color: #45aaf2 !important;
}

.text-warning {
    color: #fab005 !important;
}

.text-danger {
    color: #cd201f !important;
}

.text-light {
    color: #f5f7fb !important;
}

.text-dark {
    color: #354052 !important;
}

.text-white {
    color: #ffffff !important;
}

.text-body {
    color: #354052 !important;
}

.text-muted {
    color: #6e7582 !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
    color: inherit !important;
}

.lh-1 {
    line-height: 1 !important;
}

.lh-sm {
    line-height: 1.3333334 !important;
}

.lh-base {
    line-height: 1.7142857 !important;
}

.lh-lg {
    line-height: 2.1333334 !important;
}

.bg-primary {
    background-color: #0062AF !important;
}

.bg-control {
    background-color: #e8bc6b !important;
}

.bg-secondary {
    background-color: #6e7582 !important;
}

.bg-success {
    background-color: #26BF7F !important;
}

.bg-info {
    background-color: #45aaf2 !important;
}

.bg-warning {
    background-color: #fab005 !important;
}

.bg-danger {
    background-color: #cd201f !important;
}

.bg-light {
    background-color: #DDDDDD !important;
}

.bg-dark {
    background-color: #354052 !important;
}

.bg-body {
    background-color: #f5f7fb !important;
}

.bg-white {
    background-color: #ffffff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-success-10 {
    background-color: #26BF7F1A;
}

.bg-success-15 {
    background-color: #26BF7F26
}

.bg-success-25 {
    background-color: #26BF7F33
}

.bg-primary-15 {
    background-color: #0062AF26
}

.bg-primary-25 {
    background-color: #0062AF33
}

.bg-info-15 {
    background-color: #45aaf226
}

.bg-info-25 {
    background-color: #45aaf233
}

.bg-warning-15 {
    background-color: #fab00526
}

.bg-warning-25 {
    background-color: #fab00533
}

.bg-control-15 {
    background-color: #fab00526
}

.bg-control-25 {
    background-color: #fab00533
}

.bg-secondary-15 {
    background-color: #6e758226
}

.bg-secondary-25 {
    background-color: #6e758233
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.text-decoration-line-through {
    text-decoration: line-through !important;
}

.font-italic {
    font-style: italic !important;
}

.font-normal {
    font-style: normal !important;
}

.text-break {
    word-wrap: break-word !important;
}

.font-monospace {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important;
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.pe-none {
    pointer-events: none !important;
}

.pe-auto {
    pointer-events: auto !important;
}

.rounded {
    border-radius: 3px !important;
}

.rounded-sm {
    border-radius: 0.2rem !important;
}

.rounded-lg {
    border-radius: 0.3rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.rounded-top {
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}

.rounded-right {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
    border-bottom-right-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.rounded-left {
    border-bottom-left-radius: 3px !important;
    border-top-left-radius: 3px !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

.object-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
}

.object-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
}

.object-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
}

.object-scale-down {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
}

.object-none {
    -o-object-fit: none !important;
    object-fit: none !important;
}

.tracking-tight {
    letter-spacing: -0.05em !important;
}

.tracking-normal {
    letter-spacing: 0 !important;
}

.tracking-wide {
    letter-spacing: 0.05em !important;
}

.cursor-auto {
    cursor: auto !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-move {
    cursor: move !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-zoom-in {
    cursor: zoom-in !important;
}

.cursor-zoom-out {
    cursor: zoom-out !important;
}

.text-h1 {
    font-size: 1.75rem !important;
}

.text-h2 {
    font-size: 1.375rem !important;
}

.text-h3 {
    font-size: 1.125rem !important;
}

.text-h4 {
    font-size: 0.875rem !important;
}

.text-h5 {
    font-size: 0.75rem !important;
}

.text-h6 {
    font-size: 0.625rem !important;
}

.leading-0 {
    line-height: 0 !important;
}

.leading-1 {
    line-height: 0.25rem !important;
}

.leading-2 {
    line-height: 0.5rem !important;
}

.leading-3 {
    line-height: 1rem !important;
}

.leading-4 {
    line-height: 1.5rem !important;
}

.leading-5 {
    line-height: 3rem !important;
}

.leading-6 {
    line-height: 4.5rem !important;
}

@media (min-width: 33rem) {
    .float-sm-left {
        float: left !important;
    }
    .float-sm-right {
        float: right !important;
    }
    .float-sm-none {
        float: none !important;
    }
    .d-sm-none {
        display: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-table {
        display: table !important;
    }
    .d-sm-table-row {
        display: table-row !important;
    }
    .d-sm-table-cell {
        display: table-cell !important;
    }
    .d-sm-flex {
        display: flex !important;
    }
    .d-sm-inline-flex {
        display: inline-flex !important;
    }
    .flex-sm-fill {
        flex: 1 1 auto !important;
    }
    .flex-sm-row {
        flex-direction: row !important;
    }
    .flex-sm-column {
        flex-direction: column !important;
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-sm-start {
        justify-content: flex-start !important;
    }
    .justify-content-sm-end {
        justify-content: flex-end !important;
    }
    .justify-content-sm-center {
        justify-content: center !important;
    }
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
    .justify-content-sm-around {
        justify-content: space-around !important;
    }
    .align-items-sm-start {
        align-items: flex-start !important;
    }
    .align-items-sm-end {
        align-items: flex-end !important;
    }
    .align-items-sm-center {
        align-items: center !important;
    }
    .align-items-sm-baseline {
        align-items: baseline !important;
    }
    .align-items-sm-stretch {
        align-items: stretch !important;
    }
    .align-content-sm-start {
        align-content: flex-start !important;
    }
    .align-content-sm-end {
        align-content: flex-end !important;
    }
    .align-content-sm-center {
        align-content: center !important;
    }
    .align-content-sm-between {
        align-content: space-between !important;
    }
    .align-content-sm-around {
        align-content: space-around !important;
    }
    .align-content-sm-stretch {
        align-content: stretch !important;
    }
    .align-self-sm-auto {
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
    }
    .align-self-sm-start {
        align-self: flex-start !important;
    }
    .align-self-sm-end {
        align-self: flex-end !important;
    }
    .align-self-sm-center {
        -ms-grid-row-align: center !important;
        align-self: center !important;
    }
    .align-self-sm-baseline {
        align-self: baseline !important;
    }
    .align-self-sm-stretch {
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
    }
    .order-sm-first {
        order: -1 !important;
    }
    .order-sm-0 {
        order: 0 !important;
    }
    .order-sm-1 {
        order: 1 !important;
    }
    .order-sm-2 {
        order: 2 !important;
    }
    .order-sm-3 {
        order: 3 !important;
    }
    .order-sm-4 {
        order: 4 !important;
    }
    .order-sm-5 {
        order: 5 !important;
    }
    .order-sm-last {
        order: 6 !important;
    }
    .m-sm-0 {
        margin: 0 !important;
    }
    .m-sm-1 {
        margin: 0.25rem !important;
    }
    .m-sm-2 {
        margin: 0.5rem !important;
    }
    .m-sm-3 {
        margin: 1rem !important;
    }
    .m-sm-4 {
        margin: 1.5rem !important;
    }
    .m-sm-5 {
        margin: 3rem !important;
    }
    .m-sm-6 {
        margin: 4.5rem !important;
    }
    .m-sm-auto {
        margin: auto !important;
    }
    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-sm-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-sm-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-sm-6 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important;
    }
    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-sm-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-sm-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-sm-6 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important;
    }
    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-sm-0 {
        margin-top: 0 !important;
    }
    .mt-sm-1 {
        margin-top: 0.25rem !important;
    }
    .mt-sm-2 {
        margin-top: 0.5rem !important;
    }
    .mt-sm-3 {
        margin-top: 1rem !important;
    }
    .mt-sm-4 {
        margin-top: 1.5rem !important;
    }
    .mt-sm-5 {
        margin-top: 3rem !important;
    }
    .mt-sm-6 {
        margin-top: 4.5rem !important;
    }
    .mt-sm-auto {
        margin-top: auto !important;
    }
    .mr-sm-0 {
        margin-right: 0 !important;
    }
    .mr-sm-1 {
        margin-right: 0.25rem !important;
    }
    .mr-sm-2 {
        margin-right: 0.5rem !important;
    }
    .mr-sm-3 {
        margin-right: 1rem !important;
    }
    .mr-sm-4 {
        margin-right: 1.5rem !important;
    }
    .mr-sm-5 {
        margin-right: 3rem !important;
    }
    .mr-sm-6 {
        margin-right: 4.5rem !important;
    }
    .mr-sm-auto {
        margin-right: auto !important;
    }
    .mb-sm-0 {
        margin-bottom: 0 !important;
    }
    .mb-sm-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-sm-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-sm-3 {
        margin-bottom: 1rem !important;
    }
    .mb-sm-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-sm-5 {
        margin-bottom: 3rem !important;
    }
    .mb-sm-6 {
        margin-bottom: 4.5rem !important;
    }
    .mb-sm-auto {
        margin-bottom: auto !important;
    }
    .ml-sm-0 {
        margin-left: 0 !important;
    }
    .ml-sm-1 {
        margin-left: 0.25rem !important;
    }
    .ml-sm-2 {
        margin-left: 0.5rem !important;
    }
    .ml-sm-3 {
        margin-left: 1rem !important;
    }
    .ml-sm-4 {
        margin-left: 1.5rem !important;
    }
    .ml-sm-5 {
        margin-left: 3rem !important;
    }
    .ml-sm-6 {
        margin-left: 4.5rem !important;
    }
    .ml-sm-auto {
        margin-left: auto !important;
    }
    .m-sm-n1 {
        margin: -0.25rem !important;
    }
    .m-sm-n2 {
        margin: -0.5rem !important;
    }
    .m-sm-n3 {
        margin: -1rem !important;
    }
    .m-sm-n4 {
        margin: -1.5rem !important;
    }
    .m-sm-n5 {
        margin: -3rem !important;
    }
    .m-sm-n6 {
        margin: -4.5rem !important;
    }
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }
    .mx-sm-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }
    .mx-sm-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }
    .mx-sm-n6 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important;
    }
    .my-sm-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }
    .my-sm-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }
    .my-sm-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }
    .my-sm-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important;
    }
    .my-sm-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }
    .my-sm-n6 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important;
    }
    .mt-sm-n1 {
        margin-top: -0.25rem !important;
    }
    .mt-sm-n2 {
        margin-top: -0.5rem !important;
    }
    .mt-sm-n3 {
        margin-top: -1rem !important;
    }
    .mt-sm-n4 {
        margin-top: -1.5rem !important;
    }
    .mt-sm-n5 {
        margin-top: -3rem !important;
    }
    .mt-sm-n6 {
        margin-top: -4.5rem !important;
    }
    .mr-sm-n1 {
        margin-right: -0.25rem !important;
    }
    .mr-sm-n2 {
        margin-right: -0.5rem !important;
    }
    .mr-sm-n3 {
        margin-right: -1rem !important;
    }
    .mr-sm-n4 {
        margin-right: -1.5rem !important;
    }
    .mr-sm-n5 {
        margin-right: -3rem !important;
    }
    .mr-sm-n6 {
        margin-right: -4.5rem !important;
    }
    .mb-sm-n1 {
        margin-bottom: -0.25rem !important;
    }
    .mb-sm-n2 {
        margin-bottom: -0.5rem !important;
    }
    .mb-sm-n3 {
        margin-bottom: -1rem !important;
    }
    .mb-sm-n4 {
        margin-bottom: -1.5rem !important;
    }
    .mb-sm-n5 {
        margin-bottom: -3rem !important;
    }
    .mb-sm-n6 {
        margin-bottom: -4.5rem !important;
    }
    .ml-sm-n1 {
        margin-left: -0.25rem !important;
    }
    .ml-sm-n2 {
        margin-left: -0.5rem !important;
    }
    .ml-sm-n3 {
        margin-left: -1rem !important;
    }
    .ml-sm-n4 {
        margin-left: -1.5rem !important;
    }
    .ml-sm-n5 {
        margin-left: -3rem !important;
    }
    .ml-sm-n6 {
        margin-left: -4.5rem !important;
    }
    .p-sm-0 {
        padding: 0 !important;
    }
    .p-sm-1 {
        padding: 0.25rem !important;
    }
    .p-sm-2 {
        padding: 0.5rem !important;
    }
    .p-sm-3 {
        padding: 1rem !important;
    }
    .p-sm-4 {
        padding: 1.5rem !important;
    }
    .p-sm-5 {
        padding: 3rem !important;
    }
    .p-sm-6 {
        padding: 4.5rem !important;
    }
    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-sm-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-sm-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .px-sm-6 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important;
    }
    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-sm-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-sm-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .py-sm-6 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
    }
    .pt-sm-0 {
        padding-top: 0 !important;
    }
    .pt-sm-1 {
        padding-top: 0.25rem !important;
    }
    .pt-sm-2 {
        padding-top: 0.5rem !important;
    }
    .pt-sm-3 {
        padding-top: 1rem !important;
    }
    .pt-sm-4 {
        padding-top: 1.5rem !important;
    }
    .pt-sm-5 {
        padding-top: 3rem !important;
    }
    .pt-sm-6 {
        padding-top: 4.5rem !important;
    }
    .pr-sm-0 {
        padding-right: 0 !important;
    }
    .pr-sm-1 {
        padding-right: 0.25rem !important;
    }
    .pr-sm-2 {
        padding-right: 0.5rem !important;
    }
    .pr-sm-3 {
        padding-right: 1rem !important;
    }
    .pr-sm-4 {
        padding-right: 1.5rem !important;
    }
    .pr-sm-5 {
        padding-right: 3rem !important;
    }
    .pr-sm-6 {
        padding-right: 4.5rem !important;
    }
    .pb-sm-0 {
        padding-bottom: 0 !important;
    }
    .pb-sm-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-sm-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-sm-3 {
        padding-bottom: 1rem !important;
    }
    .pb-sm-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-sm-5 {
        padding-bottom: 3rem !important;
    }
    .pb-sm-6 {
        padding-bottom: 4.5rem !important;
    }
    .pl-sm-0 {
        padding-left: 0 !important;
    }
    .pl-sm-1 {
        padding-left: 0.25rem !important;
    }
    .pl-sm-2 {
        padding-left: 0.5rem !important;
    }
    .pl-sm-3 {
        padding-left: 1rem !important;
    }
    .pl-sm-4 {
        padding-left: 1.5rem !important;
    }
    .pl-sm-5 {
        padding-left: 3rem !important;
    }
    .pl-sm-6 {
        padding-left: 4.5rem !important;
    }
    .text-sm-left {
        text-align: left !important;
    }
    .text-sm-right {
        text-align: right !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 48rem) {
    .float-md-left {
        float: left !important;
    }
    .float-md-right {
        float: right !important;
    }
    .float-md-none {
        float: none !important;
    }
    .d-md-none {
        display: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-table {
        display: table !important;
    }
    .d-md-table-row {
        display: table-row !important;
    }
    .d-md-table-cell {
        display: table-cell !important;
    }
    .d-md-flex {
        display: flex !important;
    }
    .d-md-inline-flex {
        display: inline-flex !important;
    }
    .flex-md-fill {
        flex: 1 1 auto !important;
    }
    .flex-md-row {
        flex-direction: row !important;
    }
    .flex-md-column {
        flex-direction: column !important;
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-md-wrap {
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-md-start {
        justify-content: flex-start !important;
    }
    .justify-content-md-end {
        justify-content: flex-end !important;
    }
    .justify-content-md-center {
        justify-content: center !important;
    }
    .justify-content-md-between {
        justify-content: space-between !important;
    }
    .justify-content-md-around {
        justify-content: space-around !important;
    }
    .align-items-md-start {
        align-items: flex-start !important;
    }
    .align-items-md-end {
        align-items: flex-end !important;
    }
    .align-items-md-center {
        align-items: center !important;
    }
    .align-items-md-baseline {
        align-items: baseline !important;
    }
    .align-items-md-stretch {
        align-items: stretch !important;
    }
    .align-content-md-start {
        align-content: flex-start !important;
    }
    .align-content-md-end {
        align-content: flex-end !important;
    }
    .align-content-md-center {
        align-content: center !important;
    }
    .align-content-md-between {
        align-content: space-between !important;
    }
    .align-content-md-around {
        align-content: space-around !important;
    }
    .align-content-md-stretch {
        align-content: stretch !important;
    }
    .align-self-md-auto {
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
    }
    .align-self-md-start {
        align-self: flex-start !important;
    }
    .align-self-md-end {
        align-self: flex-end !important;
    }
    .align-self-md-center {
        -ms-grid-row-align: center !important;
        align-self: center !important;
    }
    .align-self-md-baseline {
        align-self: baseline !important;
    }
    .align-self-md-stretch {
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
    }
    .order-md-first {
        order: -1 !important;
    }
    .order-md-0 {
        order: 0 !important;
    }
    .order-md-1 {
        order: 1 !important;
    }
    .order-md-2 {
        order: 2 !important;
    }
    .order-md-3 {
        order: 3 !important;
    }
    .order-md-4 {
        order: 4 !important;
    }
    .order-md-5 {
        order: 5 !important;
    }
    .order-md-last {
        order: 6 !important;
    }
    .m-md-0 {
        margin: 0 !important;
    }
    .m-md-1 {
        margin: 0.25rem !important;
    }
    .m-md-2 {
        margin: 0.5rem !important;
    }
    .m-md-3 {
        margin: 1rem !important;
    }
    .m-md-4 {
        margin: 1.5rem !important;
    }
    .m-md-5 {
        margin: 3rem !important;
    }
    .m-md-6 {
        margin: 4.5rem !important;
    }
    .m-md-auto {
        margin: auto !important;
    }
    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-md-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-md-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-md-6 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important;
    }
    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-md-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-md-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-md-6 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important;
    }
    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-md-0 {
        margin-top: 0 !important;
    }
    .mt-md-1 {
        margin-top: 0.25rem !important;
    }
    .mt-md-2 {
        margin-top: 0.5rem !important;
    }
    .mt-md-3 {
        margin-top: 1rem !important;
    }
    .mt-md-4 {
        margin-top: 1.5rem !important;
    }
    .mt-md-5 {
        margin-top: 3rem !important;
    }
    .mt-md-6 {
        margin-top: 4.5rem !important;
    }
    .mt-md-auto {
        margin-top: auto !important;
    }
    .mr-md-0 {
        margin-right: 0 !important;
    }
    .mr-md-1 {
        margin-right: 0.25rem !important;
    }
    .mr-md-2 {
        margin-right: 0.5rem !important;
    }
    .mr-md-3 {
        margin-right: 1rem !important;
    }
    .mr-md-4 {
        margin-right: 1.5rem !important;
    }
    .mr-md-5 {
        margin-right: 3rem !important;
    }
    .mr-md-6 {
        margin-right: 4.5rem !important;
    }
    .mr-md-auto {
        margin-right: auto !important;
    }
    .mb-md-0 {
        margin-bottom: 0 !important;
    }
    .mb-md-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-md-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-md-3 {
        margin-bottom: 1rem !important;
    }
    .mb-md-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-md-5 {
        margin-bottom: 3rem !important;
    }
    .mb-md-6 {
        margin-bottom: 4.5rem !important;
    }
    .mb-md-auto {
        margin-bottom: auto !important;
    }
    .ml-md-0 {
        margin-left: 0 !important;
    }
    .ml-md-1 {
        margin-left: 0.25rem !important;
    }
    .ml-md-2 {
        margin-left: 0.5rem !important;
    }
    .ml-md-3 {
        margin-left: 1rem !important;
    }
    .ml-md-4 {
        margin-left: 1.5rem !important;
    }
    .ml-md-5 {
        margin-left: 3rem !important;
    }
    .ml-md-6 {
        margin-left: 4.5rem !important;
    }
    .ml-md-auto {
        margin-left: auto !important;
    }
    .m-md-n1 {
        margin: -0.25rem !important;
    }
    .m-md-n2 {
        margin: -0.5rem !important;
    }
    .m-md-n3 {
        margin: -1rem !important;
    }
    .m-md-n4 {
        margin: -1.5rem !important;
    }
    .m-md-n5 {
        margin: -3rem !important;
    }
    .m-md-n6 {
        margin: -4.5rem !important;
    }
    .mx-md-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }
    .mx-md-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }
    .mx-md-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }
    .mx-md-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }
    .mx-md-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }
    .mx-md-n6 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important;
    }
    .my-md-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }
    .my-md-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }
    .my-md-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }
    .my-md-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important;
    }
    .my-md-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }
    .my-md-n6 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important;
    }
    .mt-md-n1 {
        margin-top: -0.25rem !important;
    }
    .mt-md-n2 {
        margin-top: -0.5rem !important;
    }
    .mt-md-n3 {
        margin-top: -1rem !important;
    }
    .mt-md-n4 {
        margin-top: -1.5rem !important;
    }
    .mt-md-n5 {
        margin-top: -3rem !important;
    }
    .mt-md-n6 {
        margin-top: -4.5rem !important;
    }
    .mr-md-n1 {
        margin-right: -0.25rem !important;
    }
    .mr-md-n2 {
        margin-right: -0.5rem !important;
    }
    .mr-md-n3 {
        margin-right: -1rem !important;
    }
    .mr-md-n4 {
        margin-right: -1.5rem !important;
    }
    .mr-md-n5 {
        margin-right: -3rem !important;
    }
    .mr-md-n6 {
        margin-right: -4.5rem !important;
    }
    .mb-md-n1 {
        margin-bottom: -0.25rem !important;
    }
    .mb-md-n2 {
        margin-bottom: -0.5rem !important;
    }
    .mb-md-n3 {
        margin-bottom: -1rem !important;
    }
    .mb-md-n4 {
        margin-bottom: -1.5rem !important;
    }
    .mb-md-n5 {
        margin-bottom: -3rem !important;
    }
    .mb-md-n6 {
        margin-bottom: -4.5rem !important;
    }
    .ml-md-n1 {
        margin-left: -0.25rem !important;
    }
    .ml-md-n2 {
        margin-left: -0.5rem !important;
    }
    .ml-md-n3 {
        margin-left: -1rem !important;
    }
    .ml-md-n4 {
        margin-left: -1.5rem !important;
    }
    .ml-md-n5 {
        margin-left: -3rem !important;
    }
    .ml-md-n6 {
        margin-left: -4.5rem !important;
    }
    .p-md-0 {
        padding: 0 !important;
    }
    .p-md-1 {
        padding: 0.25rem !important;
    }
    .p-md-2 {
        padding: 0.5rem !important;
    }
    .p-md-3 {
        padding: 1rem !important;
    }
    .p-md-4 {
        padding: 1.5rem !important;
    }
    .p-md-5 {
        padding: 3rem !important;
    }
    .p-md-6 {
        padding: 4.5rem !important;
    }
    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-md-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-md-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .px-md-6 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important;
    }
    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-md-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-md-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .py-md-6 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
    }
    .pt-md-0 {
        padding-top: 0 !important;
    }
    .pt-md-1 {
        padding-top: 0.25rem !important;
    }
    .pt-md-2 {
        padding-top: 0.5rem !important;
    }
    .pt-md-3 {
        padding-top: 1rem !important;
    }
    .pt-md-4 {
        padding-top: 1.5rem !important;
    }
    .pt-md-5 {
        padding-top: 3rem !important;
    }
    .pt-md-6 {
        padding-top: 4.5rem !important;
    }
    .pr-md-0 {
        padding-right: 0 !important;
    }
    .pr-md-1 {
        padding-right: 0.25rem !important;
    }
    .pr-md-2 {
        padding-right: 0.5rem !important;
    }
    .pr-md-3 {
        padding-right: 1rem !important;
    }
    .pr-md-4 {
        padding-right: 1.5rem !important;
    }
    .pr-md-5 {
        padding-right: 3rem !important;
    }
    .pr-md-6 {
        padding-right: 4.5rem !important;
    }
    .pb-md-0 {
        padding-bottom: 0 !important;
    }
    .pb-md-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-md-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-md-3 {
        padding-bottom: 1rem !important;
    }
    .pb-md-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-md-5 {
        padding-bottom: 3rem !important;
    }
    .pb-md-6 {
        padding-bottom: 4.5rem !important;
    }
    .pl-md-0 {
        padding-left: 0 !important;
    }
    .pl-md-1 {
        padding-left: 0.25rem !important;
    }
    .pl-md-2 {
        padding-left: 0.5rem !important;
    }
    .pl-md-3 {
        padding-left: 1rem !important;
    }
    .pl-md-4 {
        padding-left: 1.5rem !important;
    }
    .pl-md-5 {
        padding-left: 3rem !important;
    }
    .pl-md-6 {
        padding-left: 4.5rem !important;
    }
    .text-md-left {
        text-align: left !important;
    }
    .text-md-right {
        text-align: right !important;
    }
    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 64rem) {
    .float-lg-left {
        float: left !important;
    }
    .float-lg-right {
        float: right !important;
    }
    .float-lg-none {
        float: none !important;
    }
    .d-lg-none {
        display: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-table {
        display: table !important;
    }
    .d-lg-table-row {
        display: table-row !important;
    }
    .d-lg-table-cell {
        display: table-cell !important;
    }
    .d-lg-flex {
        display: flex !important;
    }
    .d-lg-inline-flex {
        display: inline-flex !important;
    }
    .flex-lg-fill {
        flex: 1 1 auto !important;
    }
    .flex-lg-row {
        flex-direction: row !important;
    }
    .flex-lg-column {
        flex-direction: column !important;
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-lg-start {
        justify-content: flex-start !important;
    }
    .justify-content-lg-end {
        justify-content: flex-end !important;
    }
    .justify-content-lg-center {
        justify-content: center !important;
    }
    .justify-content-lg-between {
        justify-content: space-between !important;
    }
    .justify-content-lg-around {
        justify-content: space-around !important;
    }
    .align-items-lg-start {
        align-items: flex-start !important;
    }
    .align-items-lg-end {
        align-items: flex-end !important;
    }
    .align-items-lg-center {
        align-items: center !important;
    }
    .align-items-lg-baseline {
        align-items: baseline !important;
    }
    .align-items-lg-stretch {
        align-items: stretch !important;
    }
    .align-content-lg-start {
        align-content: flex-start !important;
    }
    .align-content-lg-end {
        align-content: flex-end !important;
    }
    .align-content-lg-center {
        align-content: center !important;
    }
    .align-content-lg-between {
        align-content: space-between !important;
    }
    .align-content-lg-around {
        align-content: space-around !important;
    }
    .align-content-lg-stretch {
        align-content: stretch !important;
    }
    .align-self-lg-auto {
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
    }
    .align-self-lg-start {
        align-self: flex-start !important;
    }
    .align-self-lg-end {
        align-self: flex-end !important;
    }
    .align-self-lg-center {
        -ms-grid-row-align: center !important;
        align-self: center !important;
    }
    .align-self-lg-baseline {
        align-self: baseline !important;
    }
    .align-self-lg-stretch {
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
    }
    .order-lg-first {
        order: -1 !important;
    }
    .order-lg-0 {
        order: 0 !important;
    }
    .order-lg-1 {
        order: 1 !important;
    }
    .order-lg-2 {
        order: 2 !important;
    }
    .order-lg-3 {
        order: 3 !important;
    }
    .order-lg-4 {
        order: 4 !important;
    }
    .order-lg-5 {
        order: 5 !important;
    }
    .order-lg-last {
        order: 6 !important;
    }
    .m-lg-0 {
        margin: 0 !important;
    }
    .m-lg-1 {
        margin: 0.25rem !important;
    }
    .m-lg-2 {
        margin: 0.5rem !important;
    }
    .m-lg-3 {
        margin: 1rem !important;
    }
    .m-lg-4 {
        margin: 1.5rem !important;
    }
    .m-lg-5 {
        margin: 3rem !important;
    }
    .m-lg-6 {
        margin: 4.5rem !important;
    }
    .m-lg-auto {
        margin: auto !important;
    }
    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-lg-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-lg-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-lg-6 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important;
    }
    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-lg-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-lg-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-lg-6 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important;
    }
    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-lg-0 {
        margin-top: 0 !important;
    }
    .mt-lg-1 {
        margin-top: 0.25rem !important;
    }
    .mt-lg-2 {
        margin-top: 0.5rem !important;
    }
    .mt-lg-3 {
        margin-top: 1rem !important;
    }
    .mt-lg-4 {
        margin-top: 1.5rem !important;
    }
    .mt-lg-5 {
        margin-top: 3rem !important;
    }
    .mt-lg-6 {
        margin-top: 4.5rem !important;
    }
    .mt-lg-auto {
        margin-top: auto !important;
    }
    .mr-lg-0 {
        margin-right: 0 !important;
    }
    .mr-lg-1 {
        margin-right: 0.25rem !important;
    }
    .mr-lg-2 {
        margin-right: 0.5rem !important;
    }
    .mr-lg-3 {
        margin-right: 1rem !important;
    }
    .mr-lg-4 {
        margin-right: 1.5rem !important;
    }
    .mr-lg-5 {
        margin-right: 3rem !important;
    }
    .mr-lg-6 {
        margin-right: 4.5rem !important;
    }
    .mr-lg-auto {
        margin-right: auto !important;
    }
    .mb-lg-0 {
        margin-bottom: 0 !important;
    }
    .mb-lg-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-lg-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-lg-3 {
        margin-bottom: 1rem !important;
    }
    .mb-lg-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-lg-5 {
        margin-bottom: 3rem !important;
    }
    .mb-lg-6 {
        margin-bottom: 4.5rem !important;
    }
    .mb-lg-auto {
        margin-bottom: auto !important;
    }
    .ml-lg-0 {
        margin-left: 0 !important;
    }
    .ml-lg-1 {
        margin-left: 0.25rem !important;
    }
    .ml-lg-2 {
        margin-left: 0.5rem !important;
    }
    .ml-lg-3 {
        margin-left: 1rem !important;
    }
    .ml-lg-4 {
        margin-left: 1.5rem !important;
    }
    .ml-lg-5 {
        margin-left: 3rem !important;
    }
    .ml-lg-6 {
        margin-left: 4.5rem !important;
    }
    .ml-lg-auto {
        margin-left: auto !important;
    }
    .m-lg-n1 {
        margin: -0.25rem !important;
    }
    .m-lg-n2 {
        margin: -0.5rem !important;
    }
    .m-lg-n3 {
        margin: -1rem !important;
    }
    .m-lg-n4 {
        margin: -1.5rem !important;
    }
    .m-lg-n5 {
        margin: -3rem !important;
    }
    .m-lg-n6 {
        margin: -4.5rem !important;
    }
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }
    .mx-lg-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }
    .mx-lg-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }
    .mx-lg-n6 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important;
    }
    .my-lg-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }
    .my-lg-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }
    .my-lg-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }
    .my-lg-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important;
    }
    .my-lg-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }
    .my-lg-n6 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important;
    }
    .mt-lg-n1 {
        margin-top: -0.25rem !important;
    }
    .mt-lg-n2 {
        margin-top: -0.5rem !important;
    }
    .mt-lg-n3 {
        margin-top: -1rem !important;
    }
    .mt-lg-n4 {
        margin-top: -1.5rem !important;
    }
    .mt-lg-n5 {
        margin-top: -3rem !important;
    }
    .mt-lg-n6 {
        margin-top: -4.5rem !important;
    }
    .mr-lg-n1 {
        margin-right: -0.25rem !important;
    }
    .mr-lg-n2 {
        margin-right: -0.5rem !important;
    }
    .mr-lg-n3 {
        margin-right: -1rem !important;
    }
    .mr-lg-n4 {
        margin-right: -1.5rem !important;
    }
    .mr-lg-n5 {
        margin-right: -3rem !important;
    }
    .mr-lg-n6 {
        margin-right: -4.5rem !important;
    }
    .mb-lg-n1 {
        margin-bottom: -0.25rem !important;
    }
    .mb-lg-n2 {
        margin-bottom: -0.5rem !important;
    }
    .mb-lg-n3 {
        margin-bottom: -1rem !important;
    }
    .mb-lg-n4 {
        margin-bottom: -1.5rem !important;
    }
    .mb-lg-n5 {
        margin-bottom: -3rem !important;
    }
    .mb-lg-n6 {
        margin-bottom: -4.5rem !important;
    }
    .ml-lg-n1 {
        margin-left: -0.25rem !important;
    }
    .ml-lg-n2 {
        margin-left: -0.5rem !important;
    }
    .ml-lg-n3 {
        margin-left: -1rem !important;
    }
    .ml-lg-n4 {
        margin-left: -1.5rem !important;
    }
    .ml-lg-n5 {
        margin-left: -3rem !important;
    }
    .ml-lg-n6 {
        margin-left: -4.5rem !important;
    }
    .p-lg-0 {
        padding: 0 !important;
    }
    .p-lg-1 {
        padding: 0.25rem !important;
    }
    .p-lg-2 {
        padding: 0.5rem !important;
    }
    .p-lg-3 {
        padding: 1rem !important;
    }
    .p-lg-4 {
        padding: 1.5rem !important;
    }
    .p-lg-5 {
        padding: 3rem !important;
    }
    .p-lg-6 {
        padding: 4.5rem !important;
    }
    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-lg-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-lg-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .px-lg-6 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important;
    }
    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-lg-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-lg-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .py-lg-6 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
    }
    .pt-lg-0 {
        padding-top: 0 !important;
    }
    .pt-lg-1 {
        padding-top: 0.25rem !important;
    }
    .pt-lg-2 {
        padding-top: 0.5rem !important;
    }
    .pt-lg-3 {
        padding-top: 1rem !important;
    }
    .pt-lg-4 {
        padding-top: 1.5rem !important;
    }
    .pt-lg-5 {
        padding-top: 3rem !important;
    }
    .pt-lg-6 {
        padding-top: 4.5rem !important;
    }
    .pr-lg-0 {
        padding-right: 0 !important;
    }
    .pr-lg-1 {
        padding-right: 0.25rem !important;
    }
    .pr-lg-2 {
        padding-right: 0.5rem !important;
    }
    .pr-lg-3 {
        padding-right: 1rem !important;
    }
    .pr-lg-4 {
        padding-right: 1.5rem !important;
    }
    .pr-lg-5 {
        padding-right: 3rem !important;
    }
    .pr-lg-6 {
        padding-right: 4.5rem !important;
    }
    .pb-lg-0 {
        padding-bottom: 0 !important;
    }
    .pb-lg-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-lg-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-lg-3 {
        padding-bottom: 1rem !important;
    }
    .pb-lg-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-lg-5 {
        padding-bottom: 3rem !important;
    }
    .pb-lg-6 {
        padding-bottom: 4.5rem !important;
    }
    .pl-lg-0 {
        padding-left: 0 !important;
    }
    .pl-lg-1 {
        padding-left: 0.25rem !important;
    }
    .pl-lg-2 {
        padding-left: 0.5rem !important;
    }
    .pl-lg-3 {
        padding-left: 1rem !important;
    }
    .pl-lg-4 {
        padding-left: 1.5rem !important;
    }
    .pl-lg-5 {
        padding-left: 3rem !important;
    }
    .pl-lg-6 {
        padding-left: 4.5rem !important;
    }
    .text-lg-left {
        text-align: left !important;
    }
    .text-lg-right {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 75.25rem) {
    .float-xl-left {
        float: left !important;
    }
    .float-xl-right {
        float: right !important;
    }
    .float-xl-none {
        float: none !important;
    }
    .d-xl-none {
        display: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-table {
        display: table !important;
    }
    .d-xl-table-row {
        display: table-row !important;
    }
    .d-xl-table-cell {
        display: table-cell !important;
    }
    .d-xl-flex {
        display: flex !important;
    }
    .d-xl-inline-flex {
        display: inline-flex !important;
    }
    .flex-xl-fill {
        flex: 1 1 auto !important;
    }
    .flex-xl-row {
        flex-direction: row !important;
    }
    .flex-xl-column {
        flex-direction: column !important;
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-xl-start {
        justify-content: flex-start !important;
    }
    .justify-content-xl-end {
        justify-content: flex-end !important;
    }
    .justify-content-xl-center {
        justify-content: center !important;
    }
    .justify-content-xl-between {
        justify-content: space-between !important;
    }
    .justify-content-xl-around {
        justify-content: space-around !important;
    }
    .align-items-xl-start {
        align-items: flex-start !important;
    }
    .align-items-xl-end {
        align-items: flex-end !important;
    }
    .align-items-xl-center {
        align-items: center !important;
    }
    .align-items-xl-baseline {
        align-items: baseline !important;
    }
    .align-items-xl-stretch {
        align-items: stretch !important;
    }
    .align-content-xl-start {
        align-content: flex-start !important;
    }
    .align-content-xl-end {
        align-content: flex-end !important;
    }
    .align-content-xl-center {
        align-content: center !important;
    }
    .align-content-xl-between {
        align-content: space-between !important;
    }
    .align-content-xl-around {
        align-content: space-around !important;
    }
    .align-content-xl-stretch {
        align-content: stretch !important;
    }
    .align-self-xl-auto {
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
    }
    .align-self-xl-start {
        align-self: flex-start !important;
    }
    .align-self-xl-end {
        align-self: flex-end !important;
    }
    .align-self-xl-center {
        -ms-grid-row-align: center !important;
        align-self: center !important;
    }
    .align-self-xl-baseline {
        align-self: baseline !important;
    }
    .align-self-xl-stretch {
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
    }
    .order-xl-first {
        order: -1 !important;
    }
    .order-xl-0 {
        order: 0 !important;
    }
    .order-xl-1 {
        order: 1 !important;
    }
    .order-xl-2 {
        order: 2 !important;
    }
    .order-xl-3 {
        order: 3 !important;
    }
    .order-xl-4 {
        order: 4 !important;
    }
    .order-xl-5 {
        order: 5 !important;
    }
    .order-xl-last {
        order: 6 !important;
    }
    .m-xl-0 {
        margin: 0 !important;
    }
    .m-xl-1 {
        margin: 0.25rem !important;
    }
    .m-xl-2 {
        margin: 0.5rem !important;
    }
    .m-xl-3 {
        margin: 1rem !important;
    }
    .m-xl-4 {
        margin: 1.5rem !important;
    }
    .m-xl-5 {
        margin: 3rem !important;
    }
    .m-xl-6 {
        margin: 4.5rem !important;
    }
    .m-xl-auto {
        margin: auto !important;
    }
    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-xl-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-xl-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-xl-6 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important;
    }
    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-xl-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-xl-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-xl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-xl-6 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important;
    }
    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-xl-0 {
        margin-top: 0 !important;
    }
    .mt-xl-1 {
        margin-top: 0.25rem !important;
    }
    .mt-xl-2 {
        margin-top: 0.5rem !important;
    }
    .mt-xl-3 {
        margin-top: 1rem !important;
    }
    .mt-xl-4 {
        margin-top: 1.5rem !important;
    }
    .mt-xl-5 {
        margin-top: 3rem !important;
    }
    .mt-xl-6 {
        margin-top: 4.5rem !important;
    }
    .mt-xl-auto {
        margin-top: auto !important;
    }
    .mr-xl-0 {
        margin-right: 0 !important;
    }
    .mr-xl-1 {
        margin-right: 0.25rem !important;
    }
    .mr-xl-2 {
        margin-right: 0.5rem !important;
    }
    .mr-xl-3 {
        margin-right: 1rem !important;
    }
    .mr-xl-4 {
        margin-right: 1.5rem !important;
    }
    .mr-xl-5 {
        margin-right: 3rem !important;
    }
    .mr-xl-6 {
        margin-right: 4.5rem !important;
    }
    .mr-xl-auto {
        margin-right: auto !important;
    }
    .mb-xl-0 {
        margin-bottom: 0 !important;
    }
    .mb-xl-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-xl-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-xl-3 {
        margin-bottom: 1rem !important;
    }
    .mb-xl-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-xl-5 {
        margin-bottom: 3rem !important;
    }
    .mb-xl-6 {
        margin-bottom: 4.5rem !important;
    }
    .mb-xl-auto {
        margin-bottom: auto !important;
    }
    .ml-xl-0 {
        margin-left: 0 !important;
    }
    .ml-xl-1 {
        margin-left: 0.25rem !important;
    }
    .ml-xl-2 {
        margin-left: 0.5rem !important;
    }
    .ml-xl-3 {
        margin-left: 1rem !important;
    }
    .ml-xl-4 {
        margin-left: 1.5rem !important;
    }
    .ml-xl-5 {
        margin-left: 3rem !important;
    }
    .ml-xl-6 {
        margin-left: 4.5rem !important;
    }
    .ml-xl-auto {
        margin-left: auto !important;
    }
    .m-xl-n1 {
        margin: -0.25rem !important;
    }
    .m-xl-n2 {
        margin: -0.5rem !important;
    }
    .m-xl-n3 {
        margin: -1rem !important;
    }
    .m-xl-n4 {
        margin: -1.5rem !important;
    }
    .m-xl-n5 {
        margin: -3rem !important;
    }
    .m-xl-n6 {
        margin: -4.5rem !important;
    }
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }
    .mx-xl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }
    .mx-xl-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }
    .mx-xl-n6 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important;
    }
    .my-xl-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }
    .my-xl-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }
    .my-xl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }
    .my-xl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important;
    }
    .my-xl-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }
    .my-xl-n6 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important;
    }
    .mt-xl-n1 {
        margin-top: -0.25rem !important;
    }
    .mt-xl-n2 {
        margin-top: -0.5rem !important;
    }
    .mt-xl-n3 {
        margin-top: -1rem !important;
    }
    .mt-xl-n4 {
        margin-top: -1.5rem !important;
    }
    .mt-xl-n5 {
        margin-top: -3rem !important;
    }
    .mt-xl-n6 {
        margin-top: -4.5rem !important;
    }
    .mr-xl-n1 {
        margin-right: -0.25rem !important;
    }
    .mr-xl-n2 {
        margin-right: -0.5rem !important;
    }
    .mr-xl-n3 {
        margin-right: -1rem !important;
    }
    .mr-xl-n4 {
        margin-right: -1.5rem !important;
    }
    .mr-xl-n5 {
        margin-right: -3rem !important;
    }
    .mr-xl-n6 {
        margin-right: -4.5rem !important;
    }
    .mb-xl-n1 {
        margin-bottom: -0.25rem !important;
    }
    .mb-xl-n2 {
        margin-bottom: -0.5rem !important;
    }
    .mb-xl-n3 {
        margin-bottom: -1rem !important;
    }
    .mb-xl-n4 {
        margin-bottom: -1.5rem !important;
    }
    .mb-xl-n5 {
        margin-bottom: -3rem !important;
    }
    .mb-xl-n6 {
        margin-bottom: -4.5rem !important;
    }
    .ml-xl-n1 {
        margin-left: -0.25rem !important;
    }
    .ml-xl-n2 {
        margin-left: -0.5rem !important;
    }
    .ml-xl-n3 {
        margin-left: -1rem !important;
    }
    .ml-xl-n4 {
        margin-left: -1.5rem !important;
    }
    .ml-xl-n5 {
        margin-left: -3rem !important;
    }
    .ml-xl-n6 {
        margin-left: -4.5rem !important;
    }
    .p-xl-0 {
        padding: 0 !important;
    }
    .p-xl-1 {
        padding: 0.25rem !important;
    }
    .p-xl-2 {
        padding: 0.5rem !important;
    }
    .p-xl-3 {
        padding: 1rem !important;
    }
    .p-xl-4 {
        padding: 1.5rem !important;
    }
    .p-xl-5 {
        padding: 3rem !important;
    }
    .p-xl-6 {
        padding: 4.5rem !important;
    }
    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-xl-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-xl-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .px-xl-6 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important;
    }
    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-xl-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-xl-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-xl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .py-xl-6 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
    }
    .pt-xl-0 {
        padding-top: 0 !important;
    }
    .pt-xl-1 {
        padding-top: 0.25rem !important;
    }
    .pt-xl-2 {
        padding-top: 0.5rem !important;
    }
    .pt-xl-3 {
        padding-top: 1rem !important;
    }
    .pt-xl-4 {
        padding-top: 1.5rem !important;
    }
    .pt-xl-5 {
        padding-top: 3rem !important;
    }
    .pt-xl-6 {
        padding-top: 4.5rem !important;
    }
    .pr-xl-0 {
        padding-right: 0 !important;
    }
    .pr-xl-1 {
        padding-right: 0.25rem !important;
    }
    .pr-xl-2 {
        padding-right: 0.5rem !important;
    }
    .pr-xl-3 {
        padding-right: 1rem !important;
    }
    .pr-xl-4 {
        padding-right: 1.5rem !important;
    }
    .pr-xl-5 {
        padding-right: 3rem !important;
    }
    .pr-xl-6 {
        padding-right: 4.5rem !important;
    }
    .pb-xl-0 {
        padding-bottom: 0 !important;
    }
    .pb-xl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-xl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-xl-3 {
        padding-bottom: 1rem !important;
    }
    .pb-xl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-xl-5 {
        padding-bottom: 3rem !important;
    }
    .pb-xl-6 {
        padding-bottom: 4.5rem !important;
    }
    .pl-xl-0 {
        padding-left: 0 !important;
    }
    .pl-xl-1 {
        padding-left: 0.25rem !important;
    }
    .pl-xl-2 {
        padding-left: 0.5rem !important;
    }
    .pl-xl-3 {
        padding-left: 1rem !important;
    }
    .pl-xl-4 {
        padding-left: 1.5rem !important;
    }
    .pl-xl-5 {
        padding-left: 3rem !important;
    }
    .pl-xl-6 {
        padding-left: 4.5rem !important;
    }
    .text-xl-left {
        text-align: left !important;
    }
    .text-xl-right {
        text-align: right !important;
    }
    .text-xl-center {
        text-align: center !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }
    .d-print-inline {
        display: inline !important;
    }
    .d-print-inline-block {
        display: inline-block !important;
    }
    .d-print-block {
        display: block !important;
    }
    .d-print-table {
        display: table !important;
    }
    .d-print-table-row {
        display: table-row !important;
    }
    .d-print-table-cell {
        display: table-cell !important;
    }
    .d-print-flex {
        display: flex !important;
    }
    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

html {
    font-size: 14px !important;
    @media (max-height: 660px) {
        font-size: 14px !important;
    }
    @media (max-height: 800px) and (min-height: 660px) {
        font-size: 15px !important;
    }
    @media (max-height: 970px) and (min-height: 800px) {
        font-size: 17px !important;
    }
    @media (min-height: 970px) {
        font-size: 17px !important;
    }
    @media (min-height: 970px) and (min-width: 2560px) {
        font-size: 19px !important;
    }
    scroll-behavior: smooth;
}

body {
    overflow-y: scroll;
    letter-spacing: -0.01em;
    touch-action: manipulation;
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga" 0;
    position: relative;
}

body .content *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    -webkit-transition: .3s background;
    transition: .3s background;
}

body .content *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #dee1e7;
}

body .content *::-webkit-scrollbar-corner {
    background: transparent;
}

body .content *:hover::-webkit-scrollbar-thumb {
    background: #cbcfd6;
    background: #cbcfd6;
}

@media print {
    body {
        background: transparent;
    }
}


/**
Navbar
 */

.navbar {
    min-height: 3.5rem;
    align-items: stretch;
    background: transparent;
    color: rgba(53, 64, 82, 0.72);
}

.navbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    -webkit-transition: .3s background;
    transition: .3s background;
}

.navbar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #dee1e7;
}

.navbar::-webkit-scrollbar-corner {
    background: transparent;
}

.navbar:hover::-webkit-scrollbar-thumb {
    background: #cbcfd6;
    background: #cbcfd6;
}

.navbar .text-muted {
    color: rgba(53, 64, 82, 0.5184) !important;
}

.navbar .navbar-brand {
    color: #354052;
}

.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
    color: #354052;
    opacity: .8;
}

.navbar .navbar-nav .nav-link {
    color: rgba(53, 64, 82, 0.72);
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:focus {
    color: #354052;
}

.navbar .navbar-nav .nav-link.disabled {
    color: rgba(53, 64, 82, 0.3);
}

.navbar .navbar-nav .show>.nav-link,
.navbar .navbar-nav .active>.nav-link,
.navbar .navbar-nav .nav-link.show,
.navbar .navbar-nav .nav-link.active {
    color: #354052;
}

.navbar .navbar-toggler {
    color: #354052;
    border-color: transparent;
}

.navbar .navbar-text {
    color: rgba(53, 64, 82, 0.72);
}

.navbar .navbar-text a,
.navbar .navbar-text a:hover,
.navbar .navbar-text a:focus {
    color: rgba(53, 64, 82, 0.72);
}

@media not print {
    .theme-dark .navbar {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        background: #354052;
        color: rgba(255, 255, 255, 0.72);
    }
    .theme-dark .navbar::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        -webkit-transition: .3s background;
        transition: .3s background;
    }
    .theme-dark .navbar::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #dee1e7;
    }
    .theme-dark .navbar::-webkit-scrollbar-corner {
        background: transparent;
    }
    .theme-dark .navbar:hover::-webkit-scrollbar-thumb {
        background: #cbcfd6;
        background: #cbcfd6;
    }
    .theme-dark .navbar .text-muted {
        color: rgba(255, 255, 255, 0.5184) !important;
    }
    .theme-dark .navbar .navbar-brand {
        color: #ffffff;
    }
    .theme-dark .navbar .navbar-brand:hover,
    .theme-dark .navbar .navbar-brand:focus {
        color: #ffffff;
        opacity: .8;
    }
    .theme-dark .navbar .navbar-nav .nav-link {
        color: rgba(255, 255, 255, 0.72);
    }
    .theme-dark .navbar .navbar-nav .nav-link:hover,
    .theme-dark .navbar .navbar-nav .nav-link:focus {
        color: #ffffff;
    }
    .theme-dark .navbar .navbar-nav .nav-link.disabled {
        color: rgba(255, 255, 255, 0.3);
    }
    .theme-dark .navbar .navbar-nav .show>.nav-link,
    .theme-dark .navbar .navbar-nav .active>.nav-link,
    .theme-dark .navbar .navbar-nav .nav-link.show,
    .theme-dark .navbar .navbar-nav .nav-link.active {
        color: #ffffff;
    }
    .theme-dark .navbar .navbar-toggler {
        color: #ffffff;
        border-color: transparent;
    }
    .theme-dark .navbar .navbar-text {
        color: rgba(255, 255, 255, 0.72);
    }
    .theme-dark .navbar .navbar-text a,
    .theme-dark .navbar .navbar-text a:hover,
    .theme-dark .navbar .navbar-text a:focus {
        color: rgba(255, 255, 255, 0.72);
    }
    .theme-dark .navbar::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        -webkit-transition: .3s background;
        transition: .3s background;
    }
    .theme-dark .navbar::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: transparent;
    }
    .theme-dark .navbar::-webkit-scrollbar-corner {
        background: transparent;
    }
    .theme-dark .navbar:hover::-webkit-scrollbar-thumb {
        background: #cbcfd6;
        background: #5d6675;
    }
    .theme-dark .navbar .input-icon-addon {
        color: rgba(245, 247, 251, 0.64);
    }
    .theme-dark .navbar .form-control {
        border-color: transparent;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .theme-dark .navbar .form-control::-webkit-input-placeholder {
        color: rgba(245, 247, 251, 0.64);
    }
    .theme-dark .navbar .form-control::-moz-placeholder {
        color: rgba(245, 247, 251, 0.64);
    }
    .theme-dark .navbar .form-control:-ms-input-placeholder {
        color: rgba(245, 247, 251, 0.64);
    }
    .theme-dark .navbar .form-control::-ms-input-placeholder {
        color: rgba(245, 247, 251, 0.64);
    }
    .theme-dark .navbar .form-control::placeholder {
        color: rgba(245, 247, 251, 0.64);
    }
}

@media not print and (prefers-color-scheme: dark) {
    .theme-dark-auto .navbar {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        background: #354052;
        color: rgba(255, 255, 255, 0.72);
    }
    .theme-dark-auto .navbar::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        -webkit-transition: .3s background;
        transition: .3s background;
    }
    .theme-dark-auto .navbar::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #dee1e7;
    }
    .theme-dark-auto .navbar::-webkit-scrollbar-corner {
        background: transparent;
    }
    .theme-dark-auto .navbar:hover::-webkit-scrollbar-thumb {
        background: #cbcfd6;
        background: #cbcfd6;
    }
    .theme-dark-auto .navbar .text-muted {
        color: rgba(255, 255, 255, 0.5184) !important;
    }
    .theme-dark-auto .navbar .navbar-brand {
        color: #ffffff;
    }
    .theme-dark-auto .navbar .navbar-brand:hover,
    .theme-dark-auto .navbar .navbar-brand:focus {
        color: #ffffff;
        opacity: .8;
    }
    .theme-dark-auto .navbar .navbar-nav .nav-link {
        color: rgba(255, 255, 255, 0.72);
    }
    .theme-dark-auto .navbar .navbar-nav .nav-link:hover,
    .theme-dark-auto .navbar .navbar-nav .nav-link:focus {
        color: #ffffff;
    }
    .theme-dark-auto .navbar .navbar-nav .nav-link.disabled {
        color: rgba(255, 255, 255, 0.3);
    }
    .theme-dark-auto .navbar .navbar-nav .show>.nav-link,
    .theme-dark-auto .navbar .navbar-nav .active>.nav-link,
    .theme-dark-auto .navbar .navbar-nav .nav-link.show,
    .theme-dark-auto .navbar .navbar-nav .nav-link.active {
        color: #ffffff;
    }
    .theme-dark-auto .navbar .navbar-toggler {
        color: #ffffff;
        border-color: transparent;
    }
    .theme-dark-auto .navbar .navbar-text {
        color: rgba(255, 255, 255, 0.72);
    }
    .theme-dark-auto .navbar .navbar-text a,
    .theme-dark-auto .navbar .navbar-text a:hover,
    .theme-dark-auto .navbar .navbar-text a:focus {
        color: rgba(255, 255, 255, 0.72);
    }
    .theme-dark-auto .navbar::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        -webkit-transition: .3s background;
        transition: .3s background;
    }
    .theme-dark-auto .navbar::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: transparent;
    }
    .theme-dark-auto .navbar::-webkit-scrollbar-corner {
        background: transparent;
    }
    .theme-dark-auto .navbar:hover::-webkit-scrollbar-thumb {
        background: #cbcfd6;
        background: #5d6675;
    }
    .theme-dark-auto .navbar .input-icon-addon {
        color: rgba(245, 247, 251, 0.64);
    }
    .theme-dark-auto .navbar .form-control {
        border-color: transparent;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .theme-dark-auto .navbar .form-control::-webkit-input-placeholder {
        color: rgba(245, 247, 251, 0.64);
    }
    .theme-dark-auto .navbar .form-control::-moz-placeholder {
        color: rgba(245, 247, 251, 0.64);
    }
    .theme-dark-auto .navbar .form-control:-ms-input-placeholder {
        color: rgba(245, 247, 251, 0.64);
    }
    .theme-dark-auto .navbar .form-control::-ms-input-placeholder {
        color: rgba(245, 247, 251, 0.64);
    }
    .theme-dark-auto .navbar .form-control::placeholder {
        color: rgba(245, 247, 251, 0.64);
    }
}

.navbar .dropdown-menu {
    position: absolute;
}

.navbar .navbar-nav {
    min-height: 3rem;
}

.navbar .navbar-nav .nav-link {
    position: relative;
    min-width: 2rem;
    min-height: 2rem;
    justify-content: center;
}

.navbar .navbar-nav .nav-link .badge {
    position: absolute;
    top: .375rem;
    right: .375rem;
    transform: translate(50%, -50%);
}

@media (max-width: 32.98rem) {
    .navbar-expand-sm .navbar-collapse {
        flex-direction: column;
    }
    .navbar-expand-sm .navbar-collapse [class*="container"] {
        flex-direction: column;
        align-items: stretch;
    }
    .navbar-expand-sm .navbar-collapse .navbar-nav {
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }
    .navbar-expand-sm .navbar-collapse .navbar-nav .nav-link {
        padding: 0.5rem 0.75rem;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-collapse .dropdown-menu {
        padding: 0;
        background: transparent;
        position: static;
        color: inherit;
        box-shadow: none;
        border: none;
        -moz-columns: 1;
        columns: 1;
        min-width: 0;
    }
    .navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item {
        min-width: 0;
        display: flex;
        width: auto;
        padding-left: 2.25rem;
    }
    .navbar-expand-sm .navbar-collapse .dropdown-toggle:after {
        margin-left: auto;
    }
    .navbar-expand-sm .navbar-collapse .nav-item.active:after {
        border-bottom-width: 0;
        border-left-width: 2px;
        right: auto;
        top: 0;
        bottom: 0;
    }
}

@media (min-width: 33rem) {
    .navbar-expand-sm .navbar-collapse {
        width: auto;
        flex: 1 1 auto;
    }
    .navbar-expand-sm.navbar-dark .nav-item.active .nav-link,
    .navbar-expand-sm .navbar-dark .nav-item.active .nav-link {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .navbar-expand-sm.navbar-light .nav-item.active,
    .navbar-expand-sm .navbar-light .nav-item.active {
        position: relative;
    }
    .navbar-expand-sm.navbar-light .nav-item.active:after,
    .navbar-expand-sm .navbar-light .nav-item.active:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: calc(-0.25rem - 1px);
        border: 0 solid #206bc4;
        border-bottom-width: 2px;
    }
}

@media (max-width: 47.98rem) {
    .navbar-expand-md .navbar-collapse {
        flex-direction: column;
    }
    .navbar-expand-md .navbar-collapse [class*="container"] {
        flex-direction: column;
        align-items: stretch;
    }
    .navbar-expand-md .navbar-collapse .navbar-nav {
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }
    .navbar-expand-md .navbar-collapse .navbar-nav .nav-link {
        padding: 0.5rem 0.75rem;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-collapse .dropdown-menu {
        padding: 0;
        background: transparent;
        position: static;
        color: inherit;
        box-shadow: none;
        border: none;
        -moz-columns: 1;
        columns: 1;
        min-width: 0;
    }
    .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item {
        min-width: 0;
        display: flex;
        width: auto;
        padding-left: 2.25rem;
    }
    .navbar-expand-md .navbar-collapse .dropdown-toggle:after {
        margin-left: auto;
    }
    .navbar-expand-md .navbar-collapse .nav-item.active:after {
        border-bottom-width: 0;
        border-left-width: 2px;
        right: auto;
        top: 0;
        bottom: 0;
    }
}

@media (min-width: 48rem) {
    .navbar-expand-md .navbar-collapse {
        width: auto;
        flex: 1 1 auto;
    }
    .navbar-expand-md.navbar-dark .nav-item.active .nav-link,
    .navbar-expand-md .navbar-dark .nav-item.active .nav-link {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .navbar-expand-md.navbar-light .nav-item.active,
    .navbar-expand-md .navbar-light .nav-item.active {
        position: relative;
    }
    .navbar-expand-md.navbar-light .nav-item.active:after,
    .navbar-expand-md .navbar-light .nav-item.active:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: calc(-0.25rem - 1px);
        border: 0 solid #206bc4;
        border-bottom-width: 2px;
    }
}

@media (max-width: 63.98rem) {
    .navbar-expand-lg .navbar-collapse {
        flex-direction: column;
    }
    .navbar-expand-lg .navbar-collapse [class*="container"] {
        flex-direction: column;
        align-items: stretch;
    }
    .navbar-expand-lg .navbar-collapse .navbar-nav {
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }
    .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
        padding: 0.5rem 0.75rem;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-menu {
        padding: 0;
        background: transparent;
        position: static;
        color: inherit;
        box-shadow: none;
        border: none;
        -moz-columns: 1;
        columns: 1;
        min-width: 0;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item {
        min-width: 0;
        display: flex;
        width: auto;
        padding-left: 2.25rem;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-toggle:after {
        margin-left: auto;
    }
    .navbar-expand-lg .navbar-collapse .nav-item.active:after {
        border-bottom-width: 0;
        border-left-width: 2px;
        right: auto;
        top: 0;
        bottom: 0;
    }
}

@media (min-width: 64rem) {
    .navbar-expand-lg .navbar-collapse {
        width: auto;
        flex: 1 1 auto;
    }
    .navbar-expand-lg.navbar-dark .nav-item.active .nav-link,
    .navbar-expand-lg .navbar-dark .nav-item.active .nav-link {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .navbar-expand-lg.navbar-light .nav-item.active,
    .navbar-expand-lg .navbar-light .nav-item.active {
        position: relative;
    }
    .navbar-expand-lg.navbar-light .nav-item.active:after,
    .navbar-expand-lg .navbar-light .nav-item.active:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: calc(-0.25rem - 1px);
        border: 0 solid #206bc4;
        border-bottom-width: 2px;
    }
}

@media (max-width: 75.23rem) {
    .navbar-expand-xl .navbar-collapse {
        flex-direction: column;
    }
    .navbar-expand-xl .navbar-collapse [class*="container"] {
        flex-direction: column;
        align-items: stretch;
    }
    .navbar-expand-xl .navbar-collapse .navbar-nav {
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }
    .navbar-expand-xl .navbar-collapse .navbar-nav .nav-link {
        padding: 0.5rem 0.75rem;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-collapse .dropdown-menu {
        padding: 0;
        background: transparent;
        position: static;
        color: inherit;
        box-shadow: none;
        border: none;
        -moz-columns: 1;
        columns: 1;
        min-width: 0;
    }
    .navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item {
        min-width: 0;
        display: flex;
        width: auto;
        padding-left: 2.25rem;
    }
    .navbar-expand-xl .navbar-collapse .dropdown-toggle:after {
        margin-left: auto;
    }
    .navbar-expand-xl .navbar-collapse .nav-item.active:after {
        border-bottom-width: 0;
        border-left-width: 2px;
        right: auto;
        top: 0;
        bottom: 0;
    }
}

@media (min-width: 75.25rem) {
    .navbar-expand-xl .navbar-collapse {
        width: auto;
        flex: 1 1 auto;
    }
    .navbar-expand-xl.navbar-dark .nav-item.active .nav-link,
    .navbar-expand-xl .navbar-dark .nav-item.active .nav-link {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .navbar-expand-xl.navbar-light .nav-item.active,
    .navbar-expand-xl .navbar-light .nav-item.active {
        position: relative;
    }
    .navbar-expand-xl.navbar-light .nav-item.active:after,
    .navbar-expand-xl .navbar-light .nav-item.active:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: calc(-0.25rem - 1px);
        border: 0 solid #206bc4;
        border-bottom-width: 2px;
    }
}

.navbar-expand .navbar-collapse {
    flex-direction: column;
}

.navbar-expand .navbar-collapse [class*="container"] {
    flex-direction: column;
    align-items: stretch;
}

.navbar-expand .navbar-collapse .navbar-nav {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.navbar-expand .navbar-collapse .navbar-nav .nav-link {
    padding: 0.5rem 0.75rem;
    justify-content: flex-start;
}

.navbar-expand .navbar-collapse .dropdown-menu {
    padding: 0;
    background: transparent;
    position: static;
    color: inherit;
    box-shadow: none;
    border: none;
    -moz-columns: 1;
    columns: 1;
    min-width: 0;
}

.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item {
    min-width: 0;
    display: flex;
    width: auto;
    padding-left: 2.25rem;
}

.navbar-expand .navbar-collapse .dropdown-toggle:after {
    margin-left: auto;
}

.navbar-expand .navbar-collapse .nav-item.active:after {
    border-bottom-width: 0;
    border-left-width: 2px;
    right: auto;
    top: 0;
    bottom: 0;
}

.navbar-expand .navbar-collapse {
    width: auto;
    flex: 1 1 auto;
}

.navbar-expand.navbar-dark .nav-item.active .nav-link,
.navbar-expand .navbar-dark .nav-item.active .nav-link {
    background-color: rgba(0, 0, 0, 0.1);
}

.navbar-expand.navbar-light .nav-item.active,
.navbar-expand .navbar-light .nav-item.active {
    position: relative;
}

.navbar-expand.navbar-light .nav-item.active:after,
.navbar-expand .navbar-light .nav-item.active:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(-0.25rem - 1px);
    border: 0 solid #206bc4;
    border-bottom-width: 2px;
}


/**
Navbar brand
 */

.navbar-brand {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
}

.navbar-brand-image {
    display: block;
    height: 2rem;
}


/**
Navbar toggler
 */

.navbar-toggler {
    border: 0;
    width: 2rem;
    height: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-toggler-icon {
    height: 2px;
    width: 1.25em;
    background: currentColor;
    border-radius: 10px;
    transition: 0.2s 0.2s top, 0.2s 0.2s bottom, 0.2s transform, 0s 0.2s opacity;
    position: relative;
}

.navbar-toggler-icon:before,
.navbar-toggler-icon:after {
    content: '';
    display: block;
    height: inherit;
    width: inherit;
    border-radius: inherit;
    background: inherit;
    position: absolute;
    left: 0;
    transition: inherit;
}

.navbar-toggler-icon:before {
    top: -.45em;
}

.navbar-toggler-icon:after {
    bottom: -.45em;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    transform: rotate(45deg);
    transition: .3s top, .3s bottom, .3s .3s transform, 0s .3s opacity;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    top: 0;
    transform: rotate(-90deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    bottom: 0;
    opacity: 0;
}


/**
Navbar light
 */

.navbar-light {
    box-shadow: 0 0 0 1px rgba(110, 117, 130, 0.2);
    background-color: #ffffff;
}


/**
Navbar dark
 */

.navbar-dark {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    background: #354052;
    color: rgba(255, 255, 255, 0.72);
}

.navbar-dark::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    -webkit-transition: .3s background;
    transition: .3s background;
}

.navbar-dark::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #dee1e7;
}

.navbar-dark::-webkit-scrollbar-corner {
    background: transparent;
}

.navbar-dark:hover::-webkit-scrollbar-thumb {
    background: #cbcfd6;
    background: #cbcfd6;
}

.navbar-dark .text-muted {
    color: rgba(255, 255, 255, 0.5184) !important;
}

.navbar-dark .navbar-brand {
    color: #ffffff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #ffffff;
    opacity: .8;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.72);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.3);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #ffffff;
}

.navbar-dark .navbar-toggler {
    color: #ffffff;
    border-color: transparent;
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.72);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: rgba(255, 255, 255, 0.72);
}

.navbar-dark::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    -webkit-transition: .3s background;
    transition: .3s background;
}

.navbar-dark::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: transparent;
}

.navbar-dark::-webkit-scrollbar-corner {
    background: transparent;
}

.navbar-dark:hover::-webkit-scrollbar-thumb {
    background: #cbcfd6;
    background: #5d6675;
}

.navbar-dark .input-icon-addon {
    color: rgba(245, 247, 251, 0.64);
}

.navbar-dark .form-control {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.1);
}

.navbar-dark .form-control::-webkit-input-placeholder {
    color: rgba(245, 247, 251, 0.64);
}

.navbar-dark .form-control::-moz-placeholder {
    color: rgba(245, 247, 251, 0.64);
}

.navbar-dark .form-control:-ms-input-placeholder {
    color: rgba(245, 247, 251, 0.64);
}

.navbar-dark .form-control::-ms-input-placeholder {
    color: rgba(245, 247, 251, 0.64);
}

.navbar-dark .form-control::placeholder {
    color: rgba(245, 247, 251, 0.64);
}


/**
Navbar nav
 */

.navbar-nav {
    align-items: stretch;
}

.navbar-nav .nav-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/**
Navbar side
 */

.navbar-side {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}


/**
Navbar vertical
 */

@media (min-width: 33rem) {
    .navbar-vertical.navbar-expand-sm {
        width: 15rem;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1030;
        align-items: flex-start;
        transition: .3s transform;
        overflow-x: auto;
    }
    .navbar-vertical.navbar-expand-sm.navbar-right {
        left: auto;
        right: 0;
    }
    .navbar-vertical.navbar-expand-sm .navbar-brand {
        padding: 0.75rem 0;
        justify-content: center;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse {
        align-items: stretch;
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav {
        flex-direction: column;
        flex-grow: 1;
        min-height: auto;
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
    .navbar-vertical.navbar-expand-sm>[class*="container"] {
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
        justify-content: flex-start;
    }
    .navbar-vertical.navbar-expand-sm~.page {
        padding-left: 15rem;
    }
    .navbar-vertical.navbar-expand-sm~.page [class*="container"] {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .navbar-vertical.navbar-expand-sm.navbar-right~.page {
        padding-left: 0;
        padding-right: 15rem;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse {
        flex-direction: column;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse [class*="container"] {
        flex-direction: column;
        align-items: stretch;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse .navbar-nav {
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse .navbar-nav .nav-link {
        padding: 0rem 0.75rem;
        justify-content: flex-start;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-menu {
        padding: 0;
        background: transparent;
        position: static;
        color: inherit;
        box-shadow: none;
        border: none;
        -moz-columns: 1;
        columns: 1;
        min-width: 0;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item {
        min-width: 0;
        display: flex;
        width: auto;
        padding-left: 2.25rem;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-toggle:after {
        margin-left: auto;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse .nav-item.active:after {
        border-bottom-width: 0;
        border-left-width: 2px;
        right: auto;
        top: 0;
        bottom: 0;
    }
}

@media (min-width: 48rem) {
    .navbar-vertical.navbar-expand-md {
        width: 15rem;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1030;
        align-items: flex-start;
        transition: .3s transform;
        overflow-x: auto;
    }
    .navbar-vertical.navbar-expand-md.navbar-right {
        left: auto;
        right: 0;
    }
    .navbar-vertical.navbar-expand-md .navbar-brand {
        padding: 0.75rem 0;
        justify-content: center;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse {
        align-items: stretch;
    }
    .navbar-vertical.navbar-expand-md .navbar-nav {
        flex-direction: column;
        flex-grow: 1;
        min-height: auto;
    }
    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
    .navbar-vertical.navbar-expand-md>[class*="container"] {
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
        justify-content: flex-start;
    }
    .navbar-vertical.navbar-expand-md~.page {
        padding-left: 15rem;
    }
    .navbar-vertical.navbar-expand-md~.page [class*="container"] {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .navbar-vertical.navbar-expand-md.navbar-right~.page {
        padding-left: 0;
        padding-right: 15rem;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse {
        flex-direction: column;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse [class*="container"] {
        flex-direction: column;
        align-items: stretch;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse .navbar-nav {
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse .navbar-nav .nav-link {
        padding: 0rem 0.75rem;
        justify-content: flex-start;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-menu {
        padding: 0;
        background: transparent;
        position: static;
        color: inherit;
        box-shadow: none;
        border: none;
        -moz-columns: 1;
        columns: 1;
        min-width: 0;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item {
        min-width: 0;
        display: flex;
        width: auto;
        padding-left: 2.25rem;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-toggle:after {
        margin-left: auto;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse .nav-item.active:after {
        border-bottom-width: 0;
        border-left-width: 2px;
        right: auto;
        top: 0;
        bottom: 0;
    }
}

@media (min-width: 64rem) {
    .navbar-vertical.navbar-expand-lg {
        width: 15rem;
        position: sticky;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1030;
        align-items: flex-start;
        transition: .3s transform;
        overflow-x: auto;
        max-height: 100vh;
    }
    .navbar-vertical.navbar-expand-lg.navbar-right {
        left: auto;
        right: 0;
    }
    .navbar-vertical.navbar-expand-lg .navbar-brand {
        padding: 0.75rem 0;
        justify-content: center;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse {
        align-items: stretch;
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav {
        flex-direction: column;
        flex-grow: 1;
        min-height: auto;
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
    .navbar-vertical.navbar-expand-lg>[class*="container"] {
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
        justify-content: flex-start;
    }
    .navbar-vertical.navbar-expand-lg~.page {
        padding-left: 15rem;
    }
    .navbar-vertical.navbar-expand-lg~.page [class*="container"] {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .navbar-vertical.navbar-expand-lg.navbar-right~.page {
        padding-left: 0;
        padding-right: 15rem;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse {
        flex-direction: column;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse [class*="container"] {
        flex-direction: column;
        align-items: stretch;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav {
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
        padding: 0rem 0.75rem;
        justify-content: flex-start;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-menu {
        padding: 0;
        background: transparent;
        position: static;
        color: inherit;
        box-shadow: none;
        border: none;
        -moz-columns: 1;
        columns: 1;
        min-width: 0;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item {
        min-width: 0;
        display: flex;
        width: auto;
        padding-left: 2.25rem;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-toggle:after {
        margin-left: auto;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse .nav-item.active:after {
        border-bottom-width: 0;
        border-left-width: 2px;
        right: auto;
        top: 0;
        bottom: 0;
    }
}

@media (min-width: 75.25rem) {
    .navbar-vertical.navbar-expand-xl {
        width: 15rem;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1030;
        align-items: flex-start;
        transition: .3s transform;
        overflow-x: auto;
    }
    .navbar-vertical.navbar-expand-xl.navbar-right {
        left: auto;
        right: 0;
    }
    .navbar-vertical.navbar-expand-xl .navbar-brand {
        padding: 0.75rem 0;
        justify-content: center;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse {
        align-items: stretch;
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav {
        flex-direction: column;
        flex-grow: 1;
        min-height: auto;
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
    .navbar-vertical.navbar-expand-xl>[class*="container"] {
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
        justify-content: flex-start;
    }
    .navbar-vertical.navbar-expand-xl~.page {
        padding-left: 15rem;
    }
    .navbar-vertical.navbar-expand-xl~.page [class*="container"] {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .navbar-vertical.navbar-expand-xl.navbar-right~.page {
        padding-left: 0;
        padding-right: 15rem;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse {
        flex-direction: column;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse [class*="container"] {
        flex-direction: column;
        align-items: stretch;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse .navbar-nav {
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse .navbar-nav .nav-link {
        padding: 0rem 0.75rem;
        justify-content: flex-start;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-menu {
        padding: 0;
        background: transparent;
        position: static;
        color: inherit;
        box-shadow: none;
        border: none;
        -moz-columns: 1;
        columns: 1;
        min-width: 0;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item {
        min-width: 0;
        display: flex;
        width: auto;
        padding-left: 2.25rem;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-toggle:after {
        margin-left: auto;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse .nav-item.active:after {
        border-bottom-width: 0;
        border-left-width: 2px;
        right: auto;
        top: 0;
        bottom: 0;
    }
}

.navbar-vertical.navbar-expand {
    width: 15rem;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1030;
    align-items: flex-start;
    transition: .3s transform;
    overflow-x: auto;
}

.navbar-vertical.navbar-expand.navbar-right {
    left: auto;
    right: 0;
}

.navbar-vertical.navbar-expand .navbar-brand {
    padding: 0.75rem 0;
    justify-content: center;
}

.navbar-vertical.navbar-expand .navbar-collapse {
    align-items: stretch;
}

.navbar-vertical.navbar-expand .navbar-nav {
    flex-direction: column;
    flex-grow: 1;
    min-height: auto;
}

.navbar-vertical.navbar-expand .navbar-nav .nav-link {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.navbar-vertical.navbar-expand>[class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    justify-content: flex-start;
}

.navbar-vertical.navbar-expand~.page {
    padding-left: 15rem;
}

.navbar-vertical.navbar-expand~.page [class*="container"] {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.navbar-vertical.navbar-expand.navbar-right~.page {
    padding-left: 0;
    padding-right: 15rem;
}

.navbar-vertical.navbar-expand .navbar-collapse {
    flex-direction: column;
}

.navbar-vertical.navbar-expand .navbar-collapse [class*="container"] {
    flex-direction: column;
    align-items: stretch;
}

.navbar-vertical.navbar-expand .navbar-collapse .navbar-nav {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.navbar-vertical.navbar-expand .navbar-collapse .navbar-nav .nav-link {
    padding: 0rem 0.75rem;
    justify-content: flex-start;
}

.navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu {
    padding: 0;
    background: transparent;
    position: static;
    color: inherit;
    box-shadow: none;
    border: none;
    -moz-columns: 1;
    columns: 1;
    min-width: 0;
}

.navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item {
    min-width: 0;
    display: flex;
    width: auto;
    padding-left: 2.25rem;
}

.navbar-vertical.navbar-expand .navbar-collapse .dropdown-toggle:after {
    margin-left: auto;
}

.navbar-vertical.navbar-expand .navbar-collapse .nav-item.active:after {
    border-bottom-width: 0;
    border-left-width: 2px;
    right: auto;
    top: 0;
    bottom: 0;
}

.page {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
}

.content {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.content>[class*="container"] {
    flex: 1;
}

@media print {
    .content {
        margin: 0 !important;
    }
}

.page-header {
    display: flex;
    align-items: center;
    min-height: 2.25rem;
    margin: 0 0 1.25rem;
}

.page-header>* {
    flex: 1;
}

.page-pretitle {
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .04em;
    color: #6e7582;
}

.page-title {
    margin: 0;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1;
    color: inherit;
}

.footer {
    border-top: 1px solid rgba(110, 117, 130, 0.2);
    background-color: #ffffff;
    padding: 1rem 0;
    color: #6e7582;
    margin-bottom: -1.25rem;
    margin-top: 1.25rem;
}

.footer-transparent {
    margin-top: 0;
    background-color: transparent;
    border-top: 0;
}

.alert-avatar {
    padding-left: 3.75rem;
}

.alert-avatar .avatar {
    position: absolute;
    top: .5rem;
    left: .75rem;
}

.alert a {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.alert a .icon {
    height: 100%;
}

.avatar {
    position: relative;
    width: 2rem;
    height: 2rem;
    font-size: 0.75rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: #6e7582;
    text-align: center;
    text-transform: uppercase;
    vertical-align: bottom;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #f1f3f8 no-repeat center/cover;
    border-radius: 50%;
}

.avatar .icon {
    font-size: 1.25em;
}

.avatar .badge {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fff;
}

a.avatar {
    cursor: pointer;
}

.avatar-sm {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 0.625rem;
    line-height: 1.25rem;
}

.avatar-md {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.25rem;
    line-height: 2.5rem;
}

.avatar-lg {
    width: 4.5rem;
    height: 4.5rem;
    font-size: 2.25rem;
    line-height: 4.5rem;
}

.avatar-xl {
    width: 6rem;
    height: 6rem;
    font-size: 3rem;
    line-height: 6rem;
}

.avatar-list {
    display: inline-flex;
    padding: 0;
    margin: 0 0 -.5rem;
}

.avatar-list .avatar {
    margin-bottom: .5rem;
}

.avatar-list .avatar:not(:last-child) {
    margin-right: .5rem;
}

.avatar-list a.avatar:hover {
    z-index: 1;
}

.avatar-list-stacked .avatar {
    margin-right: -.5rem !important;
    box-shadow: 0 0 0 2px #fff;
}

.card-footer .avatar-list-stacked .avatar {
    box-shadow: 0 0 0 2px rgba(53, 64, 82, 0.024);
}

.avatar-upload {
    width: 4rem;
    height: 4rem;
    border: 1px dashed rgba(110, 117, 130, 0.2);
    background: #ffffff;
    flex-direction: column;
    transition: .3s color, .3s background-color;
}

.avatar-upload svg {
    width: 1.5rem;
    height: 1.5rem;
    stroke-width: 1;
}

.avatar-upload:hover {
    border-color: #206bc4;
    color: #206bc4;
    text-decoration: none;
}

.avatar-upload-text {
    font-size: 0.625rem;
    line-height: 1;
    margin-top: .25rem;
}

.badge {
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .04em;
    display: inline-flex;
    justify-content: center;
    background: #cbcfd6;
    overflow: hidden;
    vertical-align: text-bottom;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a.badge {
    color: #ffffff;
}

.badge:empty {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    min-width: 0;
    min-height: auto;
    padding: 0;
    border-radius: 50%;
}

.badge-up {
    position: absolute;
    top: 25%;
    right: 0;
    min-width: 1.75em;
    min-height: 1.75em;
    padding: 0 .25rem;
    line-height: 1rem;
    background-clip: padding-box;
    transform: translate(0%, -50%);
}

.badge-outline {
    background-color: transparent;
    border: 1px solid currentColor;
}

.badge-pill {
    border-radius: 100px;
    min-width: 1.75em;
}

.badge-avatar {
    box-sizing: content-box;
    width: 1.75em;
    margin: 0 .5em 0 -.5em;
    color: rgba(245, 247, 251, 0.24);
    background: rgba(53, 64, 82, 0.24) no-repeat center/cover;
    vertical-align: text-bottom;
}

.badge-addon {
    padding: 0 .5rem;
    margin: 0 -.5em 0 .5em;
    min-width: 1.75em;
    background: rgba(53, 64, 82, 0.24);
    text-align: center;
    color: #ffffff;
    text-decoration: none;
}

.breadcrumb {
    padding: 0;
    margin: 0;
    background: transparent;
}

.breadcrumb-item.active a {
    color: inherit;
    pointer-events: none;
}

.breadcrumb-dots .breadcrumb-item+.breadcrumb-item:before {
    content: "·";
}

.breadcrumb-arrows .breadcrumb-item+.breadcrumb-item:before {
    content: "›";
    color: #000000;
    font-size: 14px !important;
    font-weight: 400;
}

.breadcrumb-bullets .breadcrumb-item+.breadcrumb-item:before {
    content: "•";
}

.breadcrumb-alternate a {
    color: #6e7582;
}

.breadcrumb-alternate .breadcrumb-item.active {
    color: inherit;
}

.btn {
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn:not([class^="btn-outline"]):not([class*=" btn-outline"]):not([class^="btn-ghost"]):not([class*=" btn-ghost"]):not(.btn-secondary) {
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.02);
}

.btn:not([class^="btn-outline"]):not([class*=" btn-outline"]):not([class^="btn-ghost"]):not([class*=" btn-ghost"]):not(:focus):not(.focus) {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), inset 0 2px 0 -1px rgba(255, 255, 255, 0.1);
}

.btn:not([class^="btn-outline"]):not([class*=" btn-outline"]):not([class^="btn-ghost"]):not([class*=" btn-ghost"]) svg.icon {
    -webkit-filter: drop-shadow(1px 1px 0 rgba(0, 0, 0, 0.05));
    filter: drop-shadow(1px 1px 0 rgba(0, 0, 0, 0.05));
}

.btn:disabled,
.btn.disabled {
    cursor: not-allowed;
}

.btn .icon {
    margin: 0 .25em 0 -.25em;
    font-size: 1.2em;
    vertical-align: sub;
}

.btn .avatar {
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: text-top;
    /* margin: 0 .5em 0 -.25em; */
}

.btn .icon-right {
    margin: 0 -.25em 0 .5em;
}

.btn svg.icon {
    width: 1em;
    height: 1em;
    stroke-width: 1.75;
}

.btn-secondary {
    color: #354052;
    background-color: #ffffff;
    border-color: rgba(110, 117, 130, 0.2);
    background-image: linear-gradient(-180deg, rgba(53, 64, 82, 0), rgba(53, 64, 82, 0.04) 100%);
    box-shadow: none !important;
}

.btn-secondary:hover {
    color: #354052;
    background-color: #FFFFFF;
    border-color: rgba(87, 92, 102, 0.2);
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: #354052;
    background-color: #ececec;
    border-color: rgba(87, 92, 102, 0.2);
    box-shadow: 0 0 0 0.2rem rgba(75, 84, 101, 0.5);
}

.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
    color: #354052;
    background-color: #e6e6e6;
    border-color: rgba(81, 86, 95, 0.2);
}

.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(75, 84, 101, 0.5);
}

.btn-secondary:disabled,
.btn-secondary.disabled {
    color: #354052;
    background-color: #ffffff;
    border-color: rgba(110, 117, 130, 0.2);
}

.btn-secondary.active {
    background: #206bc4;
    color: #ffffff;
    z-index: 3 !important;
}

.btn-lg svg.icon,
.btn-group-lg>.btn svg.icon {
    stroke-width: 2;
}

.btn-pill {
    padding-right: 1.3em;
    padding-left: 1.3em;
    border-radius: 10rem;
}

.btn-pill[class*="btn-icon"] {
    padding: 0.375rem 15px;
}

.btn-icon {
    min-width: calc(2.0333333rem + 2px);
}

.btn-icon.btn-sm,
.btn-group-sm>.btn-icon.btn {
    min-width: calc(1.35rem + 2px);
}

.btn-icon .icon {
    margin: 0 -1em;
}

.btn-link {
    box-shadow: none !important;
}

.btn-square {
    border-radius: 0;
}

.btn-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -.5rem !important;
    margin-right: -.5rem;
    line-height: 1;
}

.btn-list>.btn,
.btn-list>.dropdown {
    margin: 0 .5rem .5rem 0;
}

.btn-list>.btn-block {
    flex: 1;
    white-space: nowrap;
}

.btn-options {
    display: flex;
    align-items: center;
    padding: 0;
    color: #6e7582;
    cursor: pointer;
    background: none;
    border: none;
}

.btn-options:focus {
    outline: 0;
}

.btn-options .icon {
    width: 1rem;
    height: 1rem;
}

.btn-floating {
    position: fixed;
    z-index: 1030;
    bottom: 1.5rem;
    right: 1.5rem;
    border-radius: 50%;
}

.btn-loading {
    position: relative;
    color: transparent !important;
    text-shadow: none !important;
    pointer-events: none;
}

.btn-loading:after {
    content: '';
    display: inline-block;
    vertical-align: text-bottom;
    border: 1px solid #FAA831;
    border-right-color: transparent;
    border-radius: 50%;
    color: #ffffff;
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: calc(50% - .5rem);
    top: calc(50% - .5rem);
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.calendar {
    display: block;
    font-size: 0.765625rem;
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px;
}

.calendar-nav {
    display: flex;
    align-items: center;
}

.calendar-title {
    flex: 1;
    text-align: center;
}

.calendar-body,
.calendar-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: .5rem 0;
}

.calendar-header {
    color: #6e7582;
}

.calendar-date {
    flex: 0 0 14.2857143%;
    max-width: 14.2857143%;
    padding: .2rem;
    text-align: center;
    border: 0;
}

.calendar-date.prev-month,
.calendar-date.next-month {
    opacity: .25;
}

.calendar-date .date-item {
    position: relative;
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    line-height: 1.4rem;
    color: #66758c;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background: 0 0;
    border: 1px solid transparent;
    border-radius: 50%;
    outline: 0;
    transition: background .3s, border .3s, box-shadow .32s, color .3s;
}

.calendar-date .date-item:hover {
    color: #206bc4;
    text-decoration: none;
    background: #fefeff;
    border-color: rgba(110, 117, 130, 0.2);
}

.calendar-date .date-today {
    color: #206bc4;
    border-color: rgba(110, 117, 130, 0.2);
}

.calendar-range {
    position: relative;
}

.calendar-range:before {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 1.4rem;
    content: "";
    background: rgba(32, 107, 196, 0.1);
    transform: translateY(-50%);
}

.calendar-range.range-start .date-item,
.calendar-range.range-end .date-item {
    color: #fff;
    background: #206bc4;
    border-color: #206bc4;
}

.calendar-range.range-start:before {
    left: 50%;
}

.calendar-range.range-end:before {
    right: 50%;
}

.card {
    margin-bottom: 1.25rem;
    box-shadow: rgba(53, 64, 82, 0.04) 0 2px 4px 0;
}

@media (max-width: 75.23rem) {
    .card {
        margin-bottom: 0.75rem;
    }
}

@media print {
    .card {
        border: none;
        box-shadow: none;
    }
}

a.card {
    color: inherit;
    transition: .3s box-shadow;
}

a.card:hover {
    text-decoration: none;
    box-shadow: rgba(53, 64, 82, 0.16) 0 2px 16px 0;
}

.card-group {
    margin-bottom: 1.5rem;
}

.card-inactive {
    pointer-events: none;
    box-shadow: none;
}

.card-inactive .card-body {
    opacity: .64;
}

.card-active {
    position: relative;
    background-color: rgba(32, 107, 196, 0.03);
}

.card-active:before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    content: "";
    border: 1px solid #206bc4;
    border-radius: inherit;
}

.card-link {
    color: inherit;
}

.card-link:hover {
    color: inherit;
    text-decoration: none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
}

.card-btn {
    padding: 1.25rem 1.25rem;
    text-align: center;
    background: rgba(32, 107, 196, 0.02);
    transition: .3s background;
}

.card-btn:hover {
    text-decoration: none;
    background: rgba(32, 107, 196, 0.06);
}

.card-stacked {
    position: relative;
}

.card-stacked:after {
    position: absolute;
    top: -5px;
    right: 5px;
    left: 5px;
    height: 5px;
    content: "";
    background: #ffffff;
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px 3px 0 0;
}

.card-cover {
    position: relative;
    padding: 1.25rem 1.25rem;
    background: #666666 no-repeat center/cover;
}

.card-cover:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: rgba(53, 64, 82, 0.24);
}

.card-cover:first-child,
.card-cover:first-child:before {
    border-radius: 3px 3px 0 0;
}

.card-cover-blurred:before {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
}

.card-actions {
    margin-left: auto;
    font-size: 0.75rem;
}

.card-actions a {
    text-decoration: none;
}

.card-header {
    display: flex;
    align-items: center;
    background: transparent;
}

.card-header-tabs {
    flex: 1;
    margin: -1.25rem -1.25rem;
}

.card-header-pills {
    flex: 1;
}

.card-footer {
    color: #6e7582;
}

.card-progress {
    height: .25rem;
}

.card-progress:last-child {
    border-radius: 0 0 2px 2px;
}

.card-progress:first-child {
    border-radius: 2px 2px 0 0;
}

.card-meta {
    color: #6e7582;
}

.card-title {
    display: block;
    margin: 0 0 1rem;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.3333333;
}

a.card-title:hover {
    color: inherit;
}

.card-header .card-title {
    margin: .125rem 0;
}

.card-subtitle {
    margin-top: -1.25rem;
    margin-bottom: 0.625rem;
    font-size: 87.5%;
    color: #6e7582;
}

.card-body> :last-child {
    margin-bottom: 0;
}

.card-sm>.card-body {
    padding: .75rem;
}

@media (min-width: 48rem) {
    .card-md>.card-body {
        padding: 1.5rem;
    }
}

@media (min-width: 48rem) {
    .card-lg>.card-body {
        padding: 2rem;
    }
}

@media (min-width: 64rem) {
    .card-lg>.card-body {
        padding: 4rem;
    }
}

@media print {
    .card-body {
        padding: 0;
    }
}

.card-body+.card-body {
    border-top: 1px solid rgba(110, 117, 130, 0.2);
}

.card-body-scrollable {
    overflow: auto;
}


/**
Card optinos
 */

.card-options {
    top: 1.5rem;
    right: .75rem;
    display: flex;
    margin-left: auto;
}

.card-options-link {
    display: inline-block;
    min-width: 1rem;
    margin-left: .25rem;
    color: #6e7582;
}

.card-drop {
    line-height: 1;
    color: #6e7582;
}

.card-drop .icon {
    width: 1.5rem;
    height: 1.5rem;
    stroke-width: 1;
}


/**
Card status
 */

.card-status-top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 2px;
    border-radius: 3px 3px 0 0;
}

.card-status-left {
    position: absolute;
    right: auto;
    bottom: 0;
    width: 2px;
    height: 100%;
    border-radius: 3px 0 0 3px;
}

.card-status-bottom {
    position: absolute;
    top: initial;
    bottom: 0;
    width: 100%;
    height: 2px;
    border-radius: 0 0 3px 3px;
}


/**
Card table
 */

.card-table {
    margin-bottom: 0;
}

.card-table tr td:first-child,
.card-table tr th:first-child {
    padding-left: 1.25rem;
}

.card-table tr td:last-child,
.card-table tr th:last-child {
    padding-right: 1.25rem;
}

.card-table thead tr:first-child td,
.card-table thead tr:first-child th {
    border-top: 0;
}

.card-table tbody tr:last-child td,
.card-table tbody tr:last-child th {
    border-bottom: 0;
}

.card-body+.card-table {
    border-top: 1px solid rgba(110, 117, 130, 0.2);
}


/*
Card code
 */

.card-code {
    padding: 0;
}

.card-code .highlight {
    margin: 0;
    border: 0;
}

.card-code pre {
    margin: 0;
    color: #ffffff;
    text-shadow: none;
    background: transparent;
}


/*
Card chart
 */

.card-chart {
    position: relative;
    z-index: 1;
    height: 3.5rem;
}


/**
Card avatar
 */

.card-avatar {
    box-shadow: 0 0 0 0.5rem #ffffff;
}


/*
Card list group
 */

.card-body+.card-list-group {
    border-top: 1px solid rgba(110, 117, 130, 0.2);
}

.card-list-group .list-group-item {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.card-list-group .list-group-item:last-child {
    border-bottom: 0;
}

.card-list-group .list-group-item:first-child {
    border-top: 0;
}


/**
Card tabs
 */

.card-tabs {
    margin-bottom: 1.5rem;
}

.card-tabs .nav-tabs {
    position: relative;
    z-index: 1000;
    border-bottom: 0;
}

.card-tabs .nav-tabs .nav-link {
    background: rgba(53, 64, 82, 0.024);
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-bottom: 0;
}

.card-tabs .nav-tabs .nav-link.active,
.card-tabs .nav-tabs .nav-link:active,
.card-tabs .nav-tabs .nav-link:hover {
    border-color: rgba(110, 117, 130, 0.2);
}

.card-tabs .nav-tabs .nav-link.active {
    background: #ffffff;
}

.card-tabs .nav-tabs .nav-item:not(:first-child) .nav-link {
    border-top-left-radius: 0;
}

.card-tabs .nav-tabs .nav-item:not(:last-child) .nav-link {
    border-top-right-radius: 0;
}

.card-tabs .nav-tabs .nav-item+.nav-item {
    margin-left: -1px;
}

.card-tabs .nav-tabs-bottom {
    margin-bottom: 0;
}

.card-tabs .nav-tabs-bottom .nav-item {
    margin-top: -1px;
    margin-bottom: 0;
}

.card-tabs .nav-tabs-bottom .nav-item .nav-link {
    border-top-width: 0;
    border-bottom: 1px solid rgba(110, 117, 130, 0.2);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.card-tabs .card {
    margin: 0;
    border-top-left-radius: 0;
}

.close:focus {
    outline: none;
    color: #354052;
}

.dropdown-menu {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
}

.dropdown-menu.card {
    padding: 0;
    min-width: 25rem;
    display: none;
}

.dropdown-menu.card.show {
    display: flex;
}

.dropdown-item {
    min-width: 11rem;
    color: inherit;
    display: flex;
    align-items: center;
    margin: 0;
}

.dropdown-item-icon {
    width: 1rem !important;
    font-size: 1rem !important;
    margin-right: .5rem;
    opacity: .64;
    text-align: center;
    stroke-width: 2px;
}

.dropdown-header {
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .04em;
    color: #6e7582;
    padding-bottom: .25rem;
    pointer-events: none;
}

.dropdown-menu-scrollable {
    height: auto;
    max-height: 12rem;
    overflow-x: hidden;
}

.dropdown-menu-columns {
    -moz-columns: 2;
    columns: 2;
    -moz-column-gap: 0;
    column-gap: 0;
}

.dropdown-menu-columns .dropdown-item {
    display: inline-flex;
    min-width: 10rem;
}

.dropdown-menu-columns-2 {
    -moz-columns: 2;
    columns: 2;
}

.dropdown-menu-columns-3 {
    -moz-columns: 3;
    columns: 3;
}

.dropdown-menu-columns-4 {
    -moz-columns: 4;
    columns: 4;
}

.dropdown-menu-arrow {
    margin-top: .5rem;
}

.dropdown-menu-arrow:before {
    content: "";
    position: absolute;
    top: -4px;
    left: 12px;
    display: block;
    background: inherit;
    width: 14px;
    height: 14px;
    transform: rotate(45deg);
    transform-origin: center;
    border: 1px solid;
    border-color: inherit;
    z-index: -1;
    clip: rect(0px, 9px, 9px, 0px);
}

.dropdown-menu-arrow.dropdown-menu-right:before {
    right: 12px;
    left: auto;
}

.dropright>.dropdown-menu {
    margin-top: calc(-0.5rem - 1px);
    margin-left: 0;
}

.dropright .dropdown-toggle:after {
    margin-left: auto;
}

.dropdown-menu-card {
    padding: 0;
}

.dropdown-menu-card>.card {
    margin: 0;
    border: 0;
    box-shadow: none;
}

.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 1rem;
    text-align: center;
}

@media (min-width: 48rem) {
    .empty {
        padding: 3rem;
    }
}

.empty .empty-icon {
    margin: 0 0 1rem;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
}

.empty .empty-title,
.empty .empty-subtitle {
    margin: 0 0 .5rem;
}

.empty .empty-action {
    margin-top: 1.5rem;
}

.empty-bordered {
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px;
}

.col-separator {
    border-left: 1px solid rgba(110, 117, 130, 0.2);
}

.container-tight {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 30rem;
}

.container-narrow {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 45rem;
}

.row-0 {
    margin-right: 0;
    margin-left: 0;
}

.row-0>.col,
.row-0>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.row-0 .card {
    margin-bottom: 0;
}

.row-sm {
    margin-right: -0.375rem;
    margin-left: -0.375rem;
}

.row-sm>.col,
.row-sm>[class*="col-"] {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.row-sm .card {
    margin-bottom: 0.75rem;
}

.row-md {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
}

.row-md>.col,
.row-md>[class*="col-"] {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.row-md .card {
    margin-bottom: 3rem;
}

.row-lg {
    margin-right: -3rem;
    margin-left: -3rem;
}

.row-lg>.col,
.row-lg>[class*="col-"] {
    padding-right: 3rem;
    padding-left: 3rem;
}

.row-lg .card {
    margin-bottom: 6rem;
}

.row-deck>.col,
.row-deck>[class*="col-"] {
    display: flex;
    align-items: stretch;
}

.row-deck>.col .card,
.row-deck>[class*="col-"] .card {
    flex: 1 1 auto;
}

.row-cards {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
}

.row-cards .row-cards {
    flex: 1;
}

.row-cards>* {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

@media (max-width: 75.23rem) {
    .row-cards {
        margin-left: -0.375rem;
        margin-right: -0.375rem;
    }
    .row-cards>* {
        padding-left: 0.375rem;
        padding-right: 0.375rem;
    }
}

.icon {
    width: 1em;
    height: 1em;
    font-size: 1em;
    vertical-align: -0.2em;
}

.icon:hover {
    text-decoration: none;
}

.icon-filled {
    fill: currentColor;
}

.icon-thin {
    stroke-width: 1.5;
}

.icon-md {
    font-size: 1.5rem;
}

.icon-lg {
    font-size: 2rem;
}

.icon-xl {
    font-size: 4rem;
}

.icons-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -2px -1px 0;
    list-style: none;
}

.icons-list>li {
    flex: 1 0 4rem;
}

.icons-list-wrap {
    overflow: hidden;
}

.icons-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    text-align: center;
    border-right: 1px solid rgba(110, 117, 130, 0.2);
    border-bottom: 1px solid rgba(110, 117, 130, 0.2);
}

.icons-list-item i {
    font-size: 1.25rem;
}

textarea[cols] {
    height: auto;
}


/**
Form label
 */

.form-label {
    /* font-size: 0.875rem; */
    font-weight: 500;
    display: block;
    margin-bottom: .5rem;
    color: #3D4D65;
}

.form-label.required:after {
    content: "*";
    margin-left: .25rem;
    color: #cd201f;
}

.form-label-description {
    float: right;
    font-weight: 400;
    color: #6e7582;
    line-height: 1.9591837;
    font-size: 87.5%;
}


/**
Form hint
 */

.form-hint {
    display: block;
    font-size: 87.5%;
    color: #6e7582;
    line-height: 1.3333334;
}

.form-hint:last-child {
    margin-bottom: 0;
}

.form-hint+.form-control {
    margin-top: .25rem;
}

.form-label+.form-hint {
    margin-top: -.5rem;
}

.form-control+.form-hint,
.form-select+.form-hint {
    margin-top: .25rem;
}


/**
Form control
 */

.form-control:-webkit-autofill {
    box-shadow: 0 0 0 1000px #ffffff inset;
}

.form-control:disabled,
.form-control.disabled {
    color: #6e7582;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.form-control[size] {
    width: auto;
}

.form-control-light {
    background-color: #f5f7fb;
    border-color: transparent;
}

.form-control-dark {
    background-color: rgba(0, 0, 0, 0.1);
    color: #ffffff;
    border-color: transparent;
}

.form-control-dark:focus {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
    border-color: rgba(255, 255, 255, 0.24);
}

.form-control-dark::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.form-control-dark::-moz-placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.form-control-dark:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.form-control-dark::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.form-control-dark::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.form-control-rounded {
    border-radius: 10rem;
}

.form-control-flush {
    padding: 0;
    background: none !important;
    border-color: transparent !important;
    resize: none;
    box-shadow: none !important;
    line-height: inherit;
}

.form-footer {
    margin-top: 2rem;
}

.form-fieldset {
    padding: 1rem;
    margin-bottom: 1rem;
    background: rgba(53, 64, 82, 0.024);
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px;
}


/**
Form help
 */

.form-help {
    display: inline-flex;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    width: 1.125rem;
    height: 1.125rem;
    font-size: .75rem;
    color: #6e7582;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: rgba(145, 152, 163, 0.1);
    border-radius: 50%;
    transition: .3s background-color, .3s color;
}

.form-help:hover,
.form-help[aria-describedby] {
    color: #ffffff;
    background: #206bc4;
}


/**
Form switch
 */

.form-check {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.form-check.form-check-highlight .form-check-input:not(:checked)~.form-check-label {
    opacity: .64;
}

.form-check-input {
    margin-top: .25rem;
}

.form-switch .form-check-input {
    transition: .3s background-color, .3s background-position;
}

.form-check-label {
    line-height: 1.3333334;
    padding: .125rem 0;
}

.form-check-description {
    color: #6e7582;
    font-size: 0.75rem;
    line-height: 1.3333334;
}


/**
Input group
 */

.input-group-link {
    font-size: 0.75rem;
}

.input-group-flat:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
    border-radius: 3px;
}

.input-group-flat:focus-within .form-control,
.input-group-flat:focus-within .input-group-text {
    border-color: #7aade9 !important;
}

.input-group-flat .form-control:focus {
    border-color: rgba(110, 117, 130, 0.2);
    box-shadow: none;
}

.input-group-flat .form-control:not(:last-child) {
    border-right: 0;
}

.input-group-flat .form-control:not(:first-child) {
    border-left: 0;
}

.input-group-flat .input-group-text {
    background: transparent;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group-flat .input-group-text:first-child {
    padding-right: 0;
}

.input-group-flat .input-group-text:last-child {
    padding-left: 0;
}


/**
Upload files
 */

.form-file-button {
    margin-left: 0;
    border-left: 0;
}


/**
Icon input
 */

.input-icon {
    position: relative;
}

.input-icon .form-control:not(:last-child) {
    padding-right: 2.5rem;
}

.input-icon .form-control:not(:first-child) {
    padding-left: 2.5rem;
}

.input-icon-addon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    color: #6e7582;
    pointer-events: none;
    font-size: 1.2em;
}

.input-icon-addon:last-child {
    right: 0;
    left: auto;
}


/*
Color Input
 */

.form-colorinput {
    position: relative;
    display: inline-block;
    margin: 0;
    line-height: 1;
    cursor: pointer;
}

.form-colorinput-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.form-colorinput-color {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    color: #fff;
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.form-colorinput-color:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: no-repeat center center/1rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6' transform='scale%28.8%29' transform-origin='50% 50%'/%3e%3c/svg%3e");
    opacity: 0;
    transition: .3s opacity;
}

.form-colorinput-input:checked~.form-colorinput-color:before {
    opacity: 1;
}

.form-colorinput-input:focus~.form-colorinput-color {
    border-color: #206bc4;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.form-colorinput-light .form-colorinput-color:before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23354052' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6' transform='scale%28.8%29' transform-origin='50% 50%'/%3e%3c/svg%3e");
}


/**
Image check
 */

.form-imagecheck {
    position: relative;
    margin: 0;
    cursor: pointer;
}

.form-imagecheck-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.form-imagecheck-figure {
    position: relative;
    display: block;
    margin: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px;
}

.form-imagecheck-input:focus~.form-imagecheck-figure {
    border-color: #206bc4;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.form-imagecheck-input:checked~.form-imagecheck-figure {
    border-color: rgba(110, 117, 130, 0.2);
}

.form-imagecheck-figure:before {
    position: absolute;
    top: .25rem;
    left: .25rem;
    z-index: 1;
    display: block;
    width: 1rem;
    height: 1rem;
    color: #fff;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #ffffff;
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px;
    transition: .3s opacity;
}

.form-imagecheck-input:checked~.form-imagecheck-figure:before {
    background-color: #206bc4;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6' transform='scale%28.8%29' transform-origin='50% 50%'/%3e%3c/svg%3e");
    background-repeat: repeat;
    background-position: center center;
    background-size: 1rem;
    border-color: rgba(110, 117, 130, 0.2);
}

.form-imagecheck-image {
    max-width: 100%;
    display: block;
    opacity: .64;
    transition: .3s opacity;
}

.form-imagecheck-image:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.form-imagecheck-image:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.form-imagecheck:hover .form-imagecheck-image,
.form-imagecheck-input:focus~.form-imagecheck-figure .form-imagecheck-image,
.form-imagecheck-input:checked~.form-imagecheck-figure .form-imagecheck-image {
    opacity: 1;
}

.form-imagecheck-caption {
    padding: .25rem;
    font-size: 0.765625rem;
    color: #6e7582;
    text-align: center;
    transition: .3s color;
}

.form-imagecheck:hover .form-imagecheck-caption,
.form-imagecheck-input:focus~.form-imagecheck-figure .form-imagecheck-caption,
.form-imagecheck-input:checked~.form-imagecheck-figure .form-imagecheck-caption {
    color: #354052;
}


/*
Select group
 */

.form-selectgroup {
    display: inline-flex;
    margin: 0 -.5rem -.5rem 0;
    flex-wrap: wrap;
}

.form-selectgroup .form-selectgroup-item {
    margin: 0 .5rem .5rem 0;
}

.form-selectgroup-vertical {
    flex-direction: column;
}

.form-selectgroup-item {
    display: block;
    position: relative;
}

.form-selectgroup-input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
}

.form-selectgroup-label {
    position: relative;
    display: block;
    min-width: calc(1.4666667em + 0.75rem + 2px);
    margin: 0;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #6e7582;
    background: #ffffff;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px;
    transition: border-color .3s, background .3s, color .3s;
}

.form-selectgroup-label .icon:only-child {
    margin: 0 -.25rem;
}

.form-selectgroup-label:hover {
    color: #354052;
}

.form-selectgroup-check {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 1px solid rgba(110, 117, 130, 0.2);
    vertical-align: middle;
}

.form-selectgroup-input[type="checkbox"]+.form-selectgroup-label .form-selectgroup-check {
    border-radius: 3px;
}

.form-selectgroup-input[type="radio"]+.form-selectgroup-label .form-selectgroup-check {
    border-radius: 50%;
}

.form-selectgroup-input:checked+.form-selectgroup-label .form-selectgroup-check {
    background-color: #206bc4;
    background-repeat: repeat;
    background-position: center center;
    background-size: 1rem;
    border-color: rgba(110, 117, 130, 0.2);
}

.form-selectgroup-input[type="checkbox"]:checked+.form-selectgroup-label .form-selectgroup-check {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6' transform='scale%28.8%29' transform-origin='50% 50%'/%3e%3c/svg%3e");
}

.form-selectgroup-input[type="radio"]:checked+.form-selectgroup-label .form-selectgroup-check {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3ccircle r='3' fill='%23ffffff' cx='8' cy='8' /%3e%3c/svg%3e");
}

.form-selectgroup-input:checked+.form-selectgroup-label {
    z-index: 1;
    color: #206bc4;
    background: rgba(32, 107, 196, 0.04);
    border-color: #7aade9;
    box-shadow: 0 1px 1px 0 rgba(32, 107, 196, 0.2);
}

.form-selectgroup-input:focus+.form-selectgroup-label {
    z-index: 2;
    color: #206bc4;
    border-color: #7aade9;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.form-selectgroup-label-content {
    line-height: 1.3333334;
}


/**
Alternate version of form select group
 */

.form-selectgroup-boxes .form-selectgroup-label {
    text-align: left;
    padding: 1.25rem 1.25rem;
    color: inherit;
}

.form-selectgroup-boxes .form-selectgroup-input:checked+.form-selectgroup-label {
    color: inherit;
}

.form-selectgroup-boxes .form-selectgroup-input:checked+.form-selectgroup-label .form-selectgroup-title {
    color: #206bc4;
}

.form-selectgroup-boxes .form-selectgroup-input:checked+.form-selectgroup-label .form-selectgroup-label-content {
    opacity: 1;
}


/**
Select group
 */

.form-selectgroup-pills {
    flex-wrap: wrap;
    align-items: flex-start;
}

.form-selectgroup-pills .form-selectgroup-item {
    flex-grow: 0;
}

.form-selectgroup-pills .form-selectgroup-label {
    border-radius: 50px;
}


/**
Bootstrap color input
 */

.form-control-color::-webkit-color-swatch {
    border: none;
}


/**
Remove the cancel buttons in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.form-control.is-valid-lite,
.form-control.is-invalid-lite {
    border-color: rgba(110, 117, 130, 0.2);
}

.legend {
    display: inline-block;
    background: #e9ecf1;
    width: .75em;
    height: .75em;
    border-radius: 3px;
}

.list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
}

.list-item-actions {
    margin-left: auto;
}

.list-bordered .list-item,
.list-row .list-item {
    border-top: 1px solid rgba(110, 117, 130, 0.2);
    margin-top: -1px;
}

.list-bordered .list-item:first-child,
.list-row .list-item:first-child {
    border-top: none;
}

.list-hoverable .list-item-actions {
    opacity: 0;
    transition: .3s opacity;
}

.list-hoverable .list-item:hover .list-item-actions,
.list-hoverable .list-item-actions.show {
    opacity: 1;
}

.list-row .list-item {
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0.625rem;
}

.list-row .list-item>* {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

.list-timeline {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
}

.list-timeline>li {
    position: relative;
    margin-bottom: 1.5rem;
}

.list-timeline>li:last-child {
    margin-bottom: 0;
}

.list-timeline-time {
    float: right;
    margin-left: 1rem;
    color: #6e7582;
}

.list-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    color: #ffffff;
    text-align: center;
    background: #6e7582;
    border-radius: 50%;
}

.list-timeline-icon .icon {
    width: 1rem;
    height: 1rem;
}

.list-timeline-title {
    margin: 0;
    font-weight: 500;
}

.list-timeline-content {
    margin-left: 3.5rem;
}

@media screen and (min-width: 768px) {
    .list-timeline:not(.list-timeline-simple):before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(7.5rem + 2px);
        z-index: 1;
        display: block;
        width: 4px;
        content: "";
        background-color: #f5f7fb;
    }
    .list-timeline:not(.list-timeline-simple)>li {
        z-index: 2;
        min-height: 40px;
    }
    .list-timeline:not(.list-timeline-simple) .list-timeline-time {
        position: absolute;
        top: .5rem;
        left: 0;
        width: 5.5rem;
        margin: 0;
        text-align: right;
    }
    .list-timeline:not(.list-timeline-simple) .list-timeline-icon {
        top: 0;
        left: 6.5rem;
    }
    .list-timeline:not(.list-timeline-simple) .list-timeline-content {
        padding: .625rem 0 0 10rem;
        margin: 0;
    }
}

.list-group-transparent {
    margin: 0 -0.75rem;
}

.list-group-transparent .list-group-item {
    background: none;
    border: 0;
    border-radius: 3px;
}

.list-group-transparent .list-group-item .icon {
    color: #6e7582;
}

.list-group-transparent .list-group-item.active {
    font-weight: 500;
    color: inherit;
    background: rgba(32, 107, 196, 0.06);
}

.list-group-transparent .list-group-item.active .icon {
    color: inherit;
}

.list-separated-item {
    padding: 1rem 0;
}

.list-separated-item:first-child {
    padding-top: 0;
}

.list-separated-item:last-child {
    padding-bottom: 0;
}

.list-separated-item+.list-separated-item {
    border-top: 1px solid rgba(110, 117, 130, 0.2);
}


/**
Inline list
 */

.list-inline-dots .list-inline-item+.list-inline-item:before {
    content: '· ';
    margin-right: 0.5rem;
}

@-webkit-keyframes loader {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes loader {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loader {
    position: relative;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    color: #206bc4;
    vertical-align: middle;
}

.loader:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    border: 1px solid;
    border-color: transparent;
    border-top-color: currentColor;
    border-left-color: currentColor;
    border-radius: 50%;
    -webkit-animation: loader .6s linear;
    animation: loader .6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}


/**
Dimmer
*/

.dimmer {
    position: relative;
}

.dimmer .loader {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    display: none;
    margin: 0 auto;
    transform: translateY(-50%);
}

.dimmer.active .loader {
    display: block;
}

.dimmer.active .dimmer-content {
    pointer-events: none;
    opacity: .1;
}

.modal-content .close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1rem;
    width: 3.5rem;
    height: 3.5rem;
    margin: 0;
    padding: 0;
    z-index: 10;
}

.modal-body::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    -webkit-transition: .3s background;
    transition: .3s background;
}

.modal-body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #dee1e7;
}

.modal-body::-webkit-scrollbar-corner {
    background: transparent;
}

.modal-body:hover::-webkit-scrollbar-thumb {
    background: #cbcfd6;
    background: #cbcfd6;
}

.modal-body .modal-title {
    margin-bottom: 1rem;
}

.modal-body+.modal-body {
    border-top: 1px solid rgba(110, 117, 130, 0.1);
}

.modal-header {
    align-items: center;
    min-height: 3.5rem;
    background: #ffffff;
    padding: 0 3.5rem 0 1.5rem;
}

.modal-title {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.3333333;
}

.modal-footer {
    padding-top: 0;
    padding-bottom: .75rem;
}

.modal-blur {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
}

.modal-full-width {
    max-width: none;
    margin: 0 0.5rem;
}

.nav-tabs .icon {
    color: #6e7582;
}

.nav-tabs .nav-link {
    padding: 0.75rem 1.25rem;
    line-height: 20px;
    color: #6e7582;
    cursor: pointer;
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    transition: .3s color, .3s border-color;
}

.nav-tabs .nav-link:hover {
    color: #354052;
    border-color: transparent;
}

.nav-tabs .nav-link.disabled {
    color: rgba(110, 117, 130, 0.2);
}

.nav-tabs .nav-link.active {
    color: #354052;
    background: inherit;
    border-color: transparent;
    border-bottom-color: #206bc4;
}

.nav-tabs-alt .nav-link {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
}

.nav-icon {
    line-height: 1;
    margin-right: .25rem;
}

.nav-icon .icon {
    display: block;
    stroke-width: 1.5;
}

.nav-link {
    border-radius: 3px;
    display: flex;
    transition: .3s color;
    align-items: center;
}

.nav-link .icon {
    display: inline-block;
    font-size: 1rem;
}

.nav-link-icon {
    width: 1.25rem;
    height: 1rem;
    font-size: 1rem;
}

.nav-link-icon .icon {
    display: block;
}

.stars {
    display: inline-flex;
    color: #dee1e7;
    font-size: 0.75rem;
}

.stars .star:not(:first-child) {
    margin-left: .25rem;
}

.pagination {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.page-link {
    min-width: 1.5rem;
    border-radius: 3px;
}

.page-link:hover {
    background: transparent;
}

.page-item {
    text-align: center;
}

.page-item.page-prev,
.page-item.page-next {
    flex: 0 0 50%;
    text-align: left;
}

.page-item.page-next {
    margin-left: auto;
    text-align: right;
}

.page-item-subtitle {
    margin-bottom: 2px;
    font-size: 12px;
    color: #6e7582;
    text-transform: uppercase;
}

.page-item.disabled .page-item-subtitle {
    color: rgba(110, 117, 130, 0.5);
}

.page-item-title {
    font-size: 1.125rem;
    font-weight: 400;
    color: #354052;
}

.page-link:hover .page-item-title {
    color: #206bc4;
}

.page-item.disabled .page-item-title {
    color: rgba(110, 117, 130, 0.5);
}

@-webkit-keyframes progress-indeterminate {
    0% {
        right: 100%;
        left: -35%;
    }
    100%,
    60% {
        right: -90%;
        left: 100%;
    }
}

@keyframes progress-indeterminate {
    0% {
        right: 100%;
        left: -35%;
    }
    100%,
    60% {
        right: -90%;
        left: 100%;
    }
}

.progress {
    position: relative;
    width: 100%;
    line-height: 0.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.progress::-webkit-progress-bar {
    background: #f5f7fb;
}

.progress::-webkit-progress-value {
    background-color: #206bc4;
}

.progress::-moz-progress-bar {
    background-color: #206bc4;
}

.progress::-ms-fill {
    background-color: #206bc4;
    border: none;
}

.progress-sm {
    height: .25rem;
}

.progress-bar {
    height: 100%;
}

.progress-bar-indeterminate:after,
.progress-bar-indeterminate:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-color: inherit;
    will-change: left, right;
}

.progress-bar-indeterminate:before {
    -webkit-animation: progress-indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: progress-indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.ribbon {
    position: absolute;
    top: .75rem;
    right: -0.25rem;
    z-index: 1;
    padding: .25rem .75rem;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    background: #206bc4;
    border-color: #206bc4;
}

.ribbon:before {
    position: absolute;
    right: 0;
    bottom: 100%;
    width: 0;
    height: 0;
    content: "";
    -webkit-filter: brightness(70%);
    filter: brightness(70%);
    border: 0.125rem solid;
    border-color: inherit;
    border-top-color: transparent;
    border-right-color: transparent;
}

.ribbon.bg-blue {
    border-color: #206bc4;
}

.ribbon.bg-blue-lt {
    border-color: #d2e1f3 !important;
}

.ribbon.bg-azure {
    border-color: #45aaf2;
}

.ribbon.bg-azure-lt {
    border-color: #daeefc !important;
}

.ribbon.bg-indigo {
    border-color: #6574cd;
}

.ribbon.bg-indigo-lt {
    border-color: #e0e3f5 !important;
}

.ribbon.bg-purple {
    border-color: #a55eea;
}

.ribbon.bg-purple-lt {
    border-color: #eddffb !important;
}

.ribbon.bg-pink {
    border-color: #f66d9b;
}

.ribbon.bg-pink-lt {
    border-color: #fde2eb !important;
}

.ribbon.bg-red {
    border-color: #cd201f;
}

.ribbon.bg-red-lt {
    border-color: #f5d2d2 !important;
}

.ribbon.bg-orange {
    border-color: #ff922b;
}

.ribbon.bg-orange-lt {
    border-color: #ffe9d5 !important;
}

.ribbon.bg-yellow {
    border-color: #fab005;
}

.ribbon.bg-yellow-lt {
    border-color: #feefcd !important;
}

.ribbon.bg-lime {
    border-color: #94d82d;
}

.ribbon.bg-lime-lt {
    border-color: #eaf7d5 !important;
}

.ribbon.bg-green {
    border-color: #26BF7F;
}

.ribbon.bg-green-lt {
    border-color: #dff1cc !important;
}

.ribbon.bg-teal {
    border-color: #2bcbba;
}

.ribbon.bg-teal-lt {
    border-color: #d5f5f1 !important;
}

.ribbon.bg-cyan {
    border-color: #17a2b8;
}

.ribbon.bg-cyan-lt {
    border-color: #d1ecf1 !important;
}

.ribbon.bg-white {
    border-color: #ffffff;
}

.ribbon.bg-white-lt {
    border-color: white !important;
}

.ribbon.bg-gray {
    border-color: #a8aeb7;
}

.ribbon.bg-gray-lt {
    border-color: #eeeff1 !important;
}

.ribbon.bg-gray-dark {
    border-color: #545d6d;
}

.ribbon.bg-gray-dark-lt {
    border-color: #dddfe2 !important;
}

.ribbon.bg-dark {
    border-color: #354052;
}

.ribbon.bg-dark-lt {
    border-color: #d7d9dc !important;
}

.ribbon .icon {
    width: 1rem;
    height: 1rem;
}

.ribbon-top {
    top: -0.25rem;
    right: .75rem;
    width: 2rem;
    padding: .25rem 0;
}

.ribbon-top:before {
    top: 0;
    right: 100%;
    bottom: auto;
    border-color: inherit;
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbon-top.ribbon-left {
    right: auto;
    left: .75rem;
}

.ribbon-top.ribbon-left:before {
    top: 0;
    right: 100%;
    left: auto;
}

.ribbon-left {
    right: auto;
    left: -0.25rem;
}

.ribbon-left:before {
    top: auto;
    bottom: 100%;
    left: 0;
    border-color: inherit;
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbon-bottom {
    top: auto;
    bottom: .75rem;
}

.ribbon-bookmark {
    padding-left: .25rem;
}

.ribbon-bookmark:after {
    position: absolute;
    top: 0;
    right: 100%;
    display: block;
    width: 0;
    height: 0;
    content: "";
    border: 1rem solid;
    border-color: inherit;
    border-right-width: 0;
    border-left-color: transparent;
    border-left-width: .5rem;
}

.ribbon-bookmark.ribbon-left {
    padding-right: .5rem;
    padding-left: .5rem;
}

.ribbon-bookmark.ribbon-left:after {
    right: auto;
    left: 100%;
    border-right-color: transparent;
    border-right-width: .5rem;
    border-left-width: 0;
}

.ribbon-bookmark.ribbon-top {
    padding-right: 0;
    padding-bottom: .25rem;
    padding-left: 0;
}

.ribbon-bookmark.ribbon-top:after {
    top: 100%;
    right: 0;
    left: 0;
    border-color: inherit;
    border-width: 1rem;
    border-top-width: 0;
    border-bottom-color: transparent;
    border-bottom-width: .5rem;
}

.stamp {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0 .25rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #6e7582;
    text-align: center;
    background: #f1f3f8;
    border-radius: 3px;
}

.stamp .icon {
    font-size: 1.25rem;
    vertical-align: middle;
}

.steps {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0;
    margin: 2rem 0;
    list-style: none;
}

.steps .step-item {
    padding-top: calc(0.5rem + 4px);
}

.steps .step-item:after {
    top: calc(0.25rem + 2px);
    height: 2px;
}

.steps .step-item:before {
    width: 0.5rem;
    height: 0.5rem;
}

.steps .step-item:after,
.steps .step-item:before {
    color: #ffffff;
    background: #206bc4;
}

.steps .step-item.active:before {
    color: inherit;
    border-color: #206bc4;
}

.steps-blue .step-item:after,
.steps-blue .step-item:before {
    color: #ffffff;
    background: #206bc4;
}

.steps-blue .step-item.active:before {
    color: inherit;
    border-color: #206bc4;
}

.steps-azure .step-item:after,
.steps-azure .step-item:before {
    color: #ffffff;
    background: #45aaf2;
}

.steps-azure .step-item.active:before {
    color: inherit;
    border-color: #45aaf2;
}

.steps-indigo .step-item:after,
.steps-indigo .step-item:before {
    color: #ffffff;
    background: #6574cd;
}

.steps-indigo .step-item.active:before {
    color: inherit;
    border-color: #6574cd;
}

.steps-purple .step-item:after,
.steps-purple .step-item:before {
    color: #ffffff;
    background: #a55eea;
}

.steps-purple .step-item.active:before {
    color: inherit;
    border-color: #a55eea;
}

.steps-pink .step-item:after,
.steps-pink .step-item:before {
    color: #ffffff;
    background: #f66d9b;
}

.steps-pink .step-item.active:before {
    color: inherit;
    border-color: #f66d9b;
}

.steps-red .step-item:after,
.steps-red .step-item:before {
    color: #ffffff;
    background: #cd201f;
}

.steps-red .step-item.active:before {
    color: inherit;
    border-color: #cd201f;
}

.steps-orange .step-item:after,
.steps-orange .step-item:before {
    color: #ffffff;
    background: #ff922b;
}

.steps-orange .step-item.active:before {
    color: inherit;
    border-color: #ff922b;
}

.steps-yellow .step-item:after,
.steps-yellow .step-item:before {
    color: #ffffff;
    background: #fab005;
}

.steps-yellow .step-item.active:before {
    color: inherit;
    border-color: #fab005;
}

.steps-lime .step-item:after,
.steps-lime .step-item:before {
    color: #ffffff;
    background: #94d82d;
}

.steps-lime .step-item.active:before {
    color: inherit;
    border-color: #94d82d;
}

.steps-green .step-item:after,
.steps-green .step-item:before {
    color: #ffffff;
    background: #26BF7F;
}

.steps-green .step-item.active:before {
    color: inherit;
    border-color: #26BF7F;
}

.steps-teal .step-item:after,
.steps-teal .step-item:before {
    color: #ffffff;
    background: #2bcbba;
}

.steps-teal .step-item.active:before {
    color: inherit;
    border-color: #2bcbba;
}

.steps-cyan .step-item:after,
.steps-cyan .step-item:before {
    color: #ffffff;
    background: #17a2b8;
}

.steps-cyan .step-item.active:before {
    color: inherit;
    border-color: #17a2b8;
}

.steps-white .step-item:after,
.steps-white .step-item:before {
    color: #ffffff;
    background: #ffffff;
}

.steps-white .step-item.active:before {
    color: inherit;
    border-color: #ffffff;
}

.steps-gray .step-item:after,
.steps-gray .step-item:before {
    color: #ffffff;
    background: #a8aeb7;
}

.steps-gray .step-item.active:before {
    color: inherit;
    border-color: #a8aeb7;
}

.steps-gray-dark .step-item:after,
.steps-gray-dark .step-item:before {
    color: #ffffff;
    background: #545d6d;
}

.steps-gray-dark .step-item.active:before {
    color: inherit;
    border-color: #545d6d;
}

.steps-dark .step-item:after,
.steps-dark .step-item:before {
    color: #ffffff;
    background: #354052;
}

.steps-dark .step-item.active:before {
    color: inherit;
    border-color: #354052;
}

.step-item {
    position: relative;
    flex: 1 1 0;
    min-height: 1rem;
    margin-top: 0;
    color: inherit;
    text-align: center;
    cursor: default;
}

a.step-item {
    cursor: pointer;
}

a.step-item:hover {
    color: inherit;
}

.step-item:not(:first-child):after {
    position: absolute;
    left: -50%;
    width: 100%;
    content: "";
    transform: translateY(-50%);
}

.step-item:before {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    box-sizing: content-box;
    display: block;
    content: "";
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translateX(-50%);
}

.step-item.active {
    font-weight: 500;
}

.step-item.active:before {
    background: #fff;
}

.step-item.active~.step-item {
    color: #6e7582;
}

.step-item.active~.step-item:after,
.step-item.active~.step-item:before {
    background: #f3f5f5;
}

.step-item.active~.step-item:before {
    color: #6e7582 !important;
}

.steps-counter {
    counter-reset: steps;
}

.steps-counter .step-item {
    padding-top: calc(1.5rem + 4px);
}

.steps-counter .step-item:after {
    top: calc(0.75rem + 2px);
    height: 2px;
}

.steps-counter .step-item:before {
    width: 1.5rem;
    height: 1.5rem;
}

.steps-counter .step-item {
    counter-increment: steps;
}

.steps-counter .step-item:before {
    font-size: 1rem;
    line-height: 1.5rem;
    content: counter(steps);
}

.steps-counter .step-item.active~.step-item:before {
    background: #fff;
}

.table,
.markdown>table {
    color: inherit;
}

.table thead th,
.markdown>table thead th {
    background: rgba(53, 64, 82, 0.024);
    border-width: 1px;
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .04em;
    color: #6e7582;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

@media print {
    .table thead th,
    .markdown>table thead th {
        background: transparent;
    }
}

.table th,
.markdown>table th {
    color: #6e7582;
}

.table-nowrap th,
.table-nowrap td {
    white-space: nowrap;
}

.table-md th,
.table-md td {
    padding: .5rem;
}

.table-vcenter td,
.table-vcenter th {
    vertical-align: middle;
}

.table-center td,
.table-center th {
    text-align: center;
}

.td-truncate {
    max-width: 1px;
    width: 100%;
}

@media (max-width: 32.98rem) {
    .table-mobile {
        display: block;
    }
    .table-mobile thead {
        display: none;
    }
    .table-mobile tbody,
    .table-mobile tr {
        display: flex;
        flex-direction: column;
    }
    .table-mobile td {
        display: block;
        padding: 0.5rem !important;
        border: none;
        color: #354052 !important;
    }
    .table-mobile td[data-label]:before {
        font-size: 0.625rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .04em;
        color: #6e7582;
        content: attr(data-label);
        display: block;
    }
    .table-mobile tr {
        border-bottom: 1px solid rgba(110, 117, 130, 0.2);
    }
    .table-mobile .btn {
        display: block;
    }
}

@media (max-width: 47.98rem) {
    .table-mobile-sm {
        display: block;
    }
    .table-mobile-sm thead {
        display: none;
    }
    .table-mobile-sm tbody,
    .table-mobile-sm tr {
        display: flex;
        flex-direction: column;
    }
    .table-mobile-sm td {
        display: block;
        padding: 0.5rem !important;
        border: none;
        color: #354052 !important;
    }
    .table-mobile-sm td[data-label]:before {
        font-size: 0.625rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .04em;
        color: #6e7582;
        content: attr(data-label);
        display: block;
    }
    .table-mobile-sm tr {
        border-bottom: 1px solid rgba(110, 117, 130, 0.2);
    }
    .table-mobile-sm .btn {
        display: block;
    }
}

@media (max-width: 63.98rem) {
    .table-mobile-md {
        display: block;
    }
    .table-mobile-md thead {
        display: none;
    }
    .table-mobile-md tbody,
    .table-mobile-md tr {
        display: flex;
        flex-direction: column;
    }
    .table-mobile-md td {
        display: block;
        padding: 0.5rem !important;
        border: none;
        color: #354052 !important;
    }
    .table-mobile-md td[data-label]:before {
        font-size: 0.625rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .04em;
        color: #6e7582;
        content: attr(data-label);
        display: block;
    }
    .table-mobile-md tr {
        border-bottom: 1px solid rgba(110, 117, 130, 0.2);
    }
    .table-mobile-md .btn {
        display: block;
    }
}

@media (max-width: 75.23rem) {
    .table-mobile-lg {
        display: block;
    }
    .table-mobile-lg thead {
        display: none;
    }
    .table-mobile-lg tbody,
    .table-mobile-lg tr {
        display: flex;
        flex-direction: column;
    }
    .table-mobile-lg td {
        display: block;
        padding: 0.5rem !important;
        border: none;
        color: #354052 !important;
    }
    .table-mobile-lg td[data-label]:before {
        font-size: 0.625rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .04em;
        color: #6e7582;
        content: attr(data-label);
        display: block;
    }
    .table-mobile-lg tr {
        border-bottom: 1px solid rgba(110, 117, 130, 0.2);
    }
    .table-mobile-lg .btn {
        display: block;
    }
}

.table-mobile-xl {
    display: block;
}

.table-mobile-xl thead {
    display: none;
}

.table-mobile-xl tbody,
.table-mobile-xl tr {
    display: flex;
    flex-direction: column;
}

.table-mobile-xl td {
    display: block;
    padding: 0.5rem !important;
    border: none;
    color: #354052 !important;
}

.table-mobile-xl td[data-label]:before {
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .04em;
    color: #6e7582;
    content: attr(data-label);
    display: block;
}

.table-mobile-xl tr {
    border-bottom: 1px solid rgba(110, 117, 130, 0.2);
}

.table-mobile-xl .btn {
    display: block;
}

.toast-header {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.toast button[data-dismiss="toast"] {
    outline: none;
}

.toolbar {
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    margin: 0 -.5rem;
}

.toolbar>* {
    margin: 0 .5rem;
}


/**
Horizontal rules
 */


/**
Hr text
 */

.hr-text {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .04em;
    color: #6e7582;
    height: 1px;
}

.hr-text:after,
.hr-text:before {
    flex: 1 1 auto;
    height: 1px;
    background-color: currentColor;
    opacity: 0.1;
}

.hr-text:before {
    content: "";
    margin-right: .5rem;
}

.hr-text:after {
    content: "";
    margin-left: .5rem;
}

.hr-text>*:first-child {
    padding-right: .5rem;
    padding-left: 0;
    color: #6e7582;
}

.hr-text.hr-text-left:before {
    content: none;
}

.hr-text.hr-text-left>*:first-child {
    padding-right: .5rem;
    padding-left: .5rem;
}

.hr-text.hr-text-right:before {
    content: "";
}

.hr-text.hr-text-right:after {
    content: none;
}

.hr-text.hr-text-right>*:first-child {
    padding-right: 0;
    padding-left: .5rem;
}

.card>.hr-text {
    margin: 0;
}

.hr-text-spaceless {
    margin: -.5rem 0;
}

.lead {
    line-height: 1.4;
}

a {
    -webkit-text-decoration-skip: ink;
    text-decoration-skip-ink: auto;
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
    color: inherit;
}

strong,
.strong,
b {
    font-weight: 500;
}

blockquote {
    padding-left: 1rem;
    color: #6e7582;
    border-left: 2px solid rgba(110, 117, 130, 0.2);
}

blockquote p {
    margin-bottom: 1rem;
}

blockquote cite {
    display: block;
    text-align: right;
}

blockquote cite:before {
    content: "— ";
}

hr,
.hr {
    margin: 2rem 0;
}

dl dd:last-child {
    margin-bottom: 0;
}

code {
    padding: 2px 4px;
    background: rgba(32, 107, 196, 0.03);
    border: 1px solid rgba(32, 107, 196, 0.064);
    border-radius: 3px;
}

pre {
    padding: 1rem;
    overflow: auto;
    font-size: 0.625rem;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    line-height: 1.7142857;
    -moz-tab-size: 3;
    -o-tab-size: 3;
    tab-size: 3;
    border-radius: 3px;
    white-space: pre-wrap;
}

pre code {
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
}

img {
    max-width: 100%;
}


/**
Selection
 */

::-moz-selection {
    color: #fff;
    background-color: #307fdd;
}

::selection {
    color: #fff;
    background-color: #307fdd;
}


/**
Links
 */

[class^="link-"].disabled,
[class*=" link-"].disabled {
    color: #a8aeb7;
    pointer-events: none;
}


/**
Subheader
 */

.subheader {
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .04em;
    color: #6e7582;
}


/**
Markdown
 */

.markdown> :first-child {
    margin-top: 0;
}

.markdown> :last-child,
.markdown> :last-child .highlight {
    margin-bottom: 0;
}

.markdown>h1,
.markdown>.h1,
.markdown>h2,
.markdown>.h2,
.markdown>h3,
.markdown>.h3,
.markdown>h4,
.markdown>.h4,
.markdown>h5,
.markdown>.h5,
.markdown>h6,
.markdown>.h6 {
    margin-top: 2rem;
}

.markdown>h2,
.markdown>.h2 {
    margin-bottom: 1rem;
}

.markdown>h2:before,
.markdown>.h2:before {
    content: '';
    padding-top: 1em;
    margin-top: -1em;
    display: block;
}

@media (min-width: 48rem) {
    .markdown>hr,
    .markdown>.hr {
        margin-top: 3em;
        margin-bottom: 3em;
    }
}

.chart {
    display: block;
    min-height: 10rem;
}

.chart text {
    font-family: inherit;
}

.chart-sm {
    height: 2.5rem;
}

.chart-lg {
    height: 15rem;
}

.chart-square {
    height: 5.75rem;
}


/**
Chart sparkline
 */

.chart-sparkline {
    position: relative;
    width: 4rem;
    height: 2.5rem;
    line-height: 1;
}

.chart-sparkline-square {
    width: 2.5rem;
}

.chart-sparkline-wide {
    width: 6rem;
}

.chart-sparkline-label {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.625rem;
}

.chart-sparkline-label .icon {
    width: 1rem;
    height: 1rem;
}

.bg-white-overlay {
    color: #fff;
    background-color: rgba(245, 247, 251, 0.24);
}

.bg-dark-overlay {
    color: #fff;
    background-color: rgba(53, 64, 82, 0.24);
}

.bg-cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


/**
SOCIAL COLORS
 */

.bg-facebook {
    color: #fff !important;
    background: #3b5998 !important;
}

.text-facebook {
    color: #3b5998 !important;
}

.bg-twitter {
    color: #fff !important;
    background: #1da1f2 !important;
}

.text-twitter {
    color: #1da1f2 !important;
}

.bg-google {
    color: #fff !important;
    background: #dc4e41 !important;
}

.text-google {
    color: #dc4e41 !important;
}

.bg-youtube {
    color: #fff !important;
    background: #ff0000 !important;
}

.text-youtube {
    color: #ff0000 !important;
}

.bg-vimeo {
    color: #fff !important;
    background: #1ab7ea !important;
}

.text-vimeo {
    color: #1ab7ea !important;
}

.bg-dribbble {
    color: #fff !important;
    background: #ea4c89 !important;
}

.text-dribbble {
    color: #ea4c89 !important;
}

.bg-github {
    color: #fff !important;
    background: #181717 !important;
}

.text-github {
    color: #181717 !important;
}

.bg-instagram {
    color: #fff !important;
    background: #e4405f !important;
}

.text-instagram {
    color: #e4405f !important;
}

.bg-pinterest {
    color: #fff !important;
    background: #bd081c !important;
}

.text-pinterest {
    color: #bd081c !important;
}

.bg-vk {
    color: #fff !important;
    background: #6383a8 !important;
}

.text-vk {
    color: #6383a8 !important;
}

.bg-rss {
    color: #fff !important;
    background: #ffa500 !important;
}

.text-rss {
    color: #ffa500 !important;
}

.bg-flickr {
    color: #fff !important;
    background: #0063dc !important;
}

.text-flickr {
    color: #0063dc !important;
}

.bg-bitbucket {
    color: #fff !important;
    background: #0052cc !important;
}

.text-bitbucket {
    color: #0052cc !important;
}

.bg-tabler {
    color: #fff !important;
    background: #206bc4 !important;
}

.text-tabler {
    color: #206bc4 !important;
}

.bg-blue {
    background: #206bc4;
}

.text-blue {
    color: #206bc4 !important;
}

.bg-blue-lt {
    color: #206bc4 !important;
    background: #d2e1f3 !important;
}

.bg-azure {
    background: #45aaf2;
}

.text-azure {
    color: #45aaf2 !important;
}

.bg-azure-lt {
    color: #45aaf2 !important;
    background: #daeefc !important;
}

.bg-indigo {
    background: #6574cd;
}

.text-indigo {
    color: #6574cd !important;
}

.bg-indigo-lt {
    color: #6574cd !important;
    background: #e0e3f5 !important;
}

.bg-purple {
    background: #a55eea;
}

.text-purple {
    color: #a55eea !important;
}

.bg-purple-lt {
    color: #a55eea !important;
    background: #eddffb !important;
}

.bg-pink {
    background: #f66d9b;
}

.text-pink {
    color: #f66d9b !important;
}

.bg-pink-lt {
    color: #f66d9b !important;
    background: #fde2eb !important;
}

.bg-red {
    background: #cd201f;
}

.text-red {
    color: #cd201f !important;
}

.bg-red-lt {
    color: #cd201f !important;
    background: #f5d2d2 !important;
}

.bg-orange {
    background: #FAA831;
}

.text-orange {
    color: #FAA831 !important;
}

.bg-orange-lt {
    color: #ff922b !important;
    background: #ffe9d5 !important;
}

.bg-yellow {
    background: #fab005;
}

.text-yellow {
    color: #fab005 !important;
}

.bg-yellow-lt {
    color: #fab005 !important;
    background: #feefcd !important;
}

.bg-lime {
    background: #94d82d;
}

.text-lime {
    color: #94d82d !important;
}

.bg-lime-lt {
    color: #94d82d !important;
    background: #eaf7d5 !important;
}

.bg-green {
    background: #26BF7F;
}

.text-green {
    color: #26BF7F !important;
}

.bg-green-lt {
    color: #26BF7F !important;
    background: #dff1cc !important;
}

.bg-teal {
    background: #2bcbba;
}

.text-teal {
    color: #2bcbba !important;
}

.bg-teal-lt {
    color: #2bcbba !important;
    background: #d5f5f1 !important;
}

.bg-cyan {
    background: #17a2b8;
}

.text-cyan {
    color: #17a2b8 !important;
}

.bg-cyan-lt {
    color: #17a2b8 !important;
    background: #d1ecf1 !important;
}

.bg-white {
    background: #ffffff;
}

.text-white {
    color: #ffffff !important;
}

.bg-white-lt {
    color: #ffffff !important;
    background: white !important;
}

.bg-gray {
    background: #a8aeb7;
}

.text-gray {
    color: #a8aeb7 !important;
}

.bg-gray-lt {
    color: #a8aeb7 !important;
    background: #eeeff1 !important;
}

.bg-gray-dark {
    background: #545d6d;
}

.text-gray-dark {
    color: #545d6d !important;
}

.bg-gray-dark-lt {
    color: #545d6d !important;
    background: #dddfe2 !important;
}

.bg-dark {
    background: #354052;
}

.text-dark {
    color: #354052 !important;
}

.bg-dark-lt {
    color: #354052 !important;
    background: #d7d9dc !important;
}


/*
Scrollable
*/

.scrollable {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.scrollable.hover {
    overflow-y: hidden;
}

.scrollable.hover>* {
    margin-top: -1px;
}

.scrollable.hover:hover,
.scrollable.hover:focus,
.scrollable.hover:active {
    overflow: visible;
    overflow-y: auto;
}

.touch .scrollable {
    overflow-y: auto !important;
}

.scroll-x,
.scroll-y {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

.scroll-y {
    overflow-y: auto;
}

.scroll-x {
    overflow-x: auto;
}

.no-scroll {
    overflow: hidden;
}

.w-0 {
    width: 0 !important;
}

.h-0 {
    height: 0 !important;
}

.w-1 {
    width: 0.25rem !important;
}

.h-1 {
    height: 0.25rem !important;
}

.w-2 {
    width: 0.5rem !important;
}

.h-2 {
    height: 0.5rem !important;
}

.w-3 {
    width: 1rem !important;
}

.h-3 {
    height: 1rem !important;
}

.w-4 {
    width: 1.5rem !important;
}

.h-4 {
    height: 1.5rem !important;
}

.w-5 {
    width: 3rem !important;
}

.h-5 {
    height: 3rem !important;
}

.w-6 {
    width: 4.5rem !important;
}

.h-6 {
    height: 4.5rem !important;
}

.w-auto {
    width: auto !important;
}

.h-auto {
    height: auto !important;
}

.w-px {
    width: 1px !important;
}

.h-px {
    height: 1px !important;
}

.w-full {
    width: 100% !important;
}

.h-full {
    height: 100% !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.opacity-5 {
    opacity: 0.05 !important;
}

.opacity-10 {
    opacity: 0.1 !important;
}

.opacity-15 {
    opacity: 0.15 !important;
}

.opacity-20 {
    opacity: 0.2 !important;
}

.opacity-25 {
    opacity: 0.25 !important;
}

.opacity-30 {
    opacity: 0.3 !important;
}

.opacity-35 {
    opacity: 0.35 !important;
}

.opacity-40 {
    opacity: 0.4 !important;
}

.opacity-45 {
    opacity: 0.45 !important;
}

.opacity-50 {
    opacity: 0.5 !important;
}

.opacity-55 {
    opacity: 0.55 !important;
}

.opacity-60 {
    opacity: 0.6 !important;
}

.opacity-65 {
    opacity: 0.65 !important;
}

.opacity-70 {
    opacity: 0.7 !important;
}

.opacity-75 {
    opacity: 0.75 !important;
}

.opacity-80 {
    opacity: 0.8 !important;
}

.opacity-85 {
    opacity: 0.85 !important;
}

.opacity-90 {
    opacity: 0.9 !important;
}

.opacity-95 {
    opacity: 0.95 !important;
}

.opacity-100 {
    opacity: 1 !important;
}

.hover-shadow-sm:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.hover-shadow:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.hover-shadow-lg:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.hover-shadow-none:hover {
    box-shadow: none !important;
}


/**
Antialiasing
 */

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

@media not print {
    .theme-dark {
        color: #f5f7fb;
        background-color: #2f3949;
    }
    .theme-dark .card,
    .theme-dark .footer,
    .theme-dark .modal-content,
    .theme-dark .modal-header,
    .theme-dark .dropdown-menu,
    .theme-dark .flatpickr-calendar {
        background-color: #354052;
        color: inherit;
    }
    .theme-dark .card {
        border-color: transparent;
    }
    .theme-dark .btn-secondary,
    .theme-dark .form-file-button {
        color: #f5f7fb;
        background-color: #3b475b;
        border-color: rgba(110, 117, 130, 0.2);
        background-image: none;
    }
    .theme-dark .btn-secondary:hover,
    .theme-dark .form-file-button:hover {
        color: #f5f7fb;
        background-color: rgba(53, 64, 82, 0);
        border-color: rgba(110, 117, 130, 0.2);
    }
    .theme-dark .btn-secondary:focus,
    .theme-dark .btn-secondary.focus,
    .theme-dark .form-file-button:focus,
    .theme-dark .form-file-button.focus {
        color: #f5f7fb;
        background-color: rgba(53, 64, 82, 0);
        border-color: rgba(110, 117, 130, 0.2);
        box-shadow: 0 0 0 0.2rem rgba(193, 197, 204, 0.5);
    }
    .theme-dark .btn-secondary:active,
    .theme-dark .btn-secondary.active,
    .show>.theme-dark .btn-secondary.dropdown-toggle,
    .theme-dark .form-file-button:active,
    .theme-dark .form-file-button.active,
    .show>.theme-dark .form-file-button.dropdown-toggle {
        color: #ffffff;
        background-color: #272f3c;
        border-color: rgba(81, 86, 95, 0.2);
    }
    .theme-dark .btn-secondary:active:focus,
    .theme-dark .btn-secondary.active:focus,
    .show>.theme-dark .btn-secondary.dropdown-toggle:focus,
    .theme-dark .form-file-button:active:focus,
    .theme-dark .form-file-button.active:focus,
    .show>.theme-dark .form-file-button.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(193, 197, 204, 0.5);
    }
    .theme-dark .btn-secondary:disabled,
    .theme-dark .btn-secondary.disabled,
    .theme-dark .form-file-button:disabled,
    .theme-dark .form-file-button.disabled {
        color: #f5f7fb;
        background-color: #3b475b;
        border-color: rgba(110, 117, 130, 0.2);
    }
    .theme-dark .form-check-input:not(:checked),
    .theme-dark .form-select,
    .theme-dark .form-file-text,
    .theme-dark .form-control,
    .theme-dark .form-selectgroup-label,
    .theme-dark .flatpickr-input.flatpickr-input,
    .theme-dark .form-selectgroup-check,
    .theme-dark .form-imagecheck-figure:before {
        background-color: #2f3949;
        color: #f5f7fb;
    }
    .theme-dark .form-control-plaintext {
        color: #f5f7fb;
    }
    .theme-dark .input-group-flat .input-group-text {
        background-color: #2f3949;
    }
    .theme-dark .highlight {
        background-color: #2f3949;
    }
    .theme-dark .avatar {
        background-color: #3b475b;
    }
    .theme-dark .avatar-list-stacked .avatar {
        box-shadow: 0 0 0 2px #354052;
    }
    .theme-dark .markdown,
    .theme-dark .markdown>*,
    .theme-dark .close {
        color: inherit;
    }
    .theme-dark .apexcharts-text {
        fill: #f5f7fb;
    }
    .theme-dark .apexcharts-legend-text {
        color: inherit !important;
    }
    .theme-dark .navbar-brand-autodark {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
}

@media not print and (prefers-color-scheme: dark) {
    .theme-dark-auto {
        color: #f5f7fb;
        background-color: #2f3949;
    }
    .theme-dark-auto .card,
    .theme-dark-auto .footer,
    .theme-dark-auto .modal-content,
    .theme-dark-auto .modal-header,
    .theme-dark-auto .dropdown-menu,
    .theme-dark-auto .flatpickr-calendar {
        background-color: #354052;
        color: inherit;
    }
    .theme-dark-auto .card {
        border-color: transparent;
    }
    .theme-dark-auto .btn-secondary,
    .theme-dark-auto .form-file-button {
        color: #f5f7fb;
        background-color: #3b475b;
        border-color: rgba(110, 117, 130, 0.2);
        background-image: none;
    }
    .theme-dark-auto .btn-secondary:hover,
    .theme-dark-auto .form-file-button:hover {
        color: #f5f7fb;
        background-color: rgba(53, 64, 82, 0);
        border-color: rgba(110, 117, 130, 0.2);
    }
    .theme-dark-auto .btn-secondary:focus,
    .theme-dark-auto .btn-secondary.focus,
    .theme-dark-auto .form-file-button:focus,
    .theme-dark-auto .form-file-button.focus {
        color: #f5f7fb;
        background-color: rgba(53, 64, 82, 0);
        border-color: rgba(110, 117, 130, 0.2);
        box-shadow: 0 0 0 0.2rem rgba(193, 197, 204, 0.5);
    }
    .theme-dark-auto .btn-secondary:active,
    .theme-dark-auto .btn-secondary.active,
    .show>.theme-dark-auto .btn-secondary.dropdown-toggle,
    .theme-dark-auto .form-file-button:active,
    .theme-dark-auto .form-file-button.active,
    .show>.theme-dark-auto .form-file-button.dropdown-toggle {
        color: #ffffff;
        background-color: #272f3c;
        border-color: rgba(81, 86, 95, 0.2);
    }
    .theme-dark-auto .btn-secondary:active:focus,
    .theme-dark-auto .btn-secondary.active:focus,
    .show>.theme-dark-auto .btn-secondary.dropdown-toggle:focus,
    .theme-dark-auto .form-file-button:active:focus,
    .theme-dark-auto .form-file-button.active:focus,
    .show>.theme-dark-auto .form-file-button.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(193, 197, 204, 0.5);
    }
    .theme-dark-auto .btn-secondary:disabled,
    .theme-dark-auto .btn-secondary.disabled,
    .theme-dark-auto .form-file-button:disabled,
    .theme-dark-auto .form-file-button.disabled {
        color: #f5f7fb;
        background-color: #3b475b;
        border-color: rgba(110, 117, 130, 0.2);
    }
    .theme-dark-auto .form-check-input:not(:checked),
    .theme-dark-auto .form-select,
    .theme-dark-auto .form-file-text,
    .theme-dark-auto .form-control,
    .theme-dark-auto .form-selectgroup-label,
    .theme-dark-auto .flatpickr-input.flatpickr-input,
    .theme-dark-auto .form-selectgroup-check,
    .theme-dark-auto .form-imagecheck-figure:before {
        background-color: #2f3949;
        color: #f5f7fb;
    }
    .theme-dark-auto .form-control-plaintext {
        color: #f5f7fb;
    }
    .theme-dark-auto .input-group-flat .input-group-text {
        background-color: #2f3949;
    }
    .theme-dark-auto .highlight {
        background-color: #2f3949;
    }
    .theme-dark-auto .avatar {
        background-color: #3b475b;
    }
    .theme-dark-auto .avatar-list-stacked .avatar {
        box-shadow: 0 0 0 2px #354052;
    }
    .theme-dark-auto .markdown,
    .theme-dark-auto .markdown>*,
    .theme-dark-auto .close {
        color: inherit;
    }
    .theme-dark-auto .apexcharts-text {
        fill: #f5f7fb;
    }
    .theme-dark-auto .apexcharts-legend-text {
        color: inherit !important;
    }
    .theme-dark-auto .navbar-brand-autodark {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
}

.peity {
    stroke-linejoin: round;
    stroke-linecap: round;
}

.selectize-control {
    padding: 0;
    border: 0;
    height: auto;
}

.selectize-control.multi .selectize-input.has-items {
    padding-left: 6px;
}

.selectize-control.multi .selectize-input>div {
    font-size: 0.75rem;
    margin: 0 6px 2px 0;
    padding: 2px 8px;
    border-radius: 3px;
    background-color: rgba(145, 152, 163, 0.1);
    color: inherit;
}

.selectize-control .remove {
    font-size: inherit !important;
    font-weight: normal !important;
    border-left: 0 !important;
    color: #6e7582 !important;
}

.selectize-control.is-valid {
    padding: 0;
}

.selectize-control.is-valid .selectize-input {
    border-color: #26BF7F;
}

.selectize-control.is-valid .selectize-input.focus {
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.25);
}

.selectize-control.is-valid .selectize-dropdown {
    background: none;
    background-color: inherit;
    border-color: rgba(110, 117, 130, 0.2);
    padding-right: 0;
}

.selectize-control.is-invalid {
    padding: 0;
}

.selectize-control.is-invalid .selectize-input {
    border-color: #cd201f;
}

.selectize-control.is-invalid .selectize-input.focus {
    box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.25);
}

.selectize-control.is-invalid .selectize-dropdown {
    background: none;
    background-color: inherit;
    border-color: rgba(110, 117, 130, 0.2);
    padding-right: 0;
}

.selectize-input {
    display: flex !important;
    border: 1px solid rgba(110, 117, 130, 0.2);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    box-shadow: none;
    line-height: 1.5;
    background-color: inherit !important;
    color: inherit !important;
    flex-wrap: wrap;
}

.selectize-input.full {
    background-color: inherit;
}

.selectize-input.dropdown-active {
    border-radius: 3px;
}

.selectize-control.single .selectize-input {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28110, 117, 130, 0.44%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px;
}

.selectize-input.focus {
    border-color: #7aade9;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.selectize-input:after {
    content: none !important;
}

.selectize-dropdown {
    height: auto;
    background: #ffffff;
    padding: 0.375rem 0;
    margin-top: 1px;
}

.selectize-dropdown .option,
.selectize-dropdown .option-header {
    padding: 0.25rem 0.75rem;
}

.selectize-dropdown .active {
    background: rgba(110, 117, 130, 0.06);
    color: inherit;
    cursor: pointer;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
    height: auto;
    width: auto;
    left: .5rem;
    border: 1px solid rgba(110, 117, 130, 0.2);
    background: #ffffff;
    color: #354052;
}

.jqvmap-zoomin {
    top: .5rem;
}

.jqvmap-zoomout {
    top: 2rem;
}

.jqvmap-label {
    font-family: inherit;
    font-size: 0.625rem;
    line-height: 1;
    padding: .25rem;
    background: #354052;
}

.apexcharts-title-text {
    font-weight: 500 !important;
}

.apexcharts-tooltip-title {
    font-weight: 500 !important;
    padding-bottom: .25rem !important;
}

.apexcharts-gridline,
.apexcharts-ycrosshairs,
.apexcharts-xaxis-tick {
    stroke: rgba(110, 117, 130, 0.2) !important;
}

.apexcharts-tooltip {
    line-height: 1;
    color: #fff;
    background: #354052 !important;
    border: 0 !important;
    border-radius: 3px !important;
}

.apexcharts-tooltip-title {
    margin: 0 !important;
    background: transparent !important;
    border: 0 !important;
}

.apexcharts-tooltip-series-group {
    padding: 0 8px !important;
}

.apexcharts-tooltip-marker {
    width: .5rem;
    height: .5rem;
}

.apexcharts-gridline,
.apexcharts-ycrosshairs,
.apexcharts-xaxis-tick {
    stroke: #e9ecf1;
}

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
    border-color: rgba(110, 117, 130, 0.2);
}

.fc-unthemed .fc-toolbar h2,
.fc-unthemed .fc-toolbar .h2 {
    font-size: 1.375rem;
    margin: 0;
}

.fc-unthemed .fc-button {
    color: #6e7582;
    background-color: #ffffff;
    border-color: rgba(110, 117, 130, 0.2);
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fc-unthemed .fc-button:hover {
    color: #354052;
    background-color: #ececec;
    border-color: rgba(87, 92, 102, 0.2);
}

.fc-unthemed .fc-button:focus,
.fc-unthemed .fc-button.focus {
    color: #354052;
    background-color: #ececec;
    border-color: rgba(87, 92, 102, 0.2);
    box-shadow: 0 0 0 0.2rem rgba(110, 117, 130, 0.5);
}

.fc-unthemed .fc-button:active,
.fc-unthemed .fc-button.active,
.show>.fc-unthemed .fc-button.dropdown-toggle {
    color: #354052;
    background-color: #e6e6e6;
    border-color: rgba(81, 86, 95, 0.2);
}

.fc-unthemed .fc-button:active:focus,
.fc-unthemed .fc-button.active:focus,
.show>.fc-unthemed .fc-button.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(110, 117, 130, 0.5);
}

.fc-unthemed .fc-button:disabled,
.fc-unthemed .fc-button.disabled {
    color: #6e7582;
    background-color: #ffffff;
    border-color: rgba(110, 117, 130, 0.2);
}

@media (prefers-reduced-motion: reduce) {
    .fc-unthemed .fc-button {
        transition: none;
    }
}

.fc-unthemed .fc-widget-header {
    border: 0;
}

.fc-unthemed .fc-day-header {
    padding-bottom: .25rem;
    font-weight: 500;
}

.fc-unthemed.card-calendar .fc-day-grid-container {
    border-bottom: 0;
    border-left: 0;
}

.fc-unthemed .fc-time {
    font-weight: 500;
}

.fc-unthemed .fc-event {
    border-color: rgba(0, 0, 0, 0.05);
    color: #206bc4;
    background-color: #d2e1f3;
    padding: 0 .25rem;
}

.flatpickr-input.flatpickr-input {
    background: #ffffff;
}

.flatpickr-input.flatpickr-input.active {
    border-color: #7aade9;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.flatpickr-calendar {
    background: #ffffff;
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-radius: 3px;
    padding: .5rem;
    font-size: inherit;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: -1px;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
    content: none;
}

.flatpickr-calendar .flatpickr-innerContainer,
.flatpickr-calendar .flatpickr-rContainer,
.flatpickr-calendar .dayContainer,
.flatpickr-calendar .flatpickr-days {
    width: 100%;
}

.flatpickr-calendar .dayContainer,
.flatpickr-calendar .flatpickr-day {
    max-width: 100%;
    min-width: 0;
}

.flatpickr-calendar.inline {
    width: 100%;
    box-shadow: none;
    margin: 0;
}

.flatpickr-day {
    color: inherit;
    border-radius: 3px;
    display: flex;
    align-items: center;
    line-height: 1;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
    color: inherit;
    opacity: .2;
}

.flatpickr-day.today {
    border-color: rgba(110, 117, 130, 0.2);
}

.flatpickr-day.selected {
    background-color: #206bc4;
}

span.flatpickr-weekday {
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .04em;
    color: #6e7582;
}

.flatpickr-months {
    margin-bottom: .5rem;
}

.flatpickr-months .flatpickr-month {
    color: currentColor;
    fill: currentColor;
}

.flatpickr-months .flatpickr-current-month {
    font-weight: 400;
}

.flatpickr-months .flatpickr-monthDropdown-months {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-weight: inherit;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
    position: static;
    color: #6e7582;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
    color: #354052;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
    display: block;
    fill: currentColor !important;
}

.flatpickr-months .numInputWrapper .cur-year {
    font-weight: inherit;
}

.flatpickr-months .numInputWrapper .arrowUp,
.flatpickr-months .numInputWrapper .arrowDown {
    display: none;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
    background: rgba(110, 117, 130, 0.06);
    border-color: transparent;
}

.noUi-target {
    border: 0;
    box-shadow: none;
    background: none;
    border-radius: 0;
    color: #206bc4;
}

.noUi-horizontal {
    height: 1.25rem;
    padding: .5rem 0;
}

.noUi-base {
    background: rgba(176, 181, 190, 0.24);
    border-radius: 1rem;
}

.noUi-handle {
    width: 1rem;
    height: 1rem;
    border: 2px solid #ffffff;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    background: currentColor;
    outline: 0;
}

.noUi-handle:before,
.noUi-handle:after {
    content: none;
}

.noUi-horizontal .noUi-handle {
    width: 1rem;
    height: 1rem;
    top: -0.5rem;
    right: -0.5rem;
    margin: 1px 1px 0 0;
}

.noUi-handle.noUi-active,
.noUi-handle:focus {
    box-shadow: 0 0 0 1px #f5f7fb, 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
}

.noUi-connect {
    background: currentColor;
}


/*# sourceMappingURL=tabler.css.map */
